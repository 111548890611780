import React, { useState } from 'react';
import { FiUser, FiMail, FiPhone, FiMapPin, FiCalendar, FiEdit2, FiSave } from 'react-icons/fi';

interface UserProfile {
  name: string;
  email: string;
  phone: string;
  location: string;
  bio: string;
  avatar: string;
  joinDate: Date;
  role: string;
  socialLinks: {
    twitter: string;
    linkedin: string;
    github: string;
  };
  stats: {
    articles: number;
    followers: number;
    following: number;
  };
  recentActivity: Array<{
    id: string;
    type: 'article' | 'comment' | 'like';
    content: string;
    date: Date;
  }>;
}

const Profile: React.FC = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [profile, setProfile] = useState<UserProfile>({
    name: 'John Doe',
    email: 'john@example.com',
    phone: '+1 (555) 123-4567',
    location: 'San Francisco, CA',
    bio: 'Senior Content Manager with 5+ years of experience in digital content strategy and creation.',
    avatar: '/default-avatar.jpg',
    joinDate: new Date('2023-01-15'),
    role: 'Content Manager',
    socialLinks: {
      twitter: 'https://twitter.com/johndoe',
      linkedin: 'https://linkedin.com/in/johndoe',
      github: 'https://github.com/johndoe',
    },
    stats: {
      articles: 45,
      followers: 1200,
      following: 890,
    },
    recentActivity: [
      {
        id: '1',
        type: 'article',
        content: 'Published "Getting Started with React"',
        date: new Date('2023-12-01'),
      },
      {
        id: '2',
        type: 'comment',
        content: 'Commented on "Advanced TypeScript Tips"',
        date: new Date('2023-11-30'),
      },
      {
        id: '3',
        type: 'like',
        content: 'Liked "Web Development Best Practices"',
        date: new Date('2023-11-29'),
      },
    ],
  });

  const handleSave = async () => {
    setIsSaving(true);
    try {
      // TODO: Implement actual save logic
      await new Promise(resolve => setTimeout(resolve, 1000));
      setIsEditing(false);
      console.log('Profile saved:', profile);
    } catch (error) {
      console.error('Error saving profile:', error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleAvatarChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfile(prev => ({
          ...prev,
          avatar: reader.result as string,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white p-6">
      <div className="max-w-4xl mx-auto">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-bold">Profile</h1>
          <button
            onClick={() => isEditing ? handleSave() : setIsEditing(true)}
            disabled={isSaving}
            className="flex items-center gap-2 px-4 py-2 bg-blue-600 rounded-lg hover:bg-blue-700"
          >
            {isEditing ? (
              <>
                <FiSave /> {isSaving ? 'Saving...' : 'Save Changes'}
              </>
            ) : (
              <>
                <FiEdit2 /> Edit Profile
              </>
            )}
          </button>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {/* Left Column - Profile Info */}
          <div className="md:col-span-1 space-y-6">
            <div className="bg-gray-800 p-6 rounded-lg">
              <div className="flex flex-col items-center">
                <div className="relative mb-4">
                  <img
                    src={profile.avatar}
                    alt={profile.name}
                    className="w-32 h-32 rounded-full object-cover"
                  />
                  {isEditing && (
                    <label className="absolute bottom-0 right-0 p-2 bg-gray-700 rounded-full cursor-pointer hover:bg-gray-600">
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleAvatarChange}
                        className="hidden"
                      />
                      <FiEdit2 className="text-lg" />
                    </label>
                  )}
                </div>
                {isEditing ? (
                  <input
                    type="text"
                    value={profile.name}
                    onChange={(e) =>
                      setProfile(prev => ({ ...prev, name: e.target.value }))
                    }
                    className="text-xl font-bold text-center bg-gray-700 rounded px-2 py-1 mb-2"
                  />
                ) : (
                  <h2 className="text-xl font-bold mb-2">{profile.name}</h2>
                )}
                <p className="text-gray-400 mb-4">{profile.role}</p>
                <div className="flex items-center gap-2 text-sm text-gray-400">
                  <FiCalendar />
                  <span>Joined {profile.joinDate.toLocaleDateString()}</span>
                </div>
              </div>
            </div>

            <div className="bg-gray-800 p-6 rounded-lg">
              <h3 className="text-lg font-semibold mb-4">Stats</h3>
              <div className="grid grid-cols-3 gap-4 text-center">
                <div>
                  <div className="text-2xl font-bold">
                    {profile.stats.articles}
                  </div>
                  <div className="text-sm text-gray-400">Articles</div>
                </div>
                <div>
                  <div className="text-2xl font-bold">
                    {profile.stats.followers}
                  </div>
                  <div className="text-sm text-gray-400">Followers</div>
                </div>
                <div>
                  <div className="text-2xl font-bold">
                    {profile.stats.following}
                  </div>
                  <div className="text-sm text-gray-400">Following</div>
                </div>
              </div>
            </div>

            <div className="bg-gray-800 p-6 rounded-lg">
              <h3 className="text-lg font-semibold mb-4">Contact</h3>
              <div className="space-y-4">
                <div className="flex items-center gap-3">
                  <FiMail className="text-gray-400" />
                  {isEditing ? (
                    <input
                      type="email"
                      value={profile.email}
                      onChange={(e) =>
                        setProfile(prev => ({ ...prev, email: e.target.value }))
                      }
                      className="flex-1 bg-gray-700 rounded px-2 py-1"
                    />
                  ) : (
                    <span>{profile.email}</span>
                  )}
                </div>
                <div className="flex items-center gap-3">
                  <FiPhone className="text-gray-400" />
                  {isEditing ? (
                    <input
                      type="tel"
                      value={profile.phone}
                      onChange={(e) =>
                        setProfile(prev => ({ ...prev, phone: e.target.value }))
                      }
                      className="flex-1 bg-gray-700 rounded px-2 py-1"
                    />
                  ) : (
                    <span>{profile.phone}</span>
                  )}
                </div>
                <div className="flex items-center gap-3">
                  <FiMapPin className="text-gray-400" />
                  {isEditing ? (
                    <input
                      type="text"
                      value={profile.location}
                      onChange={(e) =>
                        setProfile(prev => ({ ...prev, location: e.target.value }))
                      }
                      className="flex-1 bg-gray-700 rounded px-2 py-1"
                    />
                  ) : (
                    <span>{profile.location}</span>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Right Column - Bio and Activity */}
          <div className="md:col-span-2 space-y-6">
            <div className="bg-gray-800 p-6 rounded-lg">
              <h3 className="text-lg font-semibold mb-4">About</h3>
              {isEditing ? (
                <textarea
                  value={profile.bio}
                  onChange={(e) =>
                    setProfile(prev => ({ ...prev, bio: e.target.value }))
                  }
                  className="w-full h-32 bg-gray-700 rounded p-2"
                />
              ) : (
                <p className="text-gray-300">{profile.bio}</p>
              )}
            </div>

            <div className="bg-gray-800 p-6 rounded-lg">
              <h3 className="text-lg font-semibold mb-4">Social Links</h3>
              <div className="space-y-4">
                {Object.entries(profile.socialLinks).map(([platform, url]) => (
                  <div key={platform} className="flex items-center gap-3">
                    <span className="capitalize text-gray-400">{platform}</span>
                    {isEditing ? (
                      <input
                        type="url"
                        value={url}
                        onChange={(e) =>
                          setProfile(prev => ({
                            ...prev,
                            socialLinks: {
                              ...prev.socialLinks,
                              [platform]: e.target.value,
                            },
                          }))
                        }
                        className="flex-1 bg-gray-700 rounded px-2 py-1"
                      />
                    ) : (
                      <a
                        href={url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-400 hover:text-blue-300"
                      >
                        {url}
                      </a>
                    )}
                  </div>
                ))}
              </div>
            </div>

            <div className="bg-gray-800 p-6 rounded-lg">
              <h3 className="text-lg font-semibold mb-4">Recent Activity</h3>
              <div className="space-y-4">
                {profile.recentActivity.map(activity => (
                  <div
                    key={activity.id}
                    className="flex items-start gap-4 border-b border-gray-700 pb-4"
                  >
                    <div className="p-2 bg-gray-700 rounded">
                      {activity.type === 'article' && <FiEdit2 />}
                      {activity.type === 'comment' && <FiMail />}
                      {activity.type === 'like' && '❤️'}
                    </div>
                    <div className="flex-1">
                      <p className="text-gray-300">{activity.content}</p>
                      <p className="text-sm text-gray-400">
                        {activity.date.toLocaleDateString()}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
