import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { Article } from '../../types/article';
import ArticlesList from './ArticlesList';
import { FiArrowLeft } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

const DraftArticles: React.FC = () => {
  const navigate = useNavigate();
  const [articles, setArticles] = useState<Article[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDrafts = async () => {
      try {
        const q = query(
          collection(db, 'articles'),
          where('status', '==', 'draft')
        );
        const querySnapshot = await getDocs(q);
        const drafts = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })) as Article[];
        setArticles(drafts);
      } catch (error) {
        console.error('Error fetching drafts:', error);
        toast.error('Failed to fetch draft articles');
      } finally {
        setLoading(false);
      }
    };

    fetchDrafts();
  }, []);

  return (
    <div className="p-6">
      <div className="flex items-center justify-between mb-8">
        <div className="flex items-center gap-4">
          <button
            onClick={() => navigate('/articles')}
            className="flex items-center gap-2 text-gray-400 hover:text-white transition-colors"
          >
            <FiArrowLeft /> Back to Articles
          </button>
          <h1 className="text-2xl font-bold text-white">Draft Articles</h1>
        </div>
      </div>

      <ArticlesList
        articles={articles}
        loading={loading}
        showFilters={false}
        emptyMessage="No draft articles found"
      />
    </div>
  );
};

export default DraftArticles;
