import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Event } from '../../types/event';
import { doc, getDoc, serverTimestamp, updateDoc, Timestamp } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { toast } from 'react-hot-toast';
import EventForm from '../../components/events/EventForm';
import { useAuth } from '../../contexts/AuthContext';
import { FiArrowLeft, FiSave } from 'react-icons/fi';

interface EventFormRef {
  getFormData: () => Partial<Event> | null;
  updateFormData: (data: Partial<Event>) => void;
}

const EditEvent: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const formRef = useRef<EventFormRef>(null);
  const { currentUser } = useAuth();
  const [event, setEvent] = useState<Partial<Event> | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    const fetchEvent = async () => {
      if (!id) return;

      try {
        const docRef = doc(db, 'events', id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const eventData = { id: docSnap.id, ...docSnap.data() } as Event;
          setEvent(eventData);
          if (formRef.current) {
            formRef.current.updateFormData(eventData);
          }
        } else {
          toast.error('Event not found');
          navigate('/admin/events');
        }
      } catch (error) {
        console.error('Error fetching event:', error);
        toast.error('Failed to fetch event');
      } finally {
        setIsFetching(false);
      }
    };

    fetchEvent();
  }, [id, navigate]);

  const handleSubmit = async (formData: Partial<Event>) => {
    if (!id || !currentUser) {
      toast.error('You must be logged in to edit an event');
      return;
    }

    setIsLoading(true);
    try {
      const now = serverTimestamp();

      // Check content size
      const contentSize = new Blob([JSON.stringify(formData)]).size;
      if (contentSize > 1000000) { // Set threshold to 1MB
        toast.error('Event content is too large. Try reducing the number or size of images in the content.');
        return;
      }

      // Convert dates to Timestamp if they are Date objects
      const startDate = formData.startDate instanceof Date 
        ? Timestamp.fromDate(formData.startDate)
        : formData.startDate;
      
      const endDate = formData.endDate instanceof Date 
        ? Timestamp.fromDate(formData.endDate)
        : formData.endDate;

      const eventData = {
        ...formData,
        startDate,
        endDate,
        updatedAt: now,
        lastModifiedAt: now,
        lastModifiedBy: currentUser.uid,
        publishedAt: formData.status === 'published' ? now : null,
        scheduledAt: formData.status === 'scheduled' ? formData.scheduledAt : null,
      };

      await updateDoc(doc(db, 'events', id), eventData);
      
      toast.success(
        formData.status === 'scheduled'
          ? `Event scheduled for ${new Date(formData.scheduledAt?.toDate() || '').toLocaleString()}`
          : 'Event updated successfully!'
      );
      
      navigate('/admin/events');
    } catch (error) {
      console.error('Error updating event:', error);
      toast.error('Failed to update event');
    } finally {
      setIsLoading(false);
    }
  };

  if (isFetching) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900">
      {/* Header */}
      <div className="sticky top-0 z-10 bg-gray-900/80 backdrop-blur-lg border-b border-white/10">
        <div className="max-w-[2000px] mx-auto px-4 sm:px-6 lg:px-8">
          <div className="py-4 flex items-center justify-between">
            {/* Left side */}
            <div className="flex items-center gap-4">
              <button
                onClick={() => navigate('/admin/events')}
                className="inline-flex items-center text-sm text-gray-400 hover:text-white transition-colors"
              >
                <FiArrowLeft className="w-4 h-4 mr-2" />
                Back to Events
              </button>
            </div>

            {/* Right side */}
            <div className="flex items-center gap-3">
              <button
                onClick={() => {
                  const data = formRef.current?.getFormData();
                  if (data) {
                    handleSubmit(data);
                  }
                }}
                disabled={isLoading}
                className="inline-flex items-center px-4 py-2 rounded-lg bg-primary text-white text-sm font-medium hover:bg-primary/90 focus:outline-none focus:ring-2 focus:ring-primary/50 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
              >
                <FiSave className="w-4 h-4 mr-2" />
                {isLoading ? 'Saving...' : 'Update Event'}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-[2000px] mx-auto px-4 sm:px-6 lg:px-8 py-6">
        <EventForm ref={formRef} event={event} onSubmit={handleSubmit} isLoading={isLoading} isEditing />
      </div>
    </div>
  );
};

export default EditEvent;
