import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FiUpload, FiX, FiArrowLeft, FiImage } from 'react-icons/fi';
import { storage } from '../../config/firebase';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { addDoc, collection, serverTimestamp, updateDoc, doc, getDoc, getFirestore, query, where, getDocs, deleteDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { toast } from 'react-hot-toast';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { DocumentReference, DocumentData } from 'firebase/firestore';

interface UserData {
  id?: string;
  uid?: string;
  email?: string;
  isAdmin?: boolean;
  userRole?: string;
  authUid?: string;
}

interface GalleryFormData {
  title: string;
  description: string;
  category: string;
  isPublished: boolean;
  status: string;
}

interface PhotoFile extends File {
  preview?: string;
  originalFile?: File;
}

interface CompressedPhoto {
  file: File;
  originalFile?: File;
  title?: string;
  description?: string;
  preview: string;
}

const AddPhotoGallery: React.FC = () => {
  const navigate = useNavigate();
  const auth = getAuth();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          setIsAuthenticated(true);
          console.log('User authenticated:', user.email);
          
          // Get the ID token with claims
          const token = await user.getIdTokenResult();
          console.log('Token claims:', token.claims);
          
          // Query userDetails collection by authUid field
          const db = getFirestore();
          const usersRef = collection(db, 'userDetails');
          const q = query(usersRef, where('authUid', '==', user.uid));
          const querySnapshot = await getDocs(q);
          
          if (querySnapshot.empty) {
            console.log('User document not found for authUid:', user.uid);
            toast.error('User profile not found');
            navigate('/');
            return;
          }
          
          const userDoc = querySnapshot.docs[0];
          const userData = userDoc.data();
          console.log('User data from Firestore:', userData);
          console.log('User role:', userData?.userRole);
          
          const isAdminUser: boolean = Boolean(token.claims.admin) || userData?.userRole === 'admin';
          console.log('Is admin user:', isAdminUser);
          setIsAdmin(isAdminUser);
          
          if (!isAdminUser) {
            console.log('Access denied: Not an admin user');
            toast.error('Admin access required');
            navigate('/');
          } else {
            console.log('Access granted: Admin user confirmed');
          }
        } catch (error) {
          console.error('Error in admin check:', error);
          toast.error('Error checking admin status');
          navigate('/');
        }
      } else {
        setIsAuthenticated(false);
        setIsAdmin(false);
        toast.error('Please log in to access this page');
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, [auth, navigate]);

  useEffect(() => {
    const checkAdminStatus = async () => {
      const auth = getAuth();
      if (auth.currentUser) {
        const userDocRef = doc(db, 'userDetails', auth.currentUser.uid);
        const userSnap = await getDoc(userDocRef);
        
        if (userSnap.exists()) {
          const userData = userSnap.data();
          // Update the user's admin status in Firestore if needed
          if (!userData.isAdmin && userData.memberType === 'Range') {
            await updateDoc(userDocRef, {
              isAdmin: true,
              userRole: 'admin'
            });
            console.log('Updated user to admin');
          }
        }
      }
    };

    checkAdminStatus();
  }, []);

  const [formData, setFormData] = useState<GalleryFormData>({
    title: '',
    description: '',
    category: '',
    isPublished: true,
    status: 'active'
  });
  const [photos, setPhotos] = useState<CompressedPhoto[]>([]);
  const [isDragging, setIsDragging] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const { title, description, category, isPublished } = formData;

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleDragEnter = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleImageUpload = async (file: File, galleryId: string): Promise<string> => {
    try {
      // Simple file type check
      if (!file.type.startsWith('image/')) {
        throw new Error('Please upload a valid image file');
      }

      const fileName = `${Date.now()}_${file.name.replace(/[^a-zA-Z0-9.]/g, '_')}`;
      const storageRef = ref(storage, `photo-gallery/${galleryId}/${fileName}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      return new Promise((resolve, reject) => {
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress(progress);
          },
          (error) => {
            console.error('Upload error:', error);
            if (error.code === 'storage/unauthorized') {
              toast.error('Upload permission denied. Please check your permissions.');
            } else {
              toast.error('Failed to upload image. Please try again.');
            }
            reject(error);
          },
          async () => {
            try {
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              resolve(downloadURL);
            } catch (error) {
              console.error('Error getting download URL:', error);
              reject(error);
            }
          }
        );
      });
    } catch (error: any) {
      console.error('Error creating upload task:', error);
      toast.error(error.message || 'Failed to upload image');
      throw error;
    }
  };

  const handleFiles = async (files: FileList | null) => {
    if (!files) return;

    const validFiles = Array.from(files).filter(
      file => file.type.startsWith('image/')
    );

    if (validFiles.length === 0) {
      toast.error('Please select valid image files');
      return;
    }

    const newPhotos: CompressedPhoto[] = [];
    for (const file of validFiles) {
      try {
        const preview = URL.createObjectURL(file);
        newPhotos.push({
          file: file,
          originalFile: file,
          preview,
          title: file.name.split('.')[0],
          description: ''
        });
      } catch (error) {
        console.error('Error processing file:', error);
        toast.error(`Failed to process ${file.name}`);
      }
    }

    setPhotos(prev => [...prev, ...newPhotos]);
  };

  const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);
    handleFiles(e.dataTransfer.files);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleFiles(event.target.files);
  };

  const removePhoto = (index: number) => {
    setPhotos(prev => {
      const newPhotos = [...prev];
      if (newPhotos[index].preview) {
        URL.revokeObjectURL(newPhotos[index].preview!);
      }
      newPhotos.splice(index, 1);
      return newPhotos;
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    try {
      if (!auth.currentUser) {
        toast.error('Please log in to continue');
        navigate('/login');
        return;
      }

      if (!formData.title.trim()) {
        toast.error('Please enter a gallery title');
        return;
      }

      if (photos.length === 0) {
        toast.error('Please add at least one photo');
        return;
      }

      setIsSubmitting(true);

      // Create gallery document first
      console.log('Creating gallery document with data:', {
        title: formData.title.trim(),
        description: formData.description.trim(),
        category: formData.category,
        isPublished: true,
        status: 'active',
        createdBy: auth.currentUser.uid,
      });

      try {
        const galleryRef = await addDoc(collection(db, 'photo-gallery'), {
          title: formData.title.trim(),
          description: formData.description.trim(),
          category: formData.category,
          isPublished: true,
          createdAt: serverTimestamp(),
          imageCount: photos.length,
          status: 'active',
          createdBy: auth.currentUser.uid,
          updatedAt: serverTimestamp(),
        });

        console.log('Gallery document created successfully with ID:', galleryRef.id);

        const galleryId = galleryRef.id;
        const uploadedPhotos = [];
        let uploadErrors = 0;

        // Upload each photo
        for (const photo of photos) {
          try {
            console.log('Uploading photo:', photo.file.name);
            const imageUrl = await handleImageUpload(photo.file, galleryId);
            if (imageUrl) {
              console.log('Photo uploaded successfully:', imageUrl);
              uploadedPhotos.push({
                url: imageUrl,
                title: photo.title?.trim() || '',
                description: photo.description?.trim() || '',
                uploadedAt: new Date().toISOString(),
                fileName: photo.file.name,
                size: photo.file.size,
                type: photo.file.type
              });
            }
          } catch (error) {
            console.error('Error uploading photo:', photo.file.name, error);
            uploadErrors++;
          }
        }

        if (uploadedPhotos.length === 0) {
          console.error('No photos uploaded successfully');
          await deleteDoc(galleryRef);
          throw new Error('Failed to upload any photos. Please try again.');
        }

        console.log('Updating gallery document with photos:', uploadedPhotos.length);
        
        // Update gallery with successful uploads
        await updateDoc(galleryRef, {
          photos: uploadedPhotos,
          thumbnail: uploadedPhotos[0].url,
          imageCount: uploadedPhotos.length,
          updatedAt: serverTimestamp(),
          uploadComplete: true,
          storagePath: `photo-gallery/${galleryId}`
        });

        console.log('Gallery document updated successfully');

        if (uploadErrors > 0) {
          toast(`Gallery created with ${uploadErrors} failed uploads`, {
            icon: '⚠️',
            duration: 4000,
            style: {
              background: '#ffd700',
              color: '#000'
            }
          });
        } else {
          toast.success('Gallery created successfully');
        }
        
        navigate('/admin/photo-gallery');
      } catch (error: any) {
        console.error('Error in gallery creation process:', error);
        if (error.code === 'permission-denied') {
          toast.error('Permission denied. Please check your access rights.');
        } else if (error.code === 'unavailable') {
          toast.error('Service temporarily unavailable. Please try again later.');
        } else {
          toast.error(error.message || 'Failed to create gallery');
        }
      }
    } catch (error: any) {
      console.error('Error in form submission:', error);
      toast.error(error.message || 'Failed to process form');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-900 p-6">
      {/* Header */}
      <div className="max-w-4xl mx-auto mb-8">
        <button
          onClick={() => navigate('/admin/photo-gallery')}
          className="flex items-center text-gray-400 hover:text-white mb-6 transition-colors"
        >
          <FiArrowLeft className="w-5 h-5 mr-2" />
          Back to Galleries
        </button>
        <div className="flex justify-between items-center">
          <div>
            <h1 className="text-3xl font-bold text-white mb-2">Create New Gallery</h1>
            <p className="text-gray-400">Add a new photo gallery to your collection</p>
          </div>
        </div>
      </div>

      {/* Form */}
      <form onSubmit={handleSubmit} className="max-w-4xl mx-auto">
        <div className="bg-gray-800 rounded-xl border border-gray-700 shadow-lg overflow-hidden">
          {/* Form Fields */}
          <div className="p-6 space-y-6">
            <div>
              <label htmlFor="title" className="block text-sm font-medium text-gray-300 mb-2">
                Gallery Title
              </label>
              <input
                type="text"
                id="title"
                name="title"
                value={title}
                onChange={handleInputChange}
                className="w-full px-4 py-3 rounded-lg bg-gray-700 border border-gray-600 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
                placeholder="Enter gallery title"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-300 mb-2">Category</label>
              <input
                type="text"
                name="category"
                value={category}
                onChange={handleInputChange}
                className="w-full px-4 py-3 rounded-lg bg-gray-700 border border-gray-600 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
                placeholder="Enter gallery category"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-300 mb-2">Description</label>
              <textarea
                name="description"
                value={description}
                onChange={handleInputChange}
                rows={3}
                className="w-full px-4 py-3 rounded-lg bg-gray-700 border border-gray-600 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
                placeholder="Enter gallery description"
              />
            </div>

            {/* Photo Upload Area */}
            <div>
              <div
                onDragEnter={handleDragEnter}
                onDragOver={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                className={`border-2 border-dashed rounded-xl p-8 transition-all duration-200 ${
                  isDragging
                    ? 'border-blue-500 bg-blue-500/10'
                    : 'border-gray-600 hover:border-gray-500'
                }`}
              >
                <input
                  type="file"
                  multiple
                  accept="image/*"
                  onChange={handleFileChange}
                  className="hidden"
                  id="photo-input"
                />
                <label
                  htmlFor="photo-input"
                  className="cursor-pointer flex flex-col items-center"
                >
                  <div className="w-16 h-16 bg-gray-700 rounded-full flex items-center justify-center mb-4">
                    <FiUpload className="w-8 h-8 text-gray-400" />
                  </div>
                  <span className="text-gray-300 text-lg mb-2">
                    Drag and drop your photos here
                  </span>
                  <span className="text-gray-400 mb-4">
                    or click to browse from your computer
                  </span>
                  <span className="text-sm text-gray-500">
                    Supported formats: JPG, PNG, GIF
                  </span>
                </label>
              </div>
            </div>

            {/* Photo Preview Grid */}
            {photos.length > 0 && (
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
                {photos.map((photo, index) => (
                  <div
                    key={index}
                    className="relative group bg-gray-800 rounded-lg overflow-hidden"
                    style={{
                      aspectRatio: '2/1',
                      width: '100%'
                    }}
                  >
                    <img
                      src={photo.preview}
                      alt={`Preview ${index + 1}`}
                      className="w-full h-full object-cover"
                    />
                    <button
                      onClick={() => removePhoto(index)}
                      className="absolute top-2 right-2 p-1 bg-red-500 text-white rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-200"
                    >
                      <FiX className="w-4 h-4" />
                    </button>
                  </div>
                ))}
              </div>
            )}

            {/* Form Actions */}
            <div className="px-6 py-4 bg-gray-750 border-t border-gray-700">
              <div className="flex justify-between items-center">
                <div className="text-sm text-gray-400">
                  {photos.length} photo{photos.length !== 1 ? 's' : ''} selected
                </div>
                <div className="flex space-x-4">
                  <button
                    type="button"
                    onClick={() => navigate('/admin/photo-gallery')}
                    className="px-6 py-3 text-gray-300 hover:text-white bg-gray-700 hover:bg-gray-600 rounded-lg transition-colors duration-200"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-all duration-200 flex items-center disabled:opacity-50 disabled:cursor-not-allowed"
                    disabled={photos.length === 0 || isSubmitting}
                  >
                    {isSubmitting ? (
                      <>
                        <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white mr-2" />
                        Creating...
                      </>
                    ) : (
                      'Create Gallery'
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddPhotoGallery;
