import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { FiSave, FiUsers, FiBell, FiArrowLeft } from 'react-icons/fi';
import { doc, getDoc, updateDoc, collection, getDocs } from 'firebase/firestore';
import { db } from '../../config/firebase';
import toast from 'react-hot-toast';
import Select from 'react-select';

interface UserOption {
  value: string;
  label: string;
  email: string;
}

interface FormData {
  title: string;
  message: string;
  targetAudience: 'all' | 'admin' | 'private';
  targetUserId?: string;
  scheduledDate: string;
  priority: 'low' | 'medium' | 'high';
  status: 'scheduled' | 'sent' | 'failed';
}

const EditNotification: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [userOptions, setUserOptions] = useState<UserOption[]>([]);
  const [selectedUser, setSelectedUser] = useState<UserOption | null>(null);

  const [formData, setFormData] = useState<FormData>({
    title: '',
    message: '',
    targetAudience: 'all',
    scheduledDate: new Date().toISOString().slice(0, 16),
    priority: 'medium',
    status: 'scheduled'
  });

  useEffect(() => {
    const fetchNotification = async () => {
      if (!id) return;
      try {
        const docRef = doc(db, 'notifications', id);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
          const data = docSnap.data();
          setFormData({
            title: data.title,
            message: data.message,
            targetAudience: data.targetAudience,
            targetUserId: data.targetUserId,
            scheduledDate: new Date(data.scheduledDate).toISOString().slice(0, 16),
            priority: data.priority,
            status: data.status
          });

          if (data.targetAudience === 'private' && data.targetUserId) {
            const userDoc = await getDoc(doc(db, 'users', data.targetUserId));
            if (userDoc.exists()) {
              const userData = userDoc.data();
              setSelectedUser({
                value: data.targetUserId,
                label: userData.displayName || userData.email,
                email: userData.email
              });
            }
          }
        }
      } catch (err) {
        console.error('Error fetching notification:', err);
        setError('Failed to fetch notification details');
      }
    };

    const fetchUsers = async () => {
      try {
        const usersSnapshot = await getDocs(collection(db, 'users'));
        const options = usersSnapshot.docs.map(doc => ({
          value: doc.id,
          label: doc.data().displayName || doc.data().email,
          email: doc.data().email
        }));
        setUserOptions(options);
      } catch (err) {
        console.error('Error fetching users:', err);
        setError('Failed to fetch users list');
      }
    };

    fetchNotification();
    fetchUsers();
  }, [id]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleUserSelect = (option: UserOption | null) => {
    setSelectedUser(option);
    if (option) {
      setFormData(prev => ({ ...prev, targetUserId: option.value }));
    } else {
      setFormData(prev => ({ ...prev, targetUserId: undefined }));
    }
  };

  const formatOptionLabel = ({ label, email }: UserOption) => (
    <div className="flex flex-col">
      <div>{label}</div>
      <div className="text-sm text-gray-400">{email}</div>
    </div>
  );

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!id) return;

    setIsLoading(true);
    setError(null);

    try {
      const docRef = doc(db, 'notifications', id);
      await updateDoc(docRef, {
        ...formData,
        updatedAt: new Date(),
      });

      toast.success('Notification updated successfully');
      navigate('/admin/notifications');
    } catch (err) {
      console.error('Error updating notification:', err);
      setError('Failed to update notification');
      toast.error('Failed to update notification');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-900">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex items-center mb-8">
          <Link 
            to="/admin/notifications"
            className="flex items-center text-gray-400 hover:text-white transition-colors mr-4"
          >
            <FiArrowLeft className="w-6 h-6" />
          </Link>
          <div>
            <h1 className="text-2xl sm:text-3xl font-bold text-white">Edit Notification</h1>
            <p className="mt-2 text-gray-400">Modify notification details</p>
          </div>
        </div>

        <div className="bg-gray-800 rounded-lg shadow p-4 sm:p-6">
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-200 mb-2">
                Title
              </label>
              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={handleInputChange}
                required
                className="w-full px-4 py-2 bg-gray-700 text-white border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent placeholder-gray-400"
                placeholder="Enter notification title"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-200 mb-2">
                Message
              </label>
              <textarea
                name="message"
                value={formData.message}
                onChange={handleInputChange}
                required
                rows={4}
                className="w-full px-4 py-2 bg-gray-700 text-white border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent placeholder-gray-400 resize-none"
                placeholder="Enter notification message"
              />
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-200 mb-2">
                  Target Audience
                </label>
                <select
                  name="targetAudience"
                  value={formData.targetAudience}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 bg-gray-700 text-white border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                >
                  <option value="all">All Logged-in Users</option>
                  <option value="admin">Admins Only</option>
                  <option value="private">Private Message</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-200 mb-2">
                  Priority
                </label>
                <select
                  name="priority"
                  value={formData.priority}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 bg-gray-700 text-white border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                >
                  <option value="low">Low</option>
                  <option value="medium">Medium</option>
                  <option value="high">High</option>
                </select>
              </div>
            </div>

            {formData.targetAudience === 'private' && (
              <div>
                <label className="block text-sm font-medium text-gray-200 mb-2">
                  Select User
                </label>
                <Select
                  value={selectedUser}
                  onChange={handleUserSelect}
                  options={userOptions}
                  formatOptionLabel={formatOptionLabel}
                  placeholder="Search user by name or email..."
                  isClearable
                  isSearchable
                  className="text-sm"
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      backgroundColor: '#374151',
                      borderColor: state.isFocused ? '#3B82F6' : '#4B5563',
                      boxShadow: state.isFocused ? '0 0 0 1px #3B82F6' : 'none',
                      '&:hover': {
                        borderColor: '#3B82F6'
                      }
                    }),
                    menu: (base) => ({
                      ...base,
                      backgroundColor: '#374151',
                      border: '1px solid #4B5563'
                    }),
                    option: (base, state) => ({
                      ...base,
                      backgroundColor: state.isSelected 
                        ? '#3B82F6' 
                        : state.isFocused 
                        ? '#4B5563' 
                        : '#374151',
                      color: 'white',
                      cursor: 'pointer'
                    }),
                    singleValue: (base) => ({
                      ...base,
                      color: 'white'
                    }),
                    input: (base) => ({
                      ...base,
                      color: 'white'
                    }),
                    placeholder: (base) => ({
                      ...base,
                      color: '#9CA3AF'
                    })
                  }}
                />
              </div>
            )}

            <div>
              <label className="block text-sm font-medium text-gray-200 mb-2">
                Scheduled Date
              </label>
              <input
                type="datetime-local"
                name="scheduledDate"
                value={formData.scheduledDate}
                onChange={handleInputChange}
                required
                className="w-full px-4 py-2 bg-gray-700 text-white border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>

            {error && (
              <div className="text-red-400 text-sm bg-red-900/50 border border-red-700 rounded-lg p-3">
                {error}
              </div>
            )}

            <div className="flex flex-col sm:flex-row gap-4 pt-6">
              <button
                type="submit"
                disabled={isLoading}
                className="flex-1 flex items-center justify-center px-4 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-blue-600"
              >
                <FiSave className="mr-2" />
                {isLoading ? 'Updating...' : 'Update Notification'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditNotification;
