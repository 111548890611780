import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Article } from '../../types/article';
import { doc, getDoc, Timestamp, updateDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { toast } from 'react-hot-toast';
import { useAuth } from '../../contexts/AuthContext';
import { FiArrowLeft, FiSave, FiX } from 'react-icons/fi';

interface ArticleFormRef {
  getFormData: () => Partial<Article>;
  updateFormData: (data: Partial<Article>) => void;
}

const EditArticle: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  
  // Form states
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [content, setContent] = useState('');
  const [seoTitle, setSeoTitle] = useState('');
  const [metaDescription, setMetaDescription] = useState('');
  const [keywords, setKeywords] = useState('');
  const [excerpt, setExcerpt] = useState('');
  const [categories, setCategories] = useState<string[]>([]);
  const [tags, setTags] = useState<string[]>([]);
  const [city, setCity] = useState('');
  
  // Image states
  const [uploadProgress, setUploadProgress] = useState(0);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      // Simulate upload progress
      setUploadProgress(0);
      const interval = setInterval(() => {
        setUploadProgress(prev => {
          if (prev >= 100) {
            clearInterval(interval);
            return 100;
          }
          return prev + 10;
        });
      }, 100);

      // Create preview
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const removeImage = () => {
    setPreviewImage(null);
    setUploadProgress(0);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  // Status states
  const [status, setStatus] = useState<'draft' | 'published' | 'scheduled'>('draft');
  const [scheduledDate, setScheduledDate] = useState('');
  const [scheduledTime, setScheduledTime] = useState('');

  const formRef = useRef<ArticleFormRef>(null);

  useEffect(() => {
    const fetchArticle = async () => {
      if (!id) return;

      try {
        const docRef = doc(db, 'articles', id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const article = { id: docSnap.id, ...docSnap.data() } as Article;
          setTitle(article.title);
          setSubtitle(article.subtitle);
          setContent(article.content);
          setSeoTitle(article.seo?.title || article.title);
          setMetaDescription(article.seo?.description || '');
          setKeywords(article.seo?.keywords?.join(', ') || '');
          setExcerpt(article.excerpt);
          setCategories(article.categories || []);
          setTags(article.tags || []);
          setCity(article.city);
          setStatus(article.status);
          if (article.featuredImage?.url) {
            setPreviewImage(article.featuredImage.url);
          }
          setScheduledDate(article.scheduledAt ? Timestamp.fromDate(article.scheduledAt.toDate()).toDate().toISOString().split('T')[0] : '');
          setScheduledTime(article.scheduledAt ? Timestamp.fromDate(article.scheduledAt.toDate()).toDate().toISOString().split('T')[1].split('.')[0] : '');
        } else {
          toast.error('Article not found');
          navigate('/admin/articles');
        }
      } catch (error) {
        console.error('Error fetching article:', error);
        toast.error('Failed to fetch article');
      } finally {
        setIsFetching(false);
      }
    };

    fetchArticle();
  }, [id, navigate]);

  const handleSubmit = async () => {
    if (!id || !currentUser) {
      toast.error('You must be logged in to edit an article');
      return;
    }

    if (!title || !content) {
      toast.error('Title and content are required');
      return;
    }

    setIsLoading(true);
    try {
      const now = Timestamp.now();
      const scheduledDateTime = status === 'scheduled' ? `${scheduledDate}T${scheduledTime}` : '';

      // Check content size
      const contentSize = new Blob([content]).size;
      if (contentSize > 1000000) { // 1MB limit
        toast.error('Article content is too large. Try reducing the content size.');
        return;
      }

      const articleData: Partial<Article> = {
        title,
        subtitle,
        content,
        excerpt: excerpt || content.slice(0, 160) + '...',
        categories,
        tags,
        city,
        status,
        authorId: currentUser.uid,
        author: currentUser.displayName || 'Unknown',
        updatedAt: now,
        lastModifiedAt: now,
        lastModifiedBy: currentUser.displayName || 'Unknown',
        publishedAt: status === 'published' ? now : null,
        scheduledAt: status === 'scheduled' ? Timestamp.fromDate(new Date(scheduledDateTime)) : null,
        viewCount: 0,
        seo: {
          title: seoTitle || title,
          description: metaDescription,
          keywords: keywords.split(',').map(k => k.trim()).filter(k => k),
        },
        featuredImage: previewImage ? {
          url: previewImage,
          alt: title
        } : null
      };

      await updateDoc(doc(db, 'articles', id), articleData);
      
      toast.success(
        status === 'scheduled'
          ? `Article scheduled for ${new Date(scheduledDateTime).toLocaleString()}`
          : 'Article updated successfully!'
      );
      
      navigate('/admin/articles');
    } catch (error) {
      console.error('Error updating article:', error);
      toast.error('Failed to update article');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800">
      {/* Header */}
      <div className="sticky top-0 z-10 bg-gray-900/80 backdrop-blur-lg border-b border-white/10">
        <div className="max-w-[2000px] mx-auto px-4 sm:px-6 lg:px-8">
          <div className="py-4 flex items-center justify-between gap-4">
            {/* Left side */}
            <div className="flex items-center gap-4">
              <button
                onClick={() => navigate('/admin/articles')}
                className="inline-flex items-center gap-2 text-gray-400 hover:text-white transition-colors"
              >
                <FiArrowLeft className="w-5 h-5" />
                <span className="hidden sm:inline">Back to Articles</span>
              </button>
              <h1 className="text-xl sm:text-2xl font-semibold text-white">Edit Article</h1>
            </div>

            {/* Right side */}
            <div className="flex items-center gap-3">
              <button
                onClick={handleSubmit}
                disabled={isLoading}
                className="inline-flex items-center gap-2 px-4 py-2 text-sm font-medium text-white bg-primary hover:bg-primary/80 rounded-lg transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              >
                <FiSave className="w-4 h-4" />
                <span className="hidden sm:inline">
                  {isLoading ? 'Updating...' : 'Update Article'}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Loading State */}
      {isFetching ? (
        <div className="flex items-center justify-center min-h-[60vh]">
          <div className="flex items-center space-x-3 bg-dark-secondary px-6 py-3 rounded-lg shadow-lg">
            <div className="w-3 h-3 bg-blue-500 rounded-full animate-bounce" />
            <div className="w-3 h-3 bg-blue-500 rounded-full animate-bounce [animation-delay:-.3s]" />
            <div className="w-3 h-3 bg-blue-500 rounded-full animate-bounce [animation-delay:-.5s]" />
          </div>
        </div>
      ) : !title ? (
        <div className="flex flex-col items-center justify-center min-h-[60vh] space-y-4">
          <h2 className="text-xl font-semibold text-gray-400">Article not found</h2>
          <button
            onClick={() => navigate('/admin/articles')}
            className="flex items-center space-x-2 px-4 py-2 rounded-lg bg-blue-500 hover:bg-blue-600 text-white transition-colors"
          >
            <FiArrowLeft className="w-5 h-5" />
            <span>Back to Articles</span>
          </button>
        </div>
      ) : (
        /* Main Content */
        <div className="max-w-[2000px] mx-auto px-4 sm:px-6 lg:px-8 py-6">
          <div className="grid grid-cols-1 lg:grid-cols-12 gap-6">
            {/* Left Column - Main Content */}
            <div className="lg:col-span-9 bg-white/5 backdrop-blur-xl rounded-xl shadow-lg">
              <div className="p-6">
                <h2 className="text-lg font-semibold text-white mb-4">Main Content</h2>
                <div className="space-y-6">
                  <div>
                    <label className="block text-sm font-medium text-gray-200 mb-2">Title</label>
                    <input
                      type="text"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      className="w-full bg-gray-900/50 border border-white/10 rounded-lg px-4 py-2.5 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-primary/50"
                      placeholder="Enter article title"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-200 mb-2">
                      Subtitle
                      <span className="text-xs text-gray-400 ml-2">(Optional)</span>
                    </label>
                    <input
                      type="text"
                      value={subtitle}
                      onChange={(e) => setSubtitle(e.target.value)}
                      className="w-full bg-gray-900/50 border border-white/10 rounded-lg px-4 py-2.5 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-primary/50"
                      placeholder="Enter article subtitle"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-200 mb-2">Content</label>
                    <textarea
                      value={content}
                      onChange={(e) => setContent(e.target.value)}
                      className="w-full h-[calc(100vh-25rem)] bg-gray-900/50 border border-white/10 rounded-lg px-4 py-2.5 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-primary/50 resize-none"
                      placeholder="Write your article content here..."
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>

            {/* Right Column - Meta Info */}
            <div className="lg:col-span-3 bg-white/5 backdrop-blur-xl rounded-xl shadow-lg">
              <div className="p-6">
                <h2 className="text-lg font-semibold text-white mb-4">Meta Information</h2>
                <div className="space-y-6">
                  <div>
                    <label className="block text-sm font-medium text-gray-200 mb-2">
                      Featured Image
                      <span className="text-xs text-gray-400 ml-2">(Recommended: 1200x630px)</span>
                    </label>
                    {previewImage ? (
                      <div className="relative mt-1">
                        <div className="relative aspect-video rounded-lg overflow-hidden bg-gray-900/50">
                          <img
                            src={previewImage}
                            alt="Preview"
                            className="w-full h-full object-cover"
                          />
                          <button
                            onClick={removeImage}
                            className="absolute top-2 right-2 p-1 bg-black/50 rounded-full hover:bg-black/70 transition-colors"
                          >
                            <FiX className="w-5 h-5 text-white" />
                          </button>
                        </div>
                        {uploadProgress < 100 && (
                          <div className="mt-2">
                            <div className="w-full bg-gray-900/50 rounded-full h-1.5">
                              <div
                                className="bg-primary h-1.5 rounded-full transition-all duration-300"
                                style={{ width: `${uploadProgress}%` }}
                              ></div>
                            </div>
                            <p className="text-sm text-gray-400 mt-1">Uploading: {uploadProgress}%</p>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="mt-1 flex flex-col items-center justify-center px-6 pt-5 pb-6 border-2 border-white/10 border-dashed rounded-lg hover:border-white/20 transition-colors cursor-pointer bg-gray-900/50">
                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                          <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48">
                            <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                          <div className="flex items-center mt-4">
                            <label className="relative cursor-pointer bg-primary rounded-md font-medium text-white px-3 py-2 hover:bg-primary/90 transition-colors focus-within:outline-none">
                              <span>Upload a file</span>
                              <input
                                ref={fileInputRef}
                                type="file"
                                className="sr-only"
                                accept="image/*"
                                onChange={handleImageUpload}
                              />
                            </label>
                            <p className="pl-3 text-sm text-gray-400">or drag and drop</p>
                          </div>
                          <p className="text-xs text-gray-400 mt-2">PNG, JPG, GIF up to 10MB</p>
                        </div>
                      </div>
                    )}
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-200 mb-2">SEO Title</label>
                    <input
                      type="text"
                      value={seoTitle}
                      onChange={(e) => setSeoTitle(e.target.value)}
                      className="w-full bg-gray-900/50 border border-white/10 rounded-lg px-4 py-2.5 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-primary/50"
                      placeholder="Enter SEO title"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-200 mb-2">Meta Description</label>
                    <textarea
                      value={metaDescription}
                      onChange={(e) => setMetaDescription(e.target.value)}
                      className="w-full h-32 bg-gray-900/50 border border-white/10 rounded-lg px-4 py-2.5 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-primary/50 resize-none"
                      placeholder="Enter meta description"
                    ></textarea>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-200 mb-2">Keywords</label>
                    <input
                      type="text"
                      value={keywords}
                      onChange={(e) => setKeywords(e.target.value)}
                      className="w-full bg-gray-900/50 border border-white/10 rounded-lg px-4 py-2.5 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-primary/50"
                      placeholder="Enter keywords, separated by commas"
                    />
                  </div>
                  <div className="pt-4 border-t border-white/10">
                    <label className="block text-sm font-medium text-gray-200 mb-2">Article Status</label>
                    <div className="flex flex-col space-y-4">
                      <select
                        value={status}
                        onChange={(e) => setStatus(e.target.value as 'draft' | 'published' | 'scheduled')}
                        className="w-full bg-gray-900/50 border border-white/10 rounded-lg px-4 py-2.5 text-white focus:outline-none focus:ring-2 focus:ring-primary/50 [&>option]:bg-gray-900"
                      >
                        <option value="draft">Draft</option>
                        <option value="published">Published</option>
                        <option value="scheduled">Scheduled</option>
                      </select>
                      
                      {status === 'scheduled' && (
                        <div className="space-y-4 mt-2 p-4 bg-gray-900/30 rounded-lg border border-white/5">
                          <div>
                            <label className="block text-sm font-medium text-gray-200 mb-2">Schedule Date</label>
                            <input
                              type="date"
                              value={scheduledDate}
                              onChange={(e) => setScheduledDate(e.target.value)}
                              min={new Date().toISOString().split('T')[0]}
                              className="w-full bg-gray-900/50 border border-white/10 rounded-lg px-4 py-2.5 text-white focus:outline-none focus:ring-2 focus:ring-primary/50"
                            />
                          </div>
                          <div>
                            <label className="block text-sm font-medium text-gray-200 mb-2">Schedule Time</label>
                            <input
                              type="time"
                              value={scheduledTime}
                              onChange={(e) => setScheduledTime(e.target.value)}
                              className="w-full bg-gray-900/50 border border-white/10 rounded-lg px-4 py-2.5 text-white focus:outline-none focus:ring-2 focus:ring-primary/50"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditArticle;
