import React, { useEffect, useState } from 'react';
import { FiUsers, FiFileText, FiActivity, FiCalendar } from 'react-icons/fi';
import { LineChart, Line, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { collection, query, orderBy, limit, getDocs, onSnapshot, where } from 'firebase/firestore';
import { db } from '../../config/firebase';

interface ActivityItem {
  id: string;
  fullName: string;
  createdAt: Date;
  memberType: string;
}

interface DashboardStats {
  totalMembers: number;
  nationalMembers: number;
  articlesPublished: number;
  eventsPublished: number;
  userGrowth: { date: string; users: number }[];
  recentMembers: ActivityItem[];
}

const DashboardHome: React.FC = () => {
  const [stats, setStats] = useState<DashboardStats>({
    totalMembers: 0,
    nationalMembers: 0,
    articlesPublished: 0,
    eventsPublished: 0,
    userGrowth: [],
    recentMembers: []
  });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        // Fetch total members
        const usersSnapshot = await getDocs(collection(db, 'userDetails'));
        const totalMembers = usersSnapshot.size;

        // Fetch national members
        const nationalQuery = query(
          collection(db, 'userDetails'),
          where('memberType', '==', 'National')
        );
        const nationalSnapshot = await getDocs(nationalQuery);
        const nationalMembers = nationalSnapshot.size;

        // Fetch articles count
        const articlesSnapshot = await getDocs(collection(db, 'articles'));
        const articlesPublished = articlesSnapshot.size;

        // Fetch events count
        const eventsSnapshot = await getDocs(collection(db, 'events'));
        const eventsPublished = eventsSnapshot.size;

        // Set initial stats
        setStats(prev => ({
          ...prev,
          totalMembers,
          nationalMembers,
          articlesPublished,
          eventsPublished
        }));

        setLoading(false);
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
        setLoading(false);
      }
    };

    // Set up real-time listener for recent members
    const membersQuery = query(
      collection(db, 'userDetails'),
      orderBy('createdAt', 'desc'),
      limit(10)
    );

    const unsubscribe = onSnapshot(membersQuery, (snapshot) => {
      const members = snapshot.docs.map(doc => ({
        id: doc.id,
        fullName: doc.data().fullName || 'Unknown Member',
        createdAt: doc.data().createdAt?.toDate() || new Date(),
        memberType: doc.data().memberType || 'Regular'
      }));

      // Additional sort to ensure correct order
      members.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());

      setStats(prev => ({ ...prev, recentMembers: members }));
    });

    fetchDashboardData();
    return () => unsubscribe();
  }, []);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-full">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6 p-6 bg-gradient-to-br from-gray-900 to-gray-800 rounded-xl">
      <div className="flex justify-between items-center">
        <h1 className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">Dashboard Overview</h1>
        <div className="text-sm text-gray-400 bg-gray-800/50 px-4 py-2 rounded-lg">
          Last updated: {new Date().toLocaleDateString()}
        </div>
      </div>

      {/* Stats Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {[
          {
            title: 'Total Members',
            value: stats.totalMembers.toLocaleString(),
            change: '+12%',
            icon: <FiUsers className="w-6 h-6" />,
            gradient: 'from-blue-500 to-blue-600',
            textGradient: 'from-blue-300 to-blue-400'
          },
          {
            title: 'National Members',
            value: stats.nationalMembers.toLocaleString(),
            change: '+8%',
            icon: <FiUsers className="w-6 h-6" />,
            gradient: 'from-emerald-500 to-emerald-600',
            textGradient: 'from-emerald-300 to-emerald-400'
          },
          {
            title: 'Articles Published',
            value: stats.articlesPublished.toLocaleString(),
            change: '+15%',
            icon: <FiFileText className="w-6 h-6" />,
            gradient: 'from-purple-500 to-purple-600',
            textGradient: 'from-purple-300 to-purple-400'
          },
          {
            title: 'Events Published',
            value: stats.eventsPublished.toLocaleString(),
            change: '+10%',
            icon: <FiCalendar className="w-6 h-6" />,
            gradient: 'from-amber-500 to-amber-600',
            textGradient: 'from-amber-300 to-amber-400'
          }
        ].map((stat, index) => (
          <div
            key={index}
            className="relative overflow-hidden p-6 rounded-xl bg-gradient-to-br from-gray-800 to-gray-900 border border-gray-700/50 hover:border-gray-600 transition-all duration-300 hover:scale-[1.02] group"
          >
            <div className="absolute inset-0 bg-gradient-to-br opacity-10 group-hover:opacity-20 transition-opacity duration-300"></div>
            <div className="flex items-center justify-between">
              <div className={`p-3 rounded-lg bg-gradient-to-br ${stat.gradient} text-white`}>
                {stat.icon}
              </div>
              <span className={`text-sm font-medium bg-clip-text text-transparent bg-gradient-to-r ${stat.textGradient}`}>
                {stat.change}
              </span>
            </div>
            <div className="mt-4">
              <h3 className="text-gray-400 font-medium">{stat.title}</h3>
              <p className={`text-2xl font-bold mt-1 bg-clip-text text-transparent bg-gradient-to-r ${stat.textGradient}`}>
                {stat.value}
              </p>
            </div>
          </div>
        ))}
      </div>

      {/* User Growth Chart */}
      <div className="rounded-xl bg-gradient-to-br from-gray-800 to-gray-900 border border-gray-700/50 p-6">
        <h2 className="text-xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">
          Membership Growth
        </h2>
        <div className="h-[300px]">
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart
              data={[
                { date: 'Jan', users: 400 },
                { date: 'Feb', users: 600 },
                { date: 'Mar', users: 800 },
                { date: 'Apr', users: 1000 },
                { date: 'May', users: 1200 },
                { date: 'Jun', users: 1500 }
              ]}
              margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
            >
              <defs>
                <linearGradient id="colorUsers" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#3B82F6" stopOpacity={0.3}/>
                  <stop offset="95%" stopColor="#3B82F6" stopOpacity={0}/>
                </linearGradient>
              </defs>
              <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
              <XAxis dataKey="date" stroke="#9CA3AF" />
              <YAxis stroke="#9CA3AF" />
              <Tooltip
                contentStyle={{
                  backgroundColor: '#1F2937',
                  border: '1px solid #374151',
                  borderRadius: '0.75rem',
                  boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)'
                }}
              />
              <Area
                type="monotone"
                dataKey="users"
                stroke="#3B82F6"
                strokeWidth={2}
                fillOpacity={1}
                fill="url(#colorUsers)"
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* Recent Members */}
      <div className="rounded-xl bg-gradient-to-br from-gray-800 to-gray-900 border border-gray-700/50 p-6">
        <h2 className="text-xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">
          Recent Members
        </h2>
        <div className="space-y-4">
          {stats.recentMembers.length > 0 ? (
            stats.recentMembers.map((member) => (
              <div
                key={member.id}
                className="flex items-center justify-between p-4 rounded-lg bg-gradient-to-br from-gray-900 to-gray-800 hover:from-gray-800 hover:to-gray-900 border border-gray-700/50 hover:border-gray-600 transition-all duration-300"
              >
                <div className="flex items-center space-x-3">
                  <div className="p-2 rounded-lg bg-gradient-to-br from-blue-500 to-blue-600 text-white">
                    <FiUsers />
                  </div>
                  <div>
                    <p className="font-medium text-gray-200">{member.fullName}</p>
                    <p className="text-sm text-gray-400">
                      <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">
                        {member.memberType} Member
                      </span>
                      {' • '}
                      {new Date(member.createdAt).toLocaleString('en-US', {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit'
                      })}
                    </p>
                  </div>
                </div>
                <button 
                  className="px-4 py-2 text-sm rounded-lg bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 text-white font-medium transition-all duration-300 hover:scale-105 hover:shadow-lg hover:shadow-blue-500/25"
                >
                  View Profile
                </button>
              </div>
            ))
          ) : (
            <div className="text-center text-gray-400 py-8 bg-gray-900/50 rounded-lg border border-gray-800">
              No recent members
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardHome;
