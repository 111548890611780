import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FiSave, FiUsers, FiBell, FiArrowLeft } from 'react-icons/fi';
import { collection, addDoc, serverTimestamp, getDocs } from 'firebase/firestore';
import { db } from '../../config/firebase';
import toast from 'react-hot-toast';
import Select from 'react-select';
import { Link } from 'react-router-dom';

interface UserOption {
  value: string;
  label: string;
  email: string;
}

interface NotificationForm {
  title: string;
  message: string;
  targetAudience: 'all' | 'admin' | 'private';
  targetUserId?: string;
  scheduledDate: string;
  priority: 'low' | 'medium' | 'high';
}

const CreateNotification: React.FC = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>('');
  const [userOptions, setUserOptions] = useState<UserOption[]>([]);
  const [selectedUser, setSelectedUser] = useState<UserOption | null>(null);
  const [formData, setFormData] = useState<NotificationForm>({
    title: '',
    message: '',
    targetAudience: 'all',
    scheduledDate: '',
    priority: 'medium',
  });

  useEffect(() => {
    const fetchUsers = async () => {
      const usersRef = collection(db, 'userDetails');
      const usersSnap = await getDocs(usersRef);
      const options = usersSnap.docs.map(doc => ({
        value: doc.id,
        label: doc.data().fullName || 'Unknown',
        email: doc.data().authEmail || ''
      }));
      setUserOptions(options);
    };

    fetchUsers();
  }, []);

  const handleSubmit = async (e: React.FormEvent, sendNow: boolean = false) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      const notificationData = {
        title: formData.title,
        message: formData.message,
        targetAudience: formData.targetAudience,
        scheduledDate: sendNow ? new Date().toISOString() : formData.scheduledDate,
        priority: formData.priority,
        status: sendNow ? 'sent' : 'scheduled',
        createdAt: serverTimestamp(),
        ...(formData.targetAudience === 'private' && formData.targetUserId 
          ? { targetUserId: formData.targetUserId }
          : {})
      };

      await addDoc(collection(db, 'notifications'), notificationData);
      toast.success('Notification created successfully!');
      navigate('/admin/notifications');
    } catch (error) {
      console.error('Error creating notification:', error);
      setError('Failed to create notification. Please try again.');
      toast.error('Failed to create notification');
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));

    if (name === 'targetAudience' && value !== 'private') {
      setSelectedUser(null);
      setFormData(prev => ({
        ...prev,
        targetUserId: undefined
      }));
    }
  };

  const handleUserSelect = (option: UserOption | null) => {
    setSelectedUser(option);
    setFormData(prev => ({
      ...prev,
      targetUserId: option?.value
    }));
  };

  const formatOptionLabel = ({ label, email }: UserOption) => (
    <div className="flex flex-col">
      <div>{label}</div>
      <div className="text-sm text-gray-500">{email}</div>
    </div>
  );

  return (
    <div className="min-h-screen bg-gray-900">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex items-center mb-8">
          <Link 
            to="/admin/notifications"
            className="flex items-center text-gray-400 hover:text-white transition-colors mr-4"
          >
            <FiArrowLeft className="w-6 h-6" />
          </Link>
          <div>
            <h1 className="text-2xl sm:text-3xl font-bold text-white">Create Notification</h1>
            <p className="mt-2 text-gray-400">Send notifications to your users</p>
          </div>
        </div>

        <div className="bg-gray-800 rounded-lg shadow p-4 sm:p-6">
          <form onSubmit={(e) => handleSubmit(e)} className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-200 mb-2">
                Title
              </label>
              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={handleInputChange}
                required
                className="w-full px-4 py-2 bg-gray-700 text-white border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent placeholder-gray-400"
                placeholder="Enter notification title"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-200 mb-2">
                Message
              </label>
              <textarea
                name="message"
                value={formData.message}
                onChange={handleInputChange}
                required
                rows={4}
                className="w-full px-4 py-2 bg-gray-700 text-white border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent placeholder-gray-400 resize-none"
                placeholder="Enter notification message"
              />
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-200 mb-2">
                  Target Audience
                </label>
                <select
                  name="targetAudience"
                  value={formData.targetAudience}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 bg-gray-700 text-white border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                >
                  <option value="all">All Logged-in Users</option>
                  <option value="admin">Admins Only</option>
                  <option value="private">Private Message</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-200 mb-2">
                  Priority
                </label>
                <select
                  name="priority"
                  value={formData.priority}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 bg-gray-700 text-white border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                >
                  <option value="low">Low</option>
                  <option value="medium">Medium</option>
                  <option value="high">High</option>
                </select>
              </div>
            </div>

            {formData.targetAudience === 'private' && (
              <div>
                <label className="block text-sm font-medium text-gray-200 mb-2">
                  Select User
                </label>
                <Select
                  value={selectedUser}
                  onChange={handleUserSelect}
                  options={userOptions}
                  formatOptionLabel={formatOptionLabel}
                  placeholder="Search user by name or email..."
                  isClearable
                  isSearchable
                  className="text-sm"
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      backgroundColor: '#374151',
                      borderColor: state.isFocused ? '#3B82F6' : '#4B5563',
                      boxShadow: state.isFocused ? '0 0 0 1px #3B82F6' : 'none',
                      '&:hover': {
                        borderColor: '#3B82F6'
                      }
                    }),
                    menu: (base) => ({
                      ...base,
                      backgroundColor: '#374151',
                      border: '1px solid #4B5563'
                    }),
                    option: (base, state) => ({
                      ...base,
                      backgroundColor: state.isSelected 
                        ? '#3B82F6' 
                        : state.isFocused 
                        ? '#4B5563' 
                        : '#374151',
                      color: 'white',
                      cursor: 'pointer'
                    }),
                    singleValue: (base) => ({
                      ...base,
                      color: 'white'
                    }),
                    input: (base) => ({
                      ...base,
                      color: 'white'
                    }),
                    placeholder: (base) => ({
                      ...base,
                      color: '#9CA3AF'
                    })
                  }}
                />
              </div>
            )}

            <div>
              <label className="block text-sm font-medium text-gray-200 mb-2">
                Scheduled Date
              </label>
              <input
                type="datetime-local"
                name="scheduledDate"
                value={formData.scheduledDate}
                onChange={handleInputChange}
                required
                className="w-full px-4 py-2 bg-gray-700 text-white border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>

            {error && (
              <div className="text-red-400 text-sm bg-red-900/50 border border-red-700 rounded-lg p-3">
                {error}
              </div>
            )}

            <div className="flex flex-col sm:flex-row gap-4 pt-6">
              <button
                type="submit"
                disabled={isLoading}
                className="flex-1 flex items-center justify-center px-4 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-blue-600"
              >
                <FiSave className="mr-2" />
                {isLoading ? 'Scheduling...' : 'Schedule'}
              </button>
              <button
                type="button"
                disabled={isLoading}
                onClick={(e) => handleSubmit(e, true)}
                className="flex-1 flex items-center justify-center px-4 py-3 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-green-600"
              >
                <FiBell className="mr-2" />
                {isLoading ? 'Sending...' : 'Send Now'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateNotification;
