import React, { useState } from 'react';
import { Card } from '../components/ui/card';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from 'recharts';

const mockSectionCompletion = [
  { name: 'Authentication Details', complete: 85, incomplete: 15 },
  { name: 'Personal Details', complete: 92, incomplete: 8 },
  { name: 'Current Address', complete: 78, incomplete: 22 },
  { name: 'Permanent Address', complete: 75, incomplete: 25 },
  { name: 'Witness Details', complete: 70, incomplete: 30 },
  { name: 'Official Use Only', complete: 95, incomplete: 5 },
  { name: 'Document Uploads', complete: 65, incomplete: 35 },
  { name: 'Update Details', complete: 88, incomplete: 12 },
];

const documentTypes = [
  { name: 'ID Proof', value: 35 },
  { name: 'Address Proof', value: 25 },
  { name: 'Photos', value: 20 },
  { name: 'Other Documents', value: 20 },
];

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8'];

export default function Analysis() {
  const [activeSection, setActiveSection] = useState('all');

  return (
    <div className="p-6 space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold">Membership Analysis Dashboard</h1>
        <select 
          className="bg-dark-secondary text-white border border-gray-600 rounded-lg px-4 py-2"
          value={activeSection}
          onChange={(e) => setActiveSection(e.target.value)}
        >
          <option value="all">All Sections</option>
          <option value="auth">Authentication</option>
          <option value="personal">Personal Details</option>
          <option value="address">Address Details</option>
          <option value="witness">Witness Details</option>
          <option value="official">Official Use</option>
          <option value="documents">Documents</option>
        </select>
      </div>
      
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
        <Card className="p-4 bg-gradient-to-br from-blue-500/10 to-blue-600/10">
          <h3 className="text-sm text-blue-400">Total Applications</h3>
          <p className="text-2xl font-bold">1,234</p>
          <span className="text-sm text-green-400">↑ 12% this month</span>
        </Card>
        <Card className="p-4 bg-gradient-to-br from-green-500/10 to-green-600/10">
          <h3 className="text-sm text-green-400">Completed Applications</h3>
          <p className="text-2xl font-bold">956</p>
          <span className="text-sm text-green-400">↑ 8% this month</span>
        </Card>
        <Card className="p-4 bg-gradient-to-br from-yellow-500/10 to-yellow-600/10">
          <h3 className="text-sm text-yellow-400">Pending Review</h3>
          <p className="text-2xl font-bold">189</p>
          <span className="text-sm text-yellow-400">↓ 5% this month</span>
        </Card>
        <Card className="p-4 bg-gradient-to-br from-purple-500/10 to-purple-600/10">
          <h3 className="text-sm text-purple-400">Rejected Applications</h3>
          <p className="text-2xl font-bold">89</p>
          <span className="text-sm text-red-400">↑ 2% this month</span>
        </Card>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <Card className="p-4">
          <h2 className="text-lg font-semibold mb-4">Section Completion Rates</h2>
          <ResponsiveContainer width="100%" height={400}>
            <BarChart
              data={mockSectionCompletion}
              layout="vertical"
              margin={{ top: 5, right: 30, left: 100, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis type="number" domain={[0, 100]} />
              <YAxis dataKey="name" type="category" width={100} />
              <Tooltip />
              <Legend />
              <Bar dataKey="complete" fill="#4CAF50" name="Complete %" stackId="a" />
              <Bar dataKey="incomplete" fill="#ff9800" name="Incomplete %" stackId="a" />
            </BarChart>
          </ResponsiveContainer>
        </Card>

        <Card className="p-4">
          <h2 className="text-lg font-semibold mb-4">Document Distribution</h2>
          <ResponsiveContainer width="100%" height={400}>
            <PieChart>
              <Pie
                data={documentTypes}
                cx="50%"
                cy="50%"
                labelLine={false}
                label={({ name, percent }) => `${name} (${(percent * 100).toFixed(0)}%)`}
                outerRadius={150}
                fill="#8884d8"
                dataKey="value"
              >
                {documentTypes.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </Card>
      </div>

      <div className="grid grid-cols-1 gap-6">
        <Card className="p-4">
          <h2 className="text-lg font-semibold mb-4">Section-wise Analysis</h2>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
            <div className="space-y-2">
              <h3 className="font-medium">Authentication Details</h3>
              <div className="flex justify-between text-sm">
                <span>Email Verified</span>
                <span className="text-green-400">92%</span>
              </div>
              <div className="flex justify-between text-sm">
                <span>Phone Verified</span>
                <span className="text-green-400">88%</span>
              </div>
            </div>
            <div className="space-y-2">
              <h3 className="font-medium">Address Details</h3>
              <div className="flex justify-between text-sm">
                <span>Current Address</span>
                <span className="text-yellow-400">78%</span>
              </div>
              <div className="flex justify-between text-sm">
                <span>Permanent Address</span>
                <span className="text-yellow-400">75%</span>
              </div>
            </div>
            <div className="space-y-2">
              <h3 className="font-medium">Document Status</h3>
              <div className="flex justify-between text-sm">
                <span>Uploaded</span>
                <span className="text-green-400">85%</span>
              </div>
              <div className="flex justify-between text-sm">
                <span>Verified</span>
                <span className="text-yellow-400">65%</span>
              </div>
            </div>
            <div className="space-y-2">
              <h3 className="font-medium">Official Processing</h3>
              <div className="flex justify-between text-sm">
                <span>Reviewed</span>
                <span className="text-green-400">95%</span>
              </div>
              <div className="flex justify-between text-sm">
                <span>Approved</span>
                <span className="text-green-400">88%</span>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}
