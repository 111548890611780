import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FiBell, FiEdit2, FiTrash2, FiUsers, FiCalendar, FiClock } from 'react-icons/fi';
import { collection, query, orderBy, onSnapshot, Timestamp, deleteDoc, doc, getDocs } from 'firebase/firestore';
import { db } from '../../config/firebase';
import toast from 'react-hot-toast';

interface AdminNotification {
  id: string;
  title: string;
  message: string;
  targetAudience: 'all' | 'admin' | 'private';
  targetUserId?: string;
  scheduledDate: string;
  priority: 'low' | 'medium' | 'high';
  status: 'scheduled' | 'sent' | 'failed';
  createdAt: Timestamp;
}

const NotificationsList: React.FC = () => {
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState<AdminNotification[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [users, setUsers] = useState<Record<string, { fullName: string; email: string }>>({});
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [notificationToDelete, setNotificationToDelete] = useState<AdminNotification | null>(null);

  useEffect(() => {
    const notificationsRef = collection(db, 'notifications');
    const q = query(notificationsRef, orderBy('createdAt', 'desc'));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const notificationsList = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as AdminNotification[];
      
      setNotifications(notificationsList);
      setIsLoading(false);
    }, (error) => {
      console.error('Error fetching notifications:', error);
      toast.error('Failed to load notifications');
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchUsers = async () => {
      const usersRef = collection(db, 'userDetails');
      const usersSnap = await getDocs(usersRef);
      const usersData = Object.fromEntries(
        usersSnap.docs.map(doc => [
          doc.id,
          { 
            fullName: doc.data().fullName || 'Unknown',
            email: doc.data().authEmail || ''
          }
        ])
      );
      setUsers(usersData);
    };

    fetchUsers();
  }, []);

  const handleDeleteClick = (notification: AdminNotification) => {
    setNotificationToDelete(notification);
    setDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (!notificationToDelete) return;

    try {
      await deleteDoc(doc(db, 'notifications', notificationToDelete.id));
      toast.success('Notification deleted successfully');
      setDeleteModalOpen(false);
      setNotificationToDelete(null);
    } catch (error) {
      console.error('Error deleting notification:', error);
      toast.error('Failed to delete notification');
    }
  };

  const handleDeleteCancel = () => {
    setDeleteModalOpen(false);
    setNotificationToDelete(null);
  };

  const filteredNotifications = notifications.filter(notification =>
    notification.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
    notification.message.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const formatTimestamp = (timestamp: Timestamp) => {
    return new Date(timestamp.toDate()).toLocaleTimeString();
  };

  return (
    <div className="p-6 bg-gray-900 min-h-screen">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-semibold text-white">Notifications</h1>
        <button
          onClick={() => navigate('/admin/notifications/create')}
          className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
        >
          <FiBell className="mr-2" />
          Create Notification
        </button>
      </div>

      <div className="mb-4">
        <input
          type="text"
          placeholder="Search notifications..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="w-full px-4 py-2 bg-gray-800 text-white border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 placeholder-gray-400"
        />
      </div>

      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
        </div>
      ) : filteredNotifications.length === 0 ? (
        <div className="text-center py-12 text-gray-400">
          No notifications found
        </div>
      ) : (
        <div className="grid gap-4">
          {filteredNotifications.map((notification) => (
            <div
              key={notification.id}
              className="bg-gray-800 rounded-lg p-4 shadow-lg border border-gray-700 hover:border-gray-600 transition-colors"
            >
              <div className="flex justify-between items-start mb-2">
                <h3 className="text-lg font-medium text-white">{notification.title}</h3>
                <div className="flex space-x-2">
                  <button
                    onClick={() => navigate(`/admin/notifications/edit/${notification.id}`)}
                    className="p-2 text-gray-400 hover:text-blue-500 transition-colors"
                  >
                    <FiEdit2 />
                  </button>
                  <button
                    onClick={() => handleDeleteClick(notification)}
                    className="p-2 text-gray-400 hover:text-red-500 transition-colors"
                  >
                    <FiTrash2 />
                  </button>
                </div>
              </div>

              <p className="text-gray-300 mb-4">{notification.message}</p>

              <div className="flex flex-wrap gap-4 text-sm text-gray-400">
                <div className="flex items-center">
                  <FiUsers className="mr-1" />
                  <span>
                    {notification.targetAudience === 'all' 
                      ? 'All Logged-in Users'
                      : notification.targetAudience === 'admin'
                      ? 'Admins Only'
                      : notification.targetAudience === 'private' && notification.targetUserId
                      ? `Private: ${users[notification.targetUserId]?.fullName || 'Unknown'}`
                      : 'Unknown Target'}
                  </span>
                </div>
                <div className="flex items-center">
                  <FiCalendar className="mr-1" />
                  <span>{notification.scheduledDate}</span>
                </div>
                <div className="flex items-center">
                  <FiClock className="mr-1" />
                  <span>{formatTimestamp(notification.createdAt)}</span>
                </div>
                <div className={`
                  px-2 py-1 rounded-full text-xs
                  ${notification.priority === 'high' ? 'bg-red-900 text-red-200' : 
                    notification.priority === 'medium' ? 'bg-yellow-900 text-yellow-200' : 
                    'bg-green-900 text-green-200'}
                `}>
                  {notification.priority.charAt(0).toUpperCase() + notification.priority.slice(1)} Priority
                </div>
                <div className={`
                  px-2 py-1 rounded-full text-xs
                  ${notification.status === 'sent' ? 'bg-green-900 text-green-200' : 
                    notification.status === 'failed' ? 'bg-red-900 text-red-200' : 
                    'bg-blue-900 text-blue-200'}
                `}>
                  {notification.status.charAt(0).toUpperCase() + notification.status.slice(1)}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      {/* Delete Confirmation Modal */}
      {deleteModalOpen && notificationToDelete && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-gray-800 rounded-lg shadow-xl w-full max-w-2xl p-8">
            <div className="flex flex-col items-center text-center mb-8">
              <div className="w-16 h-16 bg-red-900/20 rounded-full flex items-center justify-center mb-4">
                <FiTrash2 className="w-8 h-8 text-red-500" />
              </div>
              <h3 className="text-2xl font-bold text-white">Delete Notification?</h3>
              <p className="mt-4 text-gray-300 text-lg">
                "{notificationToDelete.title}"
              </p>
            </div>
            <p className="text-gray-400 text-center mb-8">
              This action cannot be undone. This will permanently delete this notification.
            </p>
            <div className="flex justify-center space-x-4">
              <button
                onClick={handleDeleteCancel}
                className="px-6 py-3 bg-gray-700 text-gray-300 rounded-lg hover:bg-gray-600 transition-colors min-w-[120px]"
              >
                Cancel
              </button>
              <button
                onClick={handleDeleteConfirm}
                className="px-6 py-3 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors min-w-[120px]"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NotificationsList;
