import React, { useEffect } from 'react';
import { MemberType, MemberRank } from '../types/memberTypes';

interface OfficialUseOnlyProps {
  userData: {
    memberType: MemberType;
    memberStatus: string;
    memberRank: MemberRank;
    officeState?: string;
    officeDistrict?: string;
    officeCityVillage?: string;
    enrollmentNumber?: string;
    range?: string;
    validFrom?: string;
    validUntil?: string;
    designation?: string;
  };
  onInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  onEnrollmentNumberGenerated?: (enrollmentNumber: string) => void;
}

const generateRandomNumber = () => {
  return Math.floor(Math.random() * 100000000).toString().padStart(8, '0');
};

const getLocationCode = (location: string | undefined) => {
  if (!location) return '';
  const words = location.trim().split(' ');
  if (words.length >= 2) {
    return (words[0][0] + words[1][0]).toUpperCase();
  }
  return location.substring(0, 2).toUpperCase();
};

const OfficialUseOnly: React.FC<OfficialUseOnlyProps> = ({
  userData,
  onInputChange,
  onEnrollmentNumberGenerated
}) => {
  useEffect(() => {
    if (userData.memberType) {
      const randomNum = generateRandomNumber();
      let enrollmentNumber = '';

      const stateCode = getLocationCode(userData.officeState);
      const districtCode = getLocationCode(userData.officeDistrict);
      const cityCode = getLocationCode(userData.officeCityVillage);

      switch (userData.memberType) {
        case 'National':
          enrollmentNumber = `NTL/${randomNum}`;
          break;
        case 'State':
          enrollmentNumber = `${stateCode}/${randomNum}`;
          break;
        case 'District':
          enrollmentNumber = `${stateCode}/${districtCode}/${randomNum}`;
          break;
        case 'Range':
          enrollmentNumber = `RG/${stateCode}/${randomNum}`;
          break;
        case 'Centre Zone':
          enrollmentNumber = `CZ/${randomNum}`;
          break;
        case 'East Zone':
          enrollmentNumber = `EZ/${randomNum}`;
          break;
        case 'West Zone':
          enrollmentNumber = `WZ/${randomNum}`;
          break;
        case 'North Zone':
          enrollmentNumber = `NZ/${randomNum}`;
          break;
        case 'South Zone':
          enrollmentNumber = `SZ/${randomNum}`;
          break;
        default: // Member
          if (userData.officeState && userData.officeDistrict && userData.officeCityVillage) {
            enrollmentNumber = `${stateCode}/${districtCode}/${cityCode}/${randomNum}`;
          }
          break;
      }

      if (enrollmentNumber && onEnrollmentNumberGenerated) {
        onEnrollmentNumberGenerated(enrollmentNumber);
      }
    }
  }, [userData.memberType, userData.officeState, userData.officeDistrict, userData.officeCityVillage]);

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <label className="block text-sm font-medium mb-2">Member Type</label>
          <select
            name="memberType"
            value={userData.memberType}
            onChange={onInputChange}
            className="w-full px-3 py-2 bg-slate-800 border border-slate-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          >
            <option value="" className="bg-gray-700">Select Member Type</option>
            <option value="National" className="bg-gray-700">National</option>
            <option value="Range" className="bg-gray-700">Range</option>
            <option value="Centre Zone" className="bg-gray-700">Centre Zone</option>
            <option value="North Zone" className="bg-gray-700">North Zone</option>
            <option value="East Zone" className="bg-gray-700">East Zone</option>
            <option value="West Zone" className="bg-gray-700">West Zone</option>
            <option value="South Zone" className="bg-gray-700">South Zone</option>
            <option value="State" className="bg-gray-700">State</option>
            <option value="District" className="bg-gray-700">District</option>
            <option value="Member" className="bg-gray-700">Member</option>
          </select>
        </div>

        {userData.memberType === 'Range' && (
          <div>
            <label className="block text-sm font-medium mb-2">Range</label>
            <input
              type="text"
              name="range"
              value={userData.range}
              onChange={onInputChange}
              className="w-full px-3 py-2 bg-slate-800 border border-slate-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
        )}

        <div>
          <label className="block text-sm font-medium mb-2">Member Status</label>
          <select
            name="memberStatus"
            value={userData.memberStatus}
            onChange={onInputChange}
            className="w-full px-3 py-2 bg-slate-800 border border-slate-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          >
            <option value="" className="bg-gray-700">Select Status</option>
            <option value="Active" className="bg-gray-700">Active</option>
            <option value="Inactive" className="bg-gray-700">Inactive</option>
            <option value="Suspended" className="bg-gray-700">Suspended</option>
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium mb-2">Designation</label>
          <select
            name="designation"
            value={userData.designation || ''}
            onChange={onInputChange}
            className="w-full px-3 py-2 bg-slate-800 border border-slate-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="" className="bg-gray-700">Select Designation</option>
            <option value="President" className="bg-gray-700">President</option>
            <option value="ADL President" className="bg-gray-700">ADL President</option>
            <option value="Senior Vice President" className="bg-gray-700">Senior Vice President</option>
            <option value="Vice President" className="bg-gray-700">Vice President</option>
            <option value="Secretary" className="bg-gray-700">Secretary</option>
            <option value="General Secretary" className="bg-gray-700">General Secretary</option>
            <option value="Joint Secretary" className="bg-gray-700">Joint Secretary</option>
            <option value="Senior Advisor" className="bg-gray-700">Senior Advisor</option>
            <option value="Advisor" className="bg-gray-700">Advisor</option>
            <option value="IT Incharge" className="bg-gray-700">IT Incharge</option>
            <option value="Special Officer" className="bg-gray-700">Special Officer</option>
            <option value="Media Co-ordinator" className="bg-gray-700">Media Co-ordinator</option>
            <option value="Legal Advisor" className="bg-gray-700">Legal Advisor</option>
            <option value="Lifetime Member" className="bg-gray-700">Lifetime Member</option>
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium mb-2">Office State</label>
          <input
            type="text"
            name="officeState"
            value={userData.officeState}
            onChange={onInputChange}
            className="w-full px-3 py-2 bg-slate-800 border border-slate-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium mb-2">Office District</label>
          <input
            type="text"
            name="officeDistrict"
            value={userData.officeDistrict}
            onChange={onInputChange}
            className="w-full px-3 py-2 bg-slate-800 border border-slate-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium mb-2">Office City/Village</label>
          <input
            type="text"
            name="officeCityVillage"
            value={userData.officeCityVillage}
            onChange={onInputChange}
            className="w-full px-3 py-2 bg-slate-800 border border-slate-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium mb-2">Enrollment Number</label>
          <div className="flex gap-2">
            <input
              type="text"
              name="enrollmentNumber"
              value={userData.enrollmentNumber}
              onChange={onInputChange}
              className="flex-1 px-3 py-2 bg-slate-800 border border-slate-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              readOnly
            />
            <button
              type="button"
              onClick={() => {
                if (userData.memberType) {
                  const randomNum = generateRandomNumber();
                  let enrollmentNumber = '';

                  const stateCode = getLocationCode(userData.officeState);
                  const districtCode = getLocationCode(userData.officeDistrict);
                  const cityCode = getLocationCode(userData.officeCityVillage);

                  switch (userData.memberType) {
                    case 'National':
                      enrollmentNumber = `NTL/${randomNum}`;
                      break;
                    case 'State':
                      enrollmentNumber = `${stateCode}/${randomNum}`;
                      break;
                    case 'District':
                      enrollmentNumber = `${stateCode}/${districtCode}/${randomNum}`;
                      break;
                    case 'Range':
                      enrollmentNumber = `RG/${stateCode}/${randomNum}`;
                      break;
                    case 'Centre Zone':
                      enrollmentNumber = `CZ/${randomNum}`;
                      break;
                    case 'East Zone':
                      enrollmentNumber = `EZ/${randomNum}`;
                      break;
                    case 'West Zone':
                      enrollmentNumber = `WZ/${randomNum}`;
                      break;
                    case 'North Zone':
                      enrollmentNumber = `NZ/${randomNum}`;
                      break;
                    case 'South Zone':
                      enrollmentNumber = `SZ/${randomNum}`;
                      break;
                    default: // Member
                      if (userData.officeState && userData.officeDistrict && userData.officeCityVillage) {
                        enrollmentNumber = `${stateCode}/${districtCode}/${cityCode}/${randomNum}`;
                      }
                      break;
                  }

                  if (enrollmentNumber && onEnrollmentNumberGenerated) {
                    onEnrollmentNumberGenerated(enrollmentNumber);
                  }
                }
              }}
              className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              Generate
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfficialUseOnly;
