import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FiBell, FiEdit2, FiTrash2, FiUser, FiCalendar, FiClock } from 'react-icons/fi';

interface UserNotification {
  id: string;
  title: string;
  message: string;
  userId: string;  // Specific user ID
  userName: string;  // User's name for display
  scheduledDate: string;
  priority: 'low' | 'medium' | 'high';
  status: 'scheduled' | 'sent' | 'failed';
  type: 'private';  // Always private for user notifications
}

const UserNotificationsList: React.FC = () => {
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState<UserNotification[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        // TODO: Replace with actual API call
        const mockNotifications: UserNotification[] = [
          {
            id: '1',
            title: 'Account Update Required',
            message: 'Please update your profile information...',
            userId: 'user1',
            userName: 'John Doe',
            scheduledDate: '2024-02-01T10:00',
            priority: 'high',
            status: 'scheduled',
            type: 'private',
          },
          {
            id: '2',
            title: 'Subscription Renewal',
            message: 'Your subscription is due for renewal...',
            userId: 'user2',
            userName: 'Jane Smith',
            scheduledDate: '2024-01-15T14:30',
            priority: 'medium',
            status: 'sent',
            type: 'private',
          },
        ];

        setNotifications(mockNotifications);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching notifications:', error);
        setIsLoading(false);
      }
    };

    fetchNotifications();
  }, []);

  const filteredNotifications = notifications.filter(notification =>
    notification.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
    notification.message.toLowerCase().includes(searchQuery.toLowerCase()) ||
    notification.userName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const getPriorityColor = (priority: UserNotification['priority']) => {
    switch (priority) {
      case 'high':
        return 'text-red-400';
      case 'medium':
        return 'text-yellow-400';
      case 'low':
        return 'text-green-400';
      default:
        return 'text-gray-400';
    }
  };

  const getStatusColor = (status: UserNotification['status']) => {
    switch (status) {
      case 'sent':
        return 'text-green-400';
      case 'scheduled':
        return 'text-blue-400';
      case 'failed':
        return 'text-red-400';
      default:
        return 'text-gray-400';
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-full">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-white"></div>
      </div>
    );
  }

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-white">Private User Notifications</h1>
        <button
          onClick={() => navigate('/admin/user-notifications/create')}
          className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 flex items-center"
        >
          <FiBell className="mr-2" />
          Create Private Notification
        </button>
      </div>

      <div className="mb-6">
        <input
          type="text"
          placeholder="Search notifications by title, message, or user name..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="w-full px-4 py-2 bg-gray-700 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>

      <div className="grid gap-6">
        {filteredNotifications.map((notification) => (
          <div
            key={notification.id}
            className="bg-gray-800 rounded-lg shadow-lg overflow-hidden"
          >
            <div className="p-6">
              <div className="flex justify-between items-start">
                <div className="flex-1">
                  <h2 className="text-xl font-semibold text-white mb-2">
                    {notification.title}
                  </h2>
                  <p className="text-gray-400 text-sm mb-4">
                    {notification.message}
                  </p>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4 text-sm text-gray-400">
                    <div className="flex items-center">
                      <FiUser className="mr-2" />
                      User: {notification.userName}
                    </div>
                    <div className="flex items-center">
                      <FiCalendar className="mr-2" />
                      {new Date(notification.scheduledDate).toLocaleDateString()}
                    </div>
                    <div className="flex items-center">
                      <FiClock className="mr-2" />
                      {new Date(notification.scheduledDate).toLocaleTimeString()}
                    </div>
                  </div>
                  <div className="flex items-center mt-4 space-x-4">
                    <span className={`text-sm capitalize ${getPriorityColor(notification.priority)}`}>
                      {notification.priority} Priority
                    </span>
                    <span className={`text-sm capitalize ${getStatusColor(notification.status)}`}>
                      {notification.status}
                    </span>
                    <span className="text-sm text-purple-400">
                      Private Message
                    </span>
                  </div>
                </div>
                <div className="flex space-x-2 ml-4">
                  <button
                    onClick={() => navigate(`/admin/user-notifications/edit/${notification.id}`)}
                    className="p-2 text-blue-400 hover:text-blue-300"
                    title="Edit"
                  >
                    <FiEdit2 />
                  </button>
                  <button
                    onClick={() => navigate(`/admin/user-notifications/delete/${notification.id}`)}
                    className="p-2 text-red-400 hover:text-red-300"
                    title="Delete"
                  >
                    <FiTrash2 />
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}

        {filteredNotifications.length === 0 && (
          <div className="text-center py-12 bg-gray-800 rounded-lg">
            <FiBell className="mx-auto h-12 w-12 text-gray-400 mb-4" />
            <div className="text-gray-400 mb-2">No private notifications found</div>
            <button
              onClick={() => navigate('/admin/user-notifications/create')}
              className="text-blue-400 hover:text-blue-300"
            >
              Create a new private notification
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserNotificationsList;
