import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { doc, getDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { FiAlertTriangle } from 'react-icons/fi';
import toast from 'react-hot-toast';

interface Notification {
  title: string;
  message: string;
}

const DeleteNotification: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [notification, setNotification] = useState<Notification | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    const fetchNotification = async () => {
      if (!id) return;

      try {
        const notificationRef = doc(db, 'notifications', id);
        const notificationSnap = await getDoc(notificationRef);

        if (notificationSnap.exists()) {
          setNotification(notificationSnap.data() as Notification);
        } else {
          setError('Notification not found');
          toast.error('Notification not found');
        }
      } catch (error) {
        console.error('Error fetching notification:', error);
        setError('Failed to fetch notification');
        toast.error('Failed to fetch notification');
      }
    };

    fetchNotification();
  }, [id]);

  const handleDelete = async () => {
    if (!id) return;

    setIsLoading(true);
    try {
      await deleteDoc(doc(db, 'notifications', id));
      toast.success('Notification deleted successfully');
      navigate('/notifications');
    } catch (error) {
      console.error('Error deleting notification:', error);
      setError('Failed to delete notification');
      toast.error('Failed to delete notification');
    } finally {
      setIsLoading(false);
    }
  };

  if (!notification && !error) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  return (
    <div className="p-6">
      <div className="max-w-md mx-auto bg-white rounded-lg shadow-md overflow-hidden">
        <div className="p-6">
          <div className="flex items-center justify-center text-red-500 mb-4">
            <FiAlertTriangle size={48} />
          </div>
          <h2 className="text-2xl font-semibold text-center text-gray-800 mb-4">
            Delete Notification
          </h2>
          {error ? (
            <div className="text-red-600 text-center mb-4">{error}</div>
          ) : (
            <>
              <p className="text-gray-600 text-center mb-6">
                Are you sure you want to delete this notification?
                This action cannot be undone.
              </p>
              <div className="bg-gray-50 p-4 rounded-lg mb-6">
                <h3 className="font-medium text-gray-800 mb-2">
                  {notification?.title}
                </h3>
                <p className="text-gray-600 text-sm">
                  {notification?.message}
                </p>
              </div>
            </>
          )}
          <div className="flex justify-center space-x-4">
            <button
              onClick={() => navigate('/notifications')}
              className="px-4 py-2 text-gray-600 hover:text-gray-800 transition-colors"
            >
              Cancel
            </button>
            <button
              onClick={handleDelete}
              disabled={isLoading || !!error}
              className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors disabled:opacity-50"
            >
              {isLoading ? 'Deleting...' : 'Delete'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteNotification;
