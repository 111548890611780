import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FiUpload, FiX, FiArrowLeft, FiImage } from 'react-icons/fi';
import { storage } from '../../config/firebase';
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage';
import { collection, doc, getDoc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { toast } from 'react-hot-toast';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

interface UserData {
  id?: string;
  uid?: string;
  email?: string;
  isAdmin?: boolean;
  userRole?: string;
  authUid?: string;
}

interface GalleryFormData {
  title: string;
  description: string;
  category: string;
  isPublished: boolean;
}

interface PhotoFile extends File {
  preview?: string;
  originalFile?: File;
}

interface CompressedPhoto {
  file: File;
  originalFile?: File;
  title?: string;
  description?: string;
  preview: string;
}

interface ExistingPhoto {
  url: string;
  title: string;
  description: string;
  fileName: string;
  uploadedAt: string;
  size: number;
  type: string;
}

const EditPhotoGallery: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const auth = getAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState<GalleryFormData>({
    title: '',
    description: '',
    category: '',
    isPublished: true
  });
  const [photos, setPhotos] = useState<CompressedPhoto[]>([]);
  const [existingPhotos, setExistingPhotos] = useState<ExistingPhoto[]>([]);
  const [isDragging, setIsDragging] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        await fetchGalleryData();
        setIsLoading(false);
      } else {
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, [auth, navigate]);

  const fetchGalleryData = async () => {
    if (!id) {
      navigate('/admin/photo-gallery');
      return;
    }

    try {
      const galleryRef = doc(db, 'photo-gallery', id);
      const galleryDoc = await getDoc(galleryRef);

      if (!galleryDoc.exists()) {
        toast.error('Gallery not found');
        navigate('/admin/photo-gallery');
        return;
      }

      const galleryData = galleryDoc.data();

      setFormData({
        title: galleryData.title || '',
        description: galleryData.description || '',
        category: galleryData.category || '',
        isPublished: galleryData.isPublished ?? false,
      });

      if (galleryData.photos) {
        const photos = galleryData.photos.map((photo: any) => ({
          url: photo.url || '',
          title: photo.title || '',
          description: photo.description || '',
          fileName: photo.fileName || '',
          uploadedAt: photo.uploadedAt || new Date().toISOString(),
          size: photo.size || 0,
          type: photo.type || 'image/jpeg'
        }));
        setExistingPhotos(photos);
      }
    } catch (error) {
      console.error('Error fetching gallery:', error);
      toast.error('Failed to load gallery data');
      navigate('/admin/photo-gallery');
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleDragEnter = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleImageUpload = async (file: File, galleryId: string): Promise<string> => {
    try {
      if (!file.type.startsWith('image/')) {
        throw new Error('Please upload a valid image file');
      }

      const fileName = `${Date.now()}_${file.name.replace(/[^a-zA-Z0-9.]/g, '_')}`;
      const storageRef = ref(storage, `photo-gallery/${galleryId}/${fileName}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      return new Promise((resolve, reject) => {
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress(progress);
          },
          (error) => {
            console.error('Upload error:', error);
            if (error.code === 'storage/unauthorized') {
              toast.error('Upload permission denied. Please check your permissions.');
            } else {
              toast.error('Failed to upload image. Please try again.');
            }
            reject(error);
          },
          async () => {
            try {
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              resolve(downloadURL);
            } catch (error) {
              console.error('Error getting download URL:', error);
              reject(error);
            }
          }
        );
      });
    } catch (error: any) {
      console.error('Error creating upload task:', error);
      toast.error(error.message || 'Failed to upload image');
      throw error;
    }
  };

  const handleFiles = async (files: FileList | null) => {
    if (!files) return;

    const validFiles = Array.from(files).filter(
      file => file.type.startsWith('image/')
    );

    if (validFiles.length === 0) {
      toast.error('Please select valid image files');
      return;
    }

    const newPhotos: CompressedPhoto[] = [];
    for (const file of validFiles) {
      try {
        const preview = URL.createObjectURL(file);
        newPhotos.push({
          file: file,
          originalFile: file,
          preview,
          title: file.name.split('.')[0],
          description: ''
        });
      } catch (error) {
        console.error('Error processing file:', error);
        toast.error(`Failed to process ${file.name}`);
      }
    }

    setPhotos(prev => [...prev, ...newPhotos]);
  };

  const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);
    handleFiles(e.dataTransfer.files);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleFiles(event.target.files);
  };

  const removePhoto = (index: number) => {
    setPhotos(prev => {
      const newPhotos = [...prev];
      if (newPhotos[index].preview) {
        URL.revokeObjectURL(newPhotos[index].preview!);
      }
      newPhotos.splice(index, 1);
      return newPhotos;
    });
  };

  // Helper function to delete photo from storage
  const deletePhotoFromStorage = async (fileName: string, galleryId: string) => {
    try {
      const photoRef = ref(storage, `photo-gallery/${galleryId}/${fileName}`);
      await deleteObject(photoRef);
      return true;
    } catch (error) {
      console.error('Error deleting from storage:', error);
      return false;
    }
  };

  // Helper function to update Firestore document
  const updateFirestorePhotos = async (photoUrl: string, galleryId: string) => {
    try {
      const galleryRef = doc(db, 'photo-gallery', galleryId);
      const galleryDoc = await getDoc(galleryRef);
      
      if (!galleryDoc.exists()) {
        throw new Error('Gallery not found');
      }

      const galleryData = galleryDoc.data();
      const updatedPhotos = (galleryData.photos || []).filter(
        (photo: ExistingPhoto) => photo.url !== photoUrl
      );

      await updateDoc(galleryRef, {
        photos: updatedPhotos,
        updatedAt: serverTimestamp()
      });
      return true;
    } catch (error) {
      console.error('Error updating Firestore:', error);
      throw error;
    }
  };

  const removeExistingPhoto = async (photoToRemove: ExistingPhoto) => {
    try {
      if (!id) {
        toast.error('Gallery ID is missing');
        return;
      }

      // Step 1: Delete from Storage if fileName exists
      if (photoToRemove.fileName) {
        await deletePhotoFromStorage(photoToRemove.fileName, id);
      }

      // Step 2: Update Firestore
      await updateFirestorePhotos(photoToRemove.url, id);

      // Step 3: Update local state
      setExistingPhotos(prev => prev.filter(photo => photo.url !== photoToRemove.url));
      toast.success('Photo deleted successfully');
    } catch (error) {
      console.error('Error removing photo:', error);
      toast.error('Failed to delete photo');
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!id) {
      toast.error('Gallery ID is required');
      return;
    }

    setIsSubmitting(true);
    try {
      const galleryRef = doc(db, 'photo-gallery', id);

      // Upload new photos
      const uploadedPhotos = await Promise.all(
        photos.map(async (photo) => {
          const downloadURL = await handleImageUpload(photo.file, id);
          return {
            url: downloadURL,
            title: photo.title || '',
            description: photo.description || '',
            fileName: photo.file.name,
            uploadedAt: new Date().toISOString(),
            size: photo.file.size,
            type: photo.file.type
          };
        })
      );

      // Update gallery document
      await updateDoc(galleryRef, {
        title: formData.title,
        description: formData.description,
        category: formData.category,
        isPublished: formData.isPublished,
        photos: [...existingPhotos, ...uploadedPhotos],
        updatedAt: serverTimestamp()
      });

      toast.success('Gallery updated successfully');
      navigate('/admin/photo-gallery');
    } catch (error) {
      console.error('Error updating gallery:', error);
      toast.error('Failed to update gallery');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-900 p-6">
      <div className="max-w-7xl mx-auto">
        {/* Header */}
        <div className="flex items-center justify-between mb-8">
          <div>
            <button
              onClick={() => navigate('/admin/photo-gallery')}
              className="flex items-center text-gray-400 hover:text-blue-500 transition-colors"
            >
              <FiArrowLeft className="w-5 h-5 mr-2" />
              <span>Back to Gallery List</span>
            </button>
            <h1 className="text-3xl font-bold text-white mt-4">Edit Photo Gallery</h1>
          </div>
        </div>

        <form onSubmit={handleSubmit} className="space-y-8">
          {/* Gallery Details Section */}
          <div className="bg-gray-800 rounded-xl p-6 shadow-lg">
            <div className="space-y-6">
              <div>
                <label htmlFor="title" className="block text-sm font-medium text-gray-300 mb-2">
                  Gallery Title
                </label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  value={formData.title}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-colors"
                  required
                />
              </div>

              <div>
                <label htmlFor="category" className="block text-sm font-medium text-gray-300 mb-2">
                  Category
                </label>
                <input
                  type="text"
                  id="category"
                  name="category"
                  value={formData.category}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-colors"
                  placeholder="Enter gallery category"
                />
              </div>

              <div>
                <label htmlFor="description" className="block text-sm font-medium text-gray-300 mb-2">
                  Description
                </label>
                <textarea
                  id="description"
                  name="description"
                  value={formData.description}
                  onChange={handleInputChange}
                  rows={4}
                  className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-colors resize-none"
                />
              </div>

              <div className="flex items-center space-x-3">
                <input
                  type="checkbox"
                  id="isPublished"
                  name="isPublished"
                  checked={formData.isPublished}
                  onChange={(e) => setFormData(prev => ({ ...prev, isPublished: e.target.checked }))}
                  className="w-4 h-4 text-blue-500 border-gray-600 rounded focus:ring-blue-500 focus:ring-offset-gray-800"
                />
                <label htmlFor="isPublished" className="text-sm font-medium text-gray-300">
                  Publish Gallery
                </label>
              </div>
            </div>
          </div>

          {/* Existing Photos Section */}
          {existingPhotos.length > 0 && (
            <div className="bg-gray-800 rounded-xl p-6 shadow-lg">
              <h2 className="text-xl font-semibold text-white mb-6">Current Photos</h2>
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                {existingPhotos.map((photo, index) => (
                  <div key={index} className="group relative">
                    <div className="aspect-w-16 aspect-h-9 rounded-lg overflow-hidden bg-gray-700">
                      <img
                        src={photo.url}
                        alt={photo.title}
                        className="w-full h-full object-cover"
                      />
                      <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-40 transition-all duration-300"></div>
                      <button
                        type="button"
                        onClick={() => removeExistingPhoto(photo)}
                        className="absolute top-2 right-2 p-1.5 bg-red-500 text-white rounded-full opacity-0 group-hover:opacity-100 transition-opacity hover:bg-red-600"
                      >
                        <FiX className="w-4 h-4" />
                      </button>
                    </div>
                    <p className="mt-2 text-sm text-gray-300 truncate">{photo.title}</p>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Upload New Photos Section */}
          <div className="bg-gray-800 rounded-xl p-6 shadow-lg">
            <h2 className="text-xl font-semibold text-white mb-6">Add New Photos</h2>
            <div
              className={`border-2 border-dashed rounded-lg p-8 ${
                isDragging
                  ? 'border-blue-500 bg-blue-500/10'
                  : 'border-gray-600 hover:border-gray-500'
              }`}
              onDragOver={(e) => {
                e.preventDefault();
                setIsDragging(true);
              }}
              onDragLeave={() => setIsDragging(false)}
              onDrop={handleDrop}
            >
              <input
                type="file"
                id="photo-input"
                multiple
                accept="image/*"
                onChange={handleFileChange}
                className="hidden"
              />
              <label
                htmlFor="photo-input"
                className="flex flex-col items-center cursor-pointer"
              >
                <FiUpload className="w-12 h-12 text-gray-400 mb-4" />
                <span className="text-base text-gray-300 text-center">
                  Drag and drop your photos here
                </span>
                <span className="text-sm text-gray-400 mt-2">
                  or click to browse from your computer
                </span>
                <span className="text-xs text-gray-500 mt-2">
                  Supported formats: JPG, PNG, GIF
                </span>
              </label>
            </div>

            {/* New Photos Preview */}
            {photos.length > 0 && (
              <div className="mt-8">
                <h3 className="text-lg font-medium text-white mb-4">New Photos to Add</h3>
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                  {photos.map((photo, index) => (
                    <div key={index} className="group relative">
                      <div className="aspect-w-16 aspect-h-9 rounded-lg overflow-hidden bg-gray-700">
                        <img
                          src={photo.preview}
                          alt={`Preview ${index + 1}`}
                          className="w-full h-full object-cover"
                        />
                        <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-40 transition-all duration-300"></div>
                        <button
                          type="button"
                          onClick={() => removePhoto(index)}
                          className="absolute top-2 right-2 p-1.5 bg-red-500 text-white rounded-full opacity-0 group-hover:opacity-100 transition-opacity hover:bg-red-600"
                        >
                          <FiX className="w-4 h-4" />
                        </button>
                      </div>
                      <p className="mt-2 text-sm text-gray-300 truncate">
                        {photo.title || photo.file.name}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>

          {/* Action Buttons */}
          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={() => navigate('/admin/photo-gallery')}
              className="px-6 py-2.5 border border-gray-600 rounded-lg text-gray-300 hover:bg-gray-700 transition-colors"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={isSubmitting}
              className={`px-6 py-2.5 rounded-lg text-white font-medium transition-colors ${
                isSubmitting
                  ? 'bg-blue-500/50 cursor-not-allowed'
                  : 'bg-blue-500 hover:bg-blue-600'
              }`}
            >
              {isSubmitting ? (
                <div className="flex items-center">
                  <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-white mr-2"></div>
                  Updating...
                </div>
              ) : (
                'Update Gallery'
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditPhotoGallery;
