import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FiPieChart, FiUsers, FiFileText, FiCalendar, FiImage, FiVideo, FiBell, FiSettings, FiChevronDown, FiLogOut } from 'react-icons/fi';
import { auth } from '../config/firebase';
import { signOut } from 'firebase/auth';
import logo from '../images/logo.png';
import toast from 'react-hot-toast';

interface MenuItem {
  label: string;
  path?: string;
  icon: React.ReactNode;
  submenu?: {
    label: string;
    path: string;
  }[];
}

interface AdminLayoutProps {
  children: React.ReactNode;
}

const AdminLayout: React.FC<AdminLayoutProps> = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [openMenus, setOpenMenus] = useState<string[]>([]);
  const location = useLocation();
  const navigate = useNavigate();

  const menuItems: MenuItem[] = [
    {
      label: 'Dashboard',
      path: '/admin/dashboard',
      icon: <FiPieChart size={20} />,
      submenu: [
        { label: 'Overview', path: '/admin/dashboard' }
      ]
    },
    {
      label: 'Users',
      icon: <FiUsers size={20} />,
      submenu: [
        { label: 'All Users', path: '/admin/users' },
        { label: 'Add User', path: '/admin/users/add' }
      ]
    },
    {
      label: 'Articles',
      icon: <FiFileText size={20} />,
      submenu: [
        { label: 'All Articles', path: '/admin/articles' },
        { label: 'Add Article', path: '/admin/articles/add' }
      ]
    },
    {
      label: 'Events',
      icon: <FiCalendar size={20} />,
      submenu: [
        { label: 'All Events', path: '/admin/events' },
        { label: 'Add Event', path: '/admin/events/add' }
        
      ]
    },
    {
      label: 'Photo Gallery',
      icon: <FiImage size={20} />,
      submenu: [
        { label: 'Photo Gallery', path: '/admin/photo-gallery' },
        { label: 'Add Photos', path: '/admin/photo-gallery/add' }
      ]
    },
    {
      label: 'Video Gallery',
      icon: <FiImage size={20} />,
      submenu: [
        { label: 'Video Gallery', path: '/admin/video-gallery' },
        { label: 'Add Videos', path: '/admin/video-gallery/add' }
      ]
    },
    {
      label: 'Notifications',
      icon: <FiBell size={20} />,
      submenu: [
        { label: 'All Notifications', path: '/admin/notifications' },
        { label: 'Create Notification', path: '/admin/notifications/create' }
      ]
    },
    {
      label: 'Settings',
      icon: <FiSettings size={20} />,
      submenu: [
        { label: 'General Setting', path: '/admin/settings' }
      ]
    }
  ];

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    if (!isSidebarOpen) {
      setOpenMenus([]);
    }
  };

  const toggleSubmenu = (label: string) => {
    setOpenMenus(prev =>
      prev.includes(label)
        ? prev.filter(item => item !== label)
        : [...prev, label]
    );
  };

  const isSubmenuOpen = (label: string) => openMenus.includes(label);
  const isActivePath = (path: string) => location.pathname === path;
  const isActiveSubmenu = (submenuItems: { path: string }[]) => 
    submenuItems.some(item => location.pathname === item.path);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      toast.success('Logged out successfully');
      navigate('/login/ccro');
    } catch (error) {
      console.error('Logout error:', error);
      toast.error('Failed to logout');
    }
  };

  return (
    <div className="flex min-h-screen bg-dark-primary">
      {/* Mobile Top Navigation */}
      <div className="sm:hidden fixed top-0 left-0 right-0 z-30 bg-dark-secondary border-b border-gray-700 shadow-lg">
        <div className="flex items-center justify-between p-4">
          <div className="flex items-center space-x-3">
            <img src={logo} alt="Logo" className="h-8 w-8" />
            <h1 className="text-xl font-bold text-white">Admin Panel</h1>
          </div>
          <button
            onClick={toggleSidebar}
            className="p-2 rounded-lg hover:bg-dark-hover/50 transition-all duration-300"
            aria-label="Toggle Menu"
          >
            <div className="w-6 h-5 relative transform transition-all duration-300">
              <span className={`absolute h-0.5 w-6 bg-white transform transition-all duration-300 ${
                isSidebarOpen ? 'rotate-45 top-2' : 'rotate-0 top-0'
              }`}></span>
              <span className={`absolute h-0.5 w-6 bg-white top-2 transition-all duration-300 ${
                isSidebarOpen ? 'opacity-0' : 'opacity-100'
              }`}></span>
              <span className={`absolute h-0.5 w-6 bg-white transform transition-all duration-300 ${
                isSidebarOpen ? '-rotate-45 top-2' : 'rotate-0 top-4'
              }`}></span>
            </div>
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      <div className={`sm:hidden fixed top-[73px] left-0 h-[calc(100vh-73px)] w-64 bg-dark-secondary transform transition-transform duration-300 ease-in-out z-20 ${
        isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
      }`}>
        <nav className="h-full overflow-y-auto flex flex-col justify-between">
          <div>
            {menuItems.map((item) => (
              <div key={item.label}>
                <button
                  onClick={() => toggleSubmenu(item.label)}
                  className={`w-full flex items-center justify-between p-4 hover:bg-dark-hover/50 transition-colors ${
                    isActiveSubmenu(item.submenu!) ? 'bg-dark-hover/50' : ''
                  }`}
                >
                  <div className="flex items-center gap-3">
                    {item.icon}
                    <span>{item.label}</span>
                  </div>
                  <FiChevronDown
                    className={`transform transition-transform duration-200 ${
                      isSubmenuOpen(item.label) ? 'rotate-180' : ''
                    }`}
                  />
                </button>
                <div className={`overflow-hidden transition-all duration-200 ${
                  isSubmenuOpen(item.label) ? 'max-h-screen' : 'max-h-0'
                }`}>
                  {item.submenu?.map((subItem) => (
                    <Link
                      key={subItem.path}
                      to={subItem.path}
                      onClick={() => {
                        setIsSidebarOpen(false);
                        setOpenMenus([]);
                      }}
                      className={`block px-6 py-3 hover:bg-dark-hover/30 transition-colors ${
                        isActivePath(subItem.path) ? 'text-blue-400' : 'text-white/80'
                      }`}
                    >
                      {subItem.label}
                    </Link>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <button
            onClick={handleLogout}
            className="flex items-center gap-3 p-4 text-red-400 hover:bg-red-500/10 transition-colors w-full"
          >
            <FiLogOut size={20} />
            <span>Logout</span>
          </button>
        </nav>
      </div>

      {/* Mobile Overlay */}
      {isSidebarOpen && (
        <div
          className="sm:hidden fixed inset-0 bg-black/50 z-10"
          onClick={toggleSidebar}
          aria-hidden="true"
        ></div>
      )}

      {/* Desktop Sidebar */}
      <div className={`sm:flex fixed top-0 left-0 h-full ${
        isSidebarOpen ? 'w-64' : 'w-20'
      } bg-dark-secondary text-white transition-all duration-300 ease-in-out z-20`}>
        <div className="w-full flex flex-col h-full">
          <div className="flex items-center justify-between p-4 border-b border-gray-700">
            {isSidebarOpen && <h1 className="text-xl font-bold">Admin Panel</h1>}
            <button
              onClick={toggleSidebar}
              className="p-2 rounded-lg hover:bg-dark-hover/50 transition-colors"
            >
              <div className="flex flex-col gap-1.5 w-6">
                <span className="block h-0.5 bg-white rounded-full"></span>
                <span className="block h-0.5 bg-white rounded-full"></span>
                <span className="block h-0.5 bg-white rounded-full"></span>
              </div>
            </button>
          </div>

          <nav className="mt-4 flex-1 flex flex-col justify-between">
            <div>
              {menuItems.map((item) => (
                <div key={item.label}>
                  <button
                    onClick={() => toggleSubmenu(item.label)}
                    className={`w-full flex items-center justify-between p-4 hover:bg-dark-hover transition-colors ${
                      isActiveSubmenu(item.submenu!) ? 'bg-dark-hover' : ''
                    }`}
                  >
                    <div className="flex items-center gap-3">
                      {item.icon}
                      {isSidebarOpen && <span>{item.label}</span>}
                    </div>
                    {isSidebarOpen && (
                      <FiChevronDown
                        className={`transform transition-transform duration-200 ${
                          isSubmenuOpen(item.label) ? 'rotate-180' : ''
                        }`}
                      />
                    )}
                  </button>
                  <div
                    className={`overflow-hidden transition-all duration-200 ${
                      isSubmenuOpen(item.label) && isSidebarOpen
                        ? 'max-h-screen opacity-100'
                        : 'max-h-0 opacity-0'
                    }`}
                  >
                    {item.submenu?.map((subItem) => (
                      <Link
                        key={subItem.path}
                        to={subItem.path}
                        className={`block px-4 py-2 hover:bg-dark-hover transition-colors ${
                          isActivePath(subItem.path) ? 'bg-dark-hover text-blue-400' : ''
                        }`}
                      >
                        {isSidebarOpen && subItem.label}
                      </Link>
                    ))}
                  </div>
                </div>
              ))}
            </div>
            <button
              onClick={handleLogout}
              className={`flex items-center gap-3 p-4 text-red-400 hover:bg-red-500/10 transition-colors w-full mb-4 ${
                isSidebarOpen ? 'justify-start' : 'justify-center'
              }`}
            >
              <FiLogOut size={20} />
              {isSidebarOpen && <span>Logout</span>}
            </button>
          </nav>
        </div>
      </div>

      {/* Main Content */}
      <div className={`flex-1 transition-all duration-300 ease-in-out ${
        isSidebarOpen ? 'sm:ml-64' : 'sm:ml-20'
      } ${window.innerWidth < 640 ? 'mt-[73px]' : ''}`}>
        <div className="p-6">
          {children}
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;
