import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FiVideo } from 'react-icons/fi';

interface Video {
  id: string;
  title: string;
  description: string;
  thumbnail: string;
  duration: string;
  createdAt: string;
  views: number;
}

const DeleteVideoGallery: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [video, setVideo] = useState<Video | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchVideo = async () => {
      try {
        // TODO: Replace with actual API call
        // Mock data for demonstration
        const mockVideo = {
          id: '1',
          title: 'Annual Conference Keynote',
          description: 'Opening keynote from our annual conference',
          thumbnail: 'https://example.com/thumbnail1.jpg',
          duration: '1:15:30',
          createdAt: '2024-01-15',
          views: 1250,
        };

        setVideo(mockVideo);
        setIsLoading(false);
      } catch (error) {
        setError('Failed to fetch video details');
        setIsLoading(false);
      }
    };

    if (id) {
      fetchVideo();
    }
  }, [id]);

  const handleDelete = async () => {
    try {
      setIsDeleting(true);
      // TODO: Implement actual API call to delete video
      console.log('Deleting video:', id);
      
      // Simulate API call
      await new Promise(resolve => setTimeout(resolve, 2000));
      
      navigate('/admin/video-gallery');
    } catch (error) {
      setError('Failed to delete video');
      setIsDeleting(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-full">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-white"></div>
      </div>
    );
  }

  if (error || !video) {
    return (
      <div className="p-6 max-w-2xl mx-auto bg-gray-800 rounded-lg shadow-lg">
        <div className="text-red-400 text-center">
          {error || 'Video not found'}
        </div>
        <div className="mt-4 flex justify-center">
          <button
            onClick={() => navigate('/admin/video-gallery')}
            className="px-4 py-2 text-sm font-medium text-white bg-gray-700 rounded-md hover:bg-gray-600"
          >
            Back to Videos
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="p-6 max-w-2xl mx-auto">
      <div className="bg-gray-800 rounded-lg shadow-lg overflow-hidden">
        <div className="p-6">
          <h2 className="text-2xl font-bold text-white mb-6">Delete Video</h2>
          
          <div className="bg-red-900/50 text-red-200 p-4 rounded-lg mb-6">
            <p className="text-lg mb-2">Are you sure you want to delete this video?</p>
            <p className="text-sm">This action cannot be undone. The video will be permanently removed from the gallery.</p>
          </div>

          <div className="bg-gray-700 rounded-lg overflow-hidden mb-6">
            <div className="aspect-video bg-gray-600">
              {video.thumbnail ? (
                <img
                  src={video.thumbnail}
                  alt={video.title}
                  className="w-full h-full object-cover"
                />
              ) : (
                <div className="flex items-center justify-center w-full h-full">
                  <FiVideo className="w-12 h-12 text-gray-500" />
                </div>
              )}
            </div>
            
            <div className="p-4">
              <h3 className="text-lg font-semibold text-white mb-2">{video.title}</h3>
              <p className="text-gray-300 text-sm mb-4">{video.description}</p>
              
              <div className="flex justify-between items-center text-sm text-gray-400">
                <div className="flex items-center space-x-4">
                  <span>{video.duration}</span>
                  <span>{video.views.toLocaleString()} views</span>
                </div>
                <span>Added on {new Date(video.createdAt).toLocaleDateString()}</span>
              </div>
            </div>
          </div>

          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={() => navigate('/admin/video-gallery')}
              className="px-6 py-2 text-sm font-medium text-gray-300 bg-gray-700 rounded-md hover:bg-gray-600"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={handleDelete}
              disabled={isDeleting}
              className="px-6 py-2 text-sm font-medium text-white bg-red-600 rounded-md hover:bg-red-700 disabled:bg-red-800 disabled:cursor-not-allowed"
            >
              {isDeleting ? (
                <span className="flex items-center">
                  <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white mr-2"></div>
                  Deleting...
                </span>
              ) : (
                'Delete Video'
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteVideoGallery;
