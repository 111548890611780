import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  getFirestore, 
  doc, 
  getDoc, 
  updateDoc, 
  serverTimestamp,
  Timestamp,
  deleteField
} from 'firebase/firestore';
import { 
  getAuth,
  updatePassword, 
  EmailAuthProvider, 
  reauthenticateWithCredential 
} from 'firebase/auth';
import { 
  getStorage, 
  ref, 
  uploadBytes, 
  getDownloadURL,
  deleteObject
} from 'firebase/storage';
import { toast } from 'react-hot-toast';
import { compressImage } from '../../utils/imageCompression';
import OfficialUseOnly from '../../components/OfficialUseOnly';
import { MemberType, MemberRank, MemberStatus } from '../../types/memberTypes';

// Types and Interfaces
interface UserData {
  fullName: string;
  email: string;
  phone: string;
  gender: string;
  dateOfBirth: string;
  aadhaarNumber: string;
  bloodGroup: string;
  fatherOrHusbandName: string;
  education: string;
  languagesKnown: string;
  identificationMark: string;
  profession: string;
  postHeld: string;
  currentAddressLine1: string;
  currentAddressLine2: string;
  currentState: string;
  currentDistrict: string;
  currentCityVillage: string;
  currentPinCode: string;
  currentStdCode: string;
  permanentAddressLine1: string;
  permanentAddressLine2: string;
  permanentState: string;
  permanentDistrict: string;
  permanentCityVillage: string;
  permanentPinCode: string;
  permanentStdCode: string;
  sameAsCurrent: boolean;
  vehicleMake: string;
  vehicleModel: string;
  vehicleRegistration: string;
  witness1Name: string;
  witness1Phone: string;
  witness1Aadhaar: string;
  witness2Name: string;
  witness2Phone: string;
  witness2Aadhaar: string;
  validFrom: string;
  validUntil: string;
  memberStatus: MemberStatus;
  memberType: MemberType;
  memberRank: MemberRank;
  officeState: string;
  officeDistrict: string;
  officeCityVillage: string;
  enrollmentNumber: string;
  range: string;
  role: string;
  userRole: string;
  adminVerified: boolean;
  emailVerified: boolean;
  profileUpdated: boolean;
  verificationStatus: string;
  validity: string;
  createdAt?: Date | Timestamp;
  createdBy?: string;
  updatedAt?: Date | Timestamp;
  lastUpdatedBy: string | null;
  history: any[];
  photo?: string;
  aadhaarFront?: string;
  aadhaarBack?: string;
  promissoryNote?: string;
  authUid?: string;
  designation: string;  // Add new field
  [key: string]: any;
}

interface SuccessModalProps {
  isOpen: boolean;
  onClose: () => void;
  message: string;
}

const SuccessModal: React.FC<SuccessModalProps> = ({ isOpen, onClose, message }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-sm w-full mx-4">
        <div className="flex flex-col items-center">
          <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mb-4">
            <svg className="w-8 h-8 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
            </svg>
          </div>
          <h3 className="text-lg font-semibold text-gray-900 mb-2">Success!</h3>
          <p className="text-gray-600 text-center mb-6">{message}</p>
          <button
            onClick={onClose}
            className="w-full px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition-colors duration-200"
          >
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

const EditUser: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const auth = getAuth();
  const db = getFirestore();
  const userId = id!; // Assert that id is not undefined since this is an edit page
  const [userData, setUserData] = useState<UserData>({
    fullName: '',
    email: '',
    phone: '',
    gender: '',
    dateOfBirth: '',
    aadhaarNumber: '',
    bloodGroup: '',
    fatherOrHusbandName: '',
    education: '',
    languagesKnown: '',
    identificationMark: '',
    profession: '',
    postHeld: '',
    currentAddressLine1: '',
    currentAddressLine2: '',
    currentState: '',
    currentDistrict: '',
    currentCityVillage: '',
    currentPinCode: '',
    currentStdCode: '',
    permanentAddressLine1: '',
    permanentAddressLine2: '',
    permanentState: '',
    permanentDistrict: '',
    permanentCityVillage: '',
    permanentPinCode: '',
    permanentStdCode: '',
    sameAsCurrent: false,
    vehicleMake: '',
    vehicleModel: '',
    vehicleRegistration: '',
    witness1Name: '',
    witness1Phone: '',
    witness1Aadhaar: '',
    witness2Name: '',
    witness2Phone: '',
    witness2Aadhaar: '',
    validFrom: '',
    validUntil: '',
    memberStatus: 'Active',
    memberType: 'Member',
    memberRank: '',
    officeState: '',
    officeDistrict: '',
    officeCityVillage: '',
    enrollmentNumber: '',
    range: '',
    role: '',
    userRole: '',
    adminVerified: false,
    emailVerified: false,
    profileUpdated: false,
    verificationStatus: '',
    validity: '',
    createdAt: undefined,
    createdBy: '',
    updatedAt: undefined,
    lastUpdatedBy: '',
    history: [],
    photo: '',
    aadhaarFront: '',
    aadhaarBack: '',
    promissoryNote: '',
    authUid: '',
    designation: '',  // Add new field
  });

  useEffect(() => {
    const fetchUserData = async () => {
      if (!id) return;

      try {
        const userRef = doc(db, 'userDetails', id!);
        const userSnap = await getDoc(userRef);

        if (userSnap.exists()) {
          const data = userSnap.data();
          console.log('=== Data Fetching Debug Logs ===');
          console.log('1. Raw Firestore Data:', data);
          
          // Personal Information
          console.log('2. Personal Information:', {
            fullName: data.fullName,
            email: data.email,
            phone: data.phone,
            gender: data.gender,
            dateOfBirth: data.dateOfBirth,
            aadhaarNumber: data.aadhaarNumber,
            bloodGroup: data.bloodGroup,
            fatherOrHusbandName: data.fatherOrHusbandName
          });

          // Education and Professional Details
          console.log('3. Education and Professional:', {
            education: data.education,
            languagesKnown: data.languagesKnown,
            identificationMark: data.identificationMark,
            profession: data.profession,
            postHeld: data.postHeld
          });

          // Address Information
          console.log('4. Current Address:', {
            currentAddressLine1: data.currentAddressLine1,
            currentAddressLine2: data.currentAddressLine2,
            currentState: data.currentState,
            currentDistrict: data.currentDistrict,
            currentCityVillage: data.currentCityVillage,
            currentPinCode: data.currentPinCode,
            currentStdCode: data.currentStdCode
          });

          console.log('5. Permanent Address:', {
            permanentAddressLine1: data.permanentAddressLine1,
            permanentAddressLine2: data.permanentAddressLine2,
            permanentState: data.permanentState,
            permanentDistrict: data.permanentDistrict,
            permanentCityVillage: data.permanentCityVillage,
            permanentPinCode: data.permanentPinCode,
            permanentStdCode: data.permanentStdCode,
            sameAsCurrent: data.sameAsCurrent
          });

          // Vehicle Information
          console.log('6. Vehicle Information:', {
            vehicleMake: data.vehicleMake,
            vehicleModel: data.vehicleModel,
            vehicleRegistration: data.vehicleRegistration
          });

          // Witness Information
          console.log('7. Witness Information:', {
            witness1Name: data.witness1Name,
            witness1Phone: data.witness1Phone,
            witness1Aadhaar: data.witness1Aadhaar,
            witness2Name: data.witness2Name,
            witness2Phone: data.witness2Phone,
            witness2Aadhaar: data.witness2Aadhaar
          });

          // Membership Details
          console.log('8. Membership Details:', {
            validFrom: data.validFrom,
            validUntil: data.validUntil,
            memberStatus: data.memberStatus
          });

          const formatDate = (date: any) => {
            if (!date) return '';
            if (date instanceof Date) {
              return date.toISOString().split('T')[0];
            }
            // Handle Firestore Timestamp
            if (date && typeof date.toDate === 'function') {
              return date.toDate().toISOString().split('T')[0];
            }
            // Handle string dates
            if (typeof date === 'string') {
              // If it's already in YYYY-MM-DD format, return as is
              if (date.match(/^\d{4}-\d{2}-\d{2}$/)) {
                return date;
              }
              // Try to parse the date string
              const parsedDate = new Date(date);
              if (!isNaN(parsedDate.getTime())) {
                return parsedDate.toISOString().split('T')[0];
              }
            }
            return '';
          };

          const formattedData: UserData = {
            fullName: data.fullName || '',
            email: data.email || '',
            phone: data.phone || '',
            gender: data.gender || '',
            dateOfBirth: formatDate(data.dateOfBirth),
            aadhaarNumber: data.aadhaarNumber || '',
            bloodGroup: data.bloodGroup || '',
            fatherOrHusbandName: data.fatherOrHusbandName || '',
            education: data.education || '',
            languagesKnown: data.languagesKnown || '',
            identificationMark: data.identificationMark || '',
            profession: data.profession || '',
            postHeld: data.postHeld || '',
            currentAddressLine1: data.currentAddressLine1 || '',
            currentAddressLine2: data.currentAddressLine2 || '',
            currentState: data.currentState || '',
            currentDistrict: data.currentDistrict || '',
            currentCityVillage: data.currentCityVillage || '',
            currentPinCode: data.currentPinCode || '',
            currentStdCode: data.currentStdCode || '',
            permanentAddressLine1: data.permanentAddressLine1 || '',
            permanentAddressLine2: data.permanentAddressLine2 || '',
            permanentState: data.permanentState || '',
            permanentDistrict: data.permanentDistrict || '',
            permanentCityVillage: data.permanentCityVillage || '',
            permanentPinCode: data.permanentPinCode || '',
            permanentStdCode: data.permanentStdCode || '',
            sameAsCurrent: data.sameAsCurrent || false,
            vehicleMake: data.vehicleMake || '',
            vehicleModel: data.vehicleModel || '',
            vehicleRegistration: data.vehicleRegistration || '',
            witness1Name: data.witness1Name || '',
            witness1Phone: data.witness1Phone || '',
            witness1Aadhaar: data.witness1Aadhaar || '',
            witness2Name: data.witness2Name || '',
            witness2Phone: data.witness2Phone || '',
            witness2Aadhaar: data.witness2Aadhaar || '',
            validFrom: formatDate(data.validFrom),
            validUntil: formatDate(data.validUntil),
            memberStatus: data.memberStatus || 'Active',
            memberType: data.memberType || 'Member',
            memberRank: data.memberRank || '',
            officeState: data.officeState || '',
            officeDistrict: data.officeDistrict || '',
            officeCityVillage: data.officeCityVillage || '',
            enrollmentNumber: data.enrollmentNumber || '',
            range: data.range || '',
            role: data.role || '',
            userRole: data.userRole || '',
            adminVerified: data.adminVerified || false,
            emailVerified: data.emailVerified || false,
            profileUpdated: data.profileUpdated || false,
            verificationStatus: data.verificationStatus || '',
            validity: data.validity || '',
            createdAt: data.createdAt ? 
              (data.createdAt.toDate ? data.createdAt.toDate() : new Date(data.createdAt)) 
              : undefined,
            createdBy: data.createdBy || '',
            updatedAt: data.updatedAt ? 
              (data.updatedAt.toDate ? data.updatedAt.toDate() : new Date(data.updatedAt)) 
              : undefined,
            lastUpdatedBy: data.lastUpdatedBy || null,
            history: data.history || [],
            photo: data.photo || '',
            aadhaarFront: data.aadhaarFront || '',
            aadhaarBack: data.aadhaarBack || '',
            promissoryNote: data.promissoryNote || '',
            authUid: data.authUid || '',
            designation: data.designation || '',  // Add new field
          };

          console.log('=== Formatted Data Debug Logs ===');
          console.log('9. Formatted Personal Info:', {
            fullName: formattedData.fullName,
            email: formattedData.email,
            phone: formattedData.phone,
            gender: formattedData.gender,
            dateOfBirth: formattedData.dateOfBirth,
            aadhaarNumber: formattedData.aadhaarNumber,
            bloodGroup: formattedData.bloodGroup,
            fatherOrHusbandName: formattedData.fatherOrHusbandName
          });

          console.log('10. Formatted Education:', {
            education: formattedData.education,
            languagesKnown: formattedData.languagesKnown,
            identificationMark: formattedData.identificationMark,
            profession: formattedData.profession,
            postHeld: formattedData.postHeld
          });

          console.log('11. Formatted Addresses:', {
            current: {
              line1: formattedData.currentAddressLine1,
              line2: formattedData.currentAddressLine2,
              state: formattedData.currentState,
              district: formattedData.currentDistrict,
              cityVillage: formattedData.currentCityVillage,
              pincode: formattedData.currentPinCode,
              stdCode: formattedData.currentStdCode
            },
            permanent: {
              line1: formattedData.permanentAddressLine1,
              line2: formattedData.permanentAddressLine2,
              state: formattedData.permanentState,
              district: formattedData.permanentDistrict,
              cityVillage: formattedData.permanentCityVillage,
              pincode: formattedData.permanentPinCode,
              stdCode: formattedData.permanentStdCode
            },
            sameAsCurrent: formattedData.sameAsCurrent
          });

          console.log('12. Formatted Vehicle Info:', {
            make: formattedData.vehicleMake,
            model: formattedData.vehicleModel,
            regNumber: formattedData.vehicleRegistration
          });

          console.log('13. Formatted Witness Info:', {
            witness1: {
              name: formattedData.witness1Name,
              phone: formattedData.witness1Phone,
              aadhaar: formattedData.witness1Aadhaar
            },
            witness2: {
              name: formattedData.witness2Name,
              phone: formattedData.witness2Phone,
              aadhaar: formattedData.witness2Aadhaar
            }
          });

          console.log('14. Formatted Membership:', {
            validFrom: formattedData.validFrom,
            validUntil: formattedData.validUntil,
            memberStatus: formattedData.memberStatus
          });

          console.log('15. Official Use Only Fields:', {
            memberType: formattedData.memberType,
            memberRank: formattedData.memberRank,
            officeState: formattedData.officeState,
            officeDistrict: formattedData.officeDistrict,
            officeCityVillage: formattedData.officeCityVillage,
            enrollmentNumber: formattedData.enrollmentNumber,
            range: formattedData.range,
            role: formattedData.role,
            userRole: formattedData.userRole,
            adminVerified: formattedData.adminVerified,
            emailVerified: formattedData.emailVerified,
            profileUpdated: formattedData.profileUpdated,
            verificationStatus: formattedData.verificationStatus,
            validity: formattedData.validity,
            designation: formattedData.designation,  // Add new field
          });

          console.log('Formatted data:', formattedData);
          setUserData(formattedData);
          setLoading(false);
        } else {
          setError('User not found');
          toast.error('User not found');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        setError('Failed to fetch user data');
        toast.error('Failed to fetch user data');
        setLoading(false);
      }
    };

    fetchUserData();
  }, [id]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    // Handle nested objects
    if (name.includes('.')) {
      const [parent, child] = name.split('.');
      setUserData(prev => ({
        ...prev,
        [parent]: {
          ...prev[parent as keyof UserData],
          [child]: value
        }
      }));
    } else {
      setUserData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSaving(true);

    try {
      // Get the validFrom date, default to today if not set
      const startDate = userData.validFrom ? new Date(userData.validFrom) : new Date();
      
      // Calculate validUntil as one year from validFrom if not set
      let endDate: Date;
      if (userData.validUntil) {
        endDate = new Date(userData.validUntil);
        // Validate that end date is after start date
        if (endDate <= startDate) {
          toast.error('Validity end date must be after start date');
          setIsSaving(false);
          return;
        }
      } else {
        // Default to one year from start date
        endDate = new Date(startDate);
        endDate.setFullYear(endDate.getFullYear() + 1);
      }

      const hasValueChanged = (oldValue: any, newValue: any): boolean => {
        // Handle undefined/null cases
        if (!oldValue && !newValue) return false;
        if (!oldValue || !newValue) return true;

        // Handle Timestamp comparisons
        if (oldValue?.toDate && typeof oldValue.toDate === 'function') {
          const oldDate = oldValue.toDate();
          const newDate = newValue instanceof Date ? newValue : new Date(newValue);
          return oldDate.getTime() !== newDate.getTime();
        }

        // Handle Date comparisons
        if (oldValue instanceof Date || newValue instanceof Date) {
          const oldDate = oldValue instanceof Date ? oldValue : new Date(oldValue);
          const newDate = newValue instanceof Date ? newValue : new Date(newValue);
          return oldDate.getTime() !== newDate.getTime();
        }

        // Handle object comparisons
        if (typeof oldValue === 'object' && typeof newValue === 'object') {
          return JSON.stringify(oldValue) !== JSON.stringify(newValue);
        }

        // Handle primitive value comparisons
        return oldValue !== newValue;
      };

      const updatedUserData: UserData = {
        ...userData,
        validFrom: startDate.toISOString().split('T')[0],
        validUntil: endDate.toISOString().split('T')[0],
        updatedAt: Timestamp.fromDate(new Date()),
      };

      const userRef = doc(db, 'userDetails', id!);
      const userSnap = await getDoc(userRef);
      
      if (!userSnap.exists()) {
        toast.error('User not found');
        setIsSaving(false);
        return;
      }

      const currentData = userSnap.data();
      
      // Ensure authUid is preserved
      if (currentData.authUid) {
        updatedUserData.authUid = currentData.authUid;
      }

      const updates: Record<string, any> = {
        updatedAt: Timestamp.fromDate(new Date()),
        lastUpdatedBy: auth.currentUser?.email || null,
        authUid: currentData.authUid // Preserve authUid in updates
      };

      // Compare each field and track changes
      (Object.keys(updatedUserData) as Array<keyof UserData>).forEach((key) => {
        if (hasValueChanged(currentData[key], updatedUserData[key])) {
          updates[key] = updatedUserData[key];
        }
      });

      // If there are changes, update the document
      if (Object.keys(updates).length > 0) {
        // Update the document
        await updateDoc(userRef, updates);

        toast.success('User updated successfully');
        navigate('/admin/users');
      } else {
        toast('No changes to save', {
          style: {
            background: '#3498db',
            color: '#fff'
          }
        });
      }
    } catch (error) {
      console.error('Error updating user:', error);
      toast.error('Error updating user');
    } finally {
      setIsSaving(false);
    }
  };

  const handleSuccessModalClose = () => {
    setShowSuccessModal(false);
    navigate('/admin/users');  // Updated path to match UsersList route
  };

  // Style constants for consistent UI
  const inputClassName = `
    w-full px-4 py-3 
    bg-slate-800/40 
    border border-slate-700/50 
    rounded-lg 
    focus:ring-2 focus:ring-emerald-500/50 focus:border-emerald-500/50 
    transition-all duration-200
    placeholder:text-slate-500
    text-slate-300
  `;

  const labelClassName = `
    block text-sm font-medium 
    text-slate-400 
    mb-1.5
  `;

  const sectionClassName = `
    bg-slate-900/50 
    backdrop-blur-sm 
    rounded-xl 
    border border-slate-800/50 
    p-6 md:p-8 
    space-y-6
    shadow-lg 
    shadow-slate-900/20
    transition-all duration-300 
    hover:shadow-slate-900/30
  `;

  const sectionHeaderClassName = `
    text-xl font-semibold 
    text-emerald-400
    mb-6 
    flex items-center gap-2
    border-b border-slate-800/50 
    pb-4
  `;

  const gridClassName = `
    grid grid-cols-1 
    md:grid-cols-2 
    lg:grid-cols-4 
    gap-6
  `;

  const bloodGroupOptions = ['A+', 'A-', 'B+', 'B-', 'AB+', 'AB-', 'O+', 'O-'];
  const genderOptions = ['male', 'female', 'other'];

  const handleSameAsCurrentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    setUserData(prev => ({
      ...prev,
      sameAsCurrent: checked,
      permanentAddressLine1: checked ? prev.currentAddressLine1 : '',
      permanentAddressLine2: checked ? prev.currentAddressLine2 : '',
      permanentState: checked ? prev.currentState : '',
      permanentDistrict: checked ? prev.currentDistrict : '',
      permanentCityVillage: checked ? prev.currentCityVillage : '',
      permanentPinCode: checked ? prev.currentPinCode : '',
      permanentStdCode: checked ? prev.currentStdCode : ''
    }));
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-slate-900 via-slate-900 to-slate-800 text-slate-300">
      {/* Success Modal */}
      <SuccessModal
        isOpen={showSuccessModal}
        onClose={handleSuccessModalClose}
        message="User data has been updated successfully!"
      />

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="mb-8 flex justify-between items-center">
          <h1 className="text-3xl font-bold text-white">Edit User</h1>
        </div>

        <form onSubmit={handleSubmit} className="space-y-8">
          {/* Personal Details */}
          <div className="bg-slate-800/50 rounded-xl p-6 shadow-lg">
            <h2 className="text-xl font-semibold mb-6 text-white">
              Personal Details
            </h2>
            <div className={gridClassName}>
              {/* Full Name */}
              <div>
                <label className={labelClassName}>
                  Full Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="fullName"
                  value={userData.fullName}
                  onChange={handleInputChange}
                  className={inputClassName}
                  required
                />
              </div>

              {/* Email */}
              <div>
                <label className={labelClassName}>
                  Email <span className="text-red-500">*</span>
                </label>
                <input
                  type="email"
                  name="email"
                  value={userData.email}
                  onChange={handleInputChange}
                  className={inputClassName}
                  required
                />
              </div>

              {/* Phone Number */}
              <div>
                <label className={labelClassName}>
                  Phone Number <span className="text-red-500">*</span>
                </label>
                <input
                  type="tel"
                  name="phone"
                  value={userData.phone}
                  onChange={handleInputChange}
                  className={inputClassName}
                  pattern="[0-9]{10}"
                  title="Please enter a valid 10-digit phone number"
                  required
                />
              </div>

              {/* Gender */}
              <div>
                <label className={labelClassName}>
                  Gender <span className="text-red-500">*</span>
                </label>
                <select
                  name="gender"
                  value={userData.gender}
                  onChange={handleInputChange}
                  className={inputClassName}
                  required
                >
                  <option value="">Select Gender</option>
                  {genderOptions.map((option) => (
                    <option key={option} value={option}>
                      {option.charAt(0).toUpperCase() + option.slice(1)}
                    </option>
                  ))}
                </select>
              </div>

              {/* Date of Birth */}
              <div>
                <label className={labelClassName}>
                  Date of Birth <span className="text-red-500">*</span>
                </label>
                <input
                  type="date"
                  name="dateOfBirth"
                  value={userData.dateOfBirth}
                  onChange={handleInputChange}
                  className={inputClassName}
                  required
                  max={new Date().toISOString().split('T')[0]}
                />
              </div>

              {/* Aadhaar Number */}
              <div>
                <label className={labelClassName}>
                  Aadhaar Number <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="aadhaarNumber"
                  value={userData.aadhaarNumber}
                  onChange={handleInputChange}
                  className={inputClassName}
                  pattern="[0-9]{12}"
                  title="Please enter a valid 12-digit Aadhaar number"
                  required
                  maxLength={12}
                />
              </div>

              {/* Blood Group */}
              <div>
                <label className={labelClassName}>
                  Blood Group <span className="text-red-500">*</span>
                </label>
                <select
                  name="bloodGroup"
                  value={userData.bloodGroup}
                  onChange={handleInputChange}
                  className={inputClassName}
                  required
                >
                  <option value="">Select Blood Group</option>
                  {bloodGroupOptions.map((option) => (
                    <option key={option} value={option}>{option}</option>
                  ))}
                </select>
              </div>

              {/* Father/Husband Name */}
              <div>
                <label className={labelClassName}>
                  Father/Husband Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="fatherOrHusbandName"
                  value={userData.fatherOrHusbandName}
                  onChange={handleInputChange}
                  className={inputClassName}
                  required
                />
              </div>

              {/* Education */}
              <div>
                <label className={labelClassName}>
                  Education <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="education"
                  value={userData.education}
                  onChange={handleInputChange}
                  className={inputClassName}
                  required
                />
              </div>

              {/* Languages Known */}
              <div>
                <label className={labelClassName}>
                  Languages Known <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="languagesKnown"
                  value={userData.languagesKnown}
                  onChange={handleInputChange}
                  className={inputClassName}
                  required
                  placeholder="e.g., English, Hindi"
                />
              </div>

              {/* Identification Mark */}
              <div>
                <label className={labelClassName}>
                  Identification Mark <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="identificationMark"
                  value={userData.identificationMark}
                  onChange={handleInputChange}
                  className={inputClassName}
                  required
                />
              </div>

              {/* Profession */}
              <div>
                <label className={labelClassName}>
                  Profession <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="profession"
                  value={userData.profession}
                  onChange={handleInputChange}
                  className={inputClassName}
                  required
                />
              </div>

              {/* Post Held */}
              <div>
                <label className={labelClassName}>
                  Post Held <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="postHeld"
                  value={userData.postHeld}
                  onChange={handleInputChange}
                  className={inputClassName}
                  required
                />
              </div>
            </div>
          </div>

          {/* Current Address */}
          <div className="bg-slate-800/50 rounded-xl p-6 shadow-lg">
            <h2 className="text-xl font-semibold mb-6 text-white">
              Current Address
            </h2>
            <div className={gridClassName}>
              {/* Address Line 1 */}
              <div className="lg:col-span-2">
                <label className={labelClassName}>
                  Address Line 1 <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="currentAddressLine1"
                  value={userData.currentAddressLine1}
                  onChange={handleInputChange}
                  className={inputClassName}
                  required
                />
              </div>

              {/* Address Line 2 */}
              <div className="lg:col-span-2">
                <label className={labelClassName}>
                  Address Line 2
                </label>
                <input
                  type="text"
                  name="currentAddressLine2"
                  value={userData.currentAddressLine2}
                  onChange={handleInputChange}
                  className={inputClassName}
                />
              </div>

              {/* State */}
              <div>
                <label className={labelClassName}>
                  State <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="currentState"
                  value={userData.currentState}
                  onChange={handleInputChange}
                  className={inputClassName}
                  required
                />
              </div>

              {/* District */}
              <div>
                <label className={labelClassName}>
                  District <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="currentDistrict"
                  value={userData.currentDistrict}
                  onChange={handleInputChange}
                  className={inputClassName}
                  required
                />
              </div>

              {/* City/Village */}
              <div>
                <label className={labelClassName}>
                  City/Village <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="currentCityVillage"
                  value={userData.currentCityVillage}
                  onChange={handleInputChange}
                  className={inputClassName}
                  required
                />
              </div>

              {/* Pin Code */}
              <div>
                <label className={labelClassName}>
                  Pin Code <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="currentPinCode"
                  value={userData.currentPinCode}
                  onChange={handleInputChange}
                  className={inputClassName}
                  pattern="[0-9]{6}"
                  maxLength={6}
                  title="Please enter a valid 6-digit pin code"
                  required
                />
              </div>

              {/* STD Code */}
              <div>
                <label className={labelClassName}>
                  STD Code <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="currentStdCode"
                  value={userData.currentStdCode}
                  onChange={handleInputChange}
                  className={inputClassName}
                  required
                />
              </div>
            </div>
          </div>

          {/* Permanent Address */}
          <div className="bg-slate-800/50 rounded-xl p-6 shadow-lg">
            <h2 className="text-xl font-semibold mb-6 text-white">
              Permanent Address
            </h2>

            {/* Same as Current Address Checkbox */}
            <div className="mb-4">
              <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  name="sameAsCurrent"
                  checked={userData.sameAsCurrent}
                  onChange={handleSameAsCurrentChange}
                  className="form-checkbox h-5 w-5 text-blue-600"
                />
                <span className="ml-2 text-slate-300">Same as Current Address</span>
              </label>
            </div>
            
            <div className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 ${userData.sameAsCurrent ? 'opacity-50' : ''}`}>
              {/* Address Line 1 */}
              <div className="lg:col-span-2">
                <label className={labelClassName}>
                  Address Line 1 <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="permanentAddressLine1"
                  value={userData.permanentAddressLine1}
                  onChange={handleInputChange}
                  className={inputClassName}
                  required
                  disabled={userData.sameAsCurrent}
                />
              </div>

              {/* Address Line 2 */}
              <div className="lg:col-span-2">
                <label className={labelClassName}>
                  Address Line 2
                </label>
                <input
                  type="text"
                  name="permanentAddressLine2"
                  value={userData.permanentAddressLine2}
                  onChange={handleInputChange}
                  className={inputClassName}
                  disabled={userData.sameAsCurrent}
                />
              </div>

              {/* State */}
              <div>
                <label className={labelClassName}>
                  State <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="permanentState"
                  value={userData.permanentState}
                  onChange={handleInputChange}
                  className={inputClassName}
                  required
                  disabled={userData.sameAsCurrent}
                />
              </div>

              {/* District */}
              <div>
                <label className={labelClassName}>
                  District <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="permanentDistrict"
                  value={userData.permanentDistrict}
                  onChange={handleInputChange}
                  className={inputClassName}
                  required
                  disabled={userData.sameAsCurrent}
                />
              </div>

              {/* City/Village */}
              <div>
                <label className={labelClassName}>
                  City/Village <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="permanentCityVillage"
                  value={userData.permanentCityVillage}
                  onChange={handleInputChange}
                  className={inputClassName}
                  required
                  disabled={userData.sameAsCurrent}
                />
              </div>

              {/* Pin Code */}
              <div>
                <label className={labelClassName}>
                  Pin Code <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="permanentPinCode"
                  value={userData.permanentPinCode}
                  onChange={handleInputChange}
                  className={inputClassName}
                  pattern="[0-9]{6}"
                  maxLength={6}
                  title="Please enter a valid 6-digit pin code"
                  required
                  disabled={userData.sameAsCurrent}
                />
              </div>

              {/* STD Code */}
              <div>
                <label className={labelClassName}>
                  STD Code <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="permanentStdCode"
                  value={userData.permanentStdCode}
                  onChange={handleInputChange}
                  className={inputClassName}
                  required
                  disabled={userData.sameAsCurrent}
                />
              </div>
            </div>
          </div>

          {/* Membership Validity */}
          <div className={sectionClassName}>
            <h2 className={sectionHeaderClassName}>
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
              </svg>
              Membership Validity
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className={labelClassName}>Valid From</label>
                <input
                  type="date"
                  name="validFrom"
                  value={userData.validFrom}
                  onChange={handleInputChange}
                  className={inputClassName}
                />
                <p className="mt-1 text-sm text-gray-400">
                  If not specified, today's date will be used
                </p>
              </div>
              <div>
                <label className={labelClassName}>Valid Until</label>
                <input
                  type="date"
                  name="validUntil"
                  value={userData.validUntil}
                  onChange={handleInputChange}
                  className={inputClassName}
                />
                <p className="mt-1 text-sm text-gray-400">
                  If not specified, validity will be set to 1 year from start date
                </p>
              </div>
            </div>
          </div>

          {/* Vehicle Details */}
          <div className="bg-slate-800/50 rounded-xl p-6 shadow-lg">
            <h2 className="text-xl font-semibold mb-6 text-white">
              Vehicle Details
            </h2>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {/* Make */}
              <div>
                <label className={labelClassName}>
                  Make
                </label>
                <input
                  type="text"
                  name="vehicleMake"
                  value={userData.vehicleMake}
                  onChange={handleInputChange}
                  className={inputClassName}
                  placeholder="e.g., Toyota, Honda, etc."
                />
              </div>

              {/* Model */}
              <div>
                <label className={labelClassName}>
                  Model
                </label>
                <input
                  type="text"
                  name="vehicleModel"
                  value={userData.vehicleModel}
                  onChange={handleInputChange}
                  className={inputClassName}
                  placeholder="e.g., Camry, Civic, etc."
                />
              </div>

              {/* Registration Number */}
              <div>
                <label className={labelClassName}>
                  Registration Number
                </label>
                <input
                  type="text"
                  name="vehicleRegistration"
                  value={userData.vehicleRegistration}
                  onChange={handleInputChange}
                  className={inputClassName}
                  placeholder="e.g., KA-01-AB-1234"
                />
              </div>
            </div>
          </div>

          {/* Witness Details */}
          <div className="bg-slate-800/50 rounded-xl p-6 shadow-lg">
            <h2 className="text-xl font-semibold mb-6 text-white">
              Witness Details
            </h2>
            <div className="space-y-6">
              {/* Witness 1 */}
              <div>
                <h3 className="text-lg font-medium mb-4">Witness 1</h3>
                <div className={gridClassName}>
                  {/* Full Name */}
                  <div>
                    <label className={labelClassName}>
                      Full Name <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="witness1Name"
                      value={userData.witness1Name}
                      onChange={handleInputChange}
                      required
                      className={inputClassName}
                      placeholder="Enter witness name"
                    />
                  </div>

                  {/* Phone Number */}
                  <div>
                    <label className={labelClassName}>
                      Phone Number <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="tel"
                      name="witness1Phone"
                      value={userData.witness1Phone}
                      onChange={(e) => {
                        const value = e.target.value.replace(/\D/g, '').slice(0, 10);
                        setUserData(prev => ({
                          ...prev,
                          witness1Phone: value
                        }));
                      }}
                      pattern="[0-9]{10}"
                      required
                      className={inputClassName}
                      placeholder="Enter 10-digit phone number"
                    />
                    {userData.witness1Phone && userData.witness1Phone.length !== 10 && (
                      <p className="mt-1 text-sm text-red-500">
                        Phone number must be exactly 10 digits
                      </p>
                    )}
                  </div>

                  {/* Aadhaar Number */}
                  <div>
                    <label className={labelClassName}>
                      Aadhaar Number <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="witness1Aadhaar"
                      value={userData.witness1Aadhaar}
                      onChange={(e) => {
                        const value = e.target.value.replace(/\D/g, '').slice(0, 12);
                        setUserData(prev => ({
                          ...prev,
                          witness1Aadhaar: value
                        }));
                      }}
                      pattern="[0-9]{12}"
                      required
                      className={inputClassName}
                      placeholder="Enter 12-digit Aadhaar number"
                    />
                    {userData.witness1Aadhaar && userData.witness1Aadhaar.length !== 12 && (
                      <p className="mt-1 text-sm text-red-500">
                        Aadhaar number must be exactly 12 digits
                      </p>
                    )}
                  </div>
                </div>
              </div>

              {/* Witness 2 */}
              <div>
                <h3 className="text-lg font-medium mb-4">Witness 2</h3>
                <div className={gridClassName}>
                  {/* Full Name */}
                  <div>
                    <label className={labelClassName}>
                      Full Name <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="witness2Name"
                      value={userData.witness2Name}
                      onChange={handleInputChange}
                      required
                      className={inputClassName}
                      placeholder="Enter witness name"
                    />
                  </div>

                  {/* Phone Number */}
                  <div>
                    <label className={labelClassName}>
                      Phone Number <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="tel"
                      name="witness2Phone"
                      value={userData.witness2Phone}
                      onChange={(e) => {
                        const value = e.target.value.replace(/\D/g, '').slice(0, 10);
                        setUserData(prev => ({
                          ...prev,
                          witness2Phone: value
                        }));
                      }}
                      pattern="[0-9]{10}"
                      required
                      className={inputClassName}
                      placeholder="Enter 10-digit phone number"
                    />
                    {userData.witness2Phone && userData.witness2Phone.length !== 10 && (
                      <p className="mt-1 text-sm text-red-500">
                        Phone number must be exactly 10 digits
                      </p>
                    )}
                  </div>

                  {/* Aadhaar Number */}
                  <div>
                    <label className={labelClassName}>
                      Aadhaar Number <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="witness2Aadhaar"
                      value={userData.witness2Aadhaar}
                      onChange={(e) => {
                        const value = e.target.value.replace(/\D/g, '').slice(0, 12);
                        setUserData(prev => ({
                          ...prev,
                          witness2Aadhaar: value
                        }));
                      }}
                      pattern="[0-9]{12}"
                      required
                      className={inputClassName}
                      placeholder="Enter 12-digit Aadhaar number"
                    />
                    {userData.witness2Aadhaar && userData.witness2Aadhaar.length !== 12 && (
                      <p className="mt-1 text-sm text-red-500">
                        Aadhaar number must be exactly 12 digits
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Official Use Only */}
          <OfficialUseOnly
            userData={{
              memberType: userData.memberType,
              memberStatus: userData.memberStatus,
              memberRank: userData.memberRank,
              officeState: userData.officeState,
              officeDistrict: userData.officeDistrict,
              officeCityVillage: userData.officeCityVillage,
              enrollmentNumber: userData.enrollmentNumber,
              range: userData.range,
              validFrom: userData.validFrom,
              validUntil: userData.validUntil,
              designation: userData.designation,  // Add new field
            }}
            onInputChange={handleInputChange}
            onEnrollmentNumberGenerated={(enrollmentNumber: string) => 
              setUserData(prev => ({ ...prev, enrollmentNumber }))
            }
          />

          <div className="flex justify-end space-x-4 mt-8">
            <button
              type="button"
              onClick={() => navigate('/admin/users')}
              className="px-6 py-2.5 rounded-lg font-medium border border-gray-300 text-gray-300 hover:bg-gray-300 hover:text-gray-900 transition-all duration-200"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={isSaving}
              className="px-6 py-2.5 rounded-lg font-medium bg-blue-500 text-white hover:bg-blue-600 transition-all duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {isSaving ? 'Saving...' : 'Save Changes'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditUser;
