import React, { useState, useEffect } from 'react';
import { collection, query, getDocs, deleteDoc, doc, where, Timestamp } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { Event } from '../../types/event';
import { useNavigate } from 'react-router-dom';
import { FiGrid, FiList, FiPlus, FiEdit2, FiEye, FiTrash2, FiLoader, FiCalendar, FiMapPin, FiUsers } from 'react-icons/fi';
import { format } from 'date-fns';
import toast from 'react-hot-toast';
import EventFilters from '../../components/events/EventFilters';

interface EventsListProps {
  events?: Event[];
  loading?: boolean;
  showFilters?: boolean;
  emptyMessage?: string;
}

const EventsList = ({ events: propEvents, loading: propLoading, showFilters = true, emptyMessage = "No events found" }: EventsListProps) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(propLoading ?? true);
  const [events, setEvents] = useState<Event[]>(propEvents ?? []);
  const [error, setError] = useState<string | null>(null);
  const [view, setView] = useState<'grid' | 'list'>('grid');
  const [filters, setFilters] = useState({
    status: '',
    search: '',
    startDate: '',
    endDate: ''
  });

  // Helper function to safely format dates
  const formatDate = (timestamp: Timestamp | null | undefined) => {
    if (!timestamp) return 'N/A';
    try {
      return format(timestamp.toDate(), 'MMM d, yyyy h:mm a');
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Invalid Date';
    }
  };

  useEffect(() => {
    if (propEvents) {
      setEvents(propEvents);
      setLoading(false);
      return;
    }

    const fetchEvents = async () => {
      try {
        setLoading(true);
        setError(null);

        console.log('Current filters:', filters);
        let eventsQuery = query(collection(db, 'events'));

        // Apply server-side filters
        if (filters.status && filters.status !== '') {
          console.log('Applying status filter:', filters.status);
          eventsQuery = query(eventsQuery, where('status', '==', filters.status));
        }

        // Sort client-side for now until index is created
        const querySnapshot = await getDocs(eventsQuery);
        console.log('Found events:', querySnapshot.size);

        const fetchedEvents = querySnapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            ...data,
            title: data.title || 'Untitled',
            description: data.description || '',
            status: data.status || 'draft',
            location: data.location || '',
            createdAt: data.createdAt as Timestamp,
            updatedAt: data.updatedAt as Timestamp,
            startDate: data.startDate as Timestamp,
            endDate: data.endDate as Timestamp,
            publishedAt: data.publishedAt as (Timestamp | null),
            scheduledAt: data.scheduledAt as (Timestamp | null),
          } as Event;
        });

        // Sort events by createdAt desc
        const sortedEvents = [...fetchedEvents].sort((a, b) => {
          const timeA = a.createdAt?.toMillis() ?? 0;
          const timeB = b.createdAt?.toMillis() ?? 0;
          return timeB - timeA;
        });

        // Apply client-side filters
        let filteredEvents = sortedEvents;

        if (filters.search) {
          const searchLower = filters.search.toLowerCase();
          filteredEvents = filteredEvents.filter(event =>
            event.title.toLowerCase().includes(searchLower) ||
            event.description.toLowerCase().includes(searchLower) ||
            event.location.toLowerCase().includes(searchLower)
          );
        }

        if (filters.startDate) {
          const startDate = new Date(filters.startDate);
          filteredEvents = filteredEvents.filter(event =>
            event.startDate && event.startDate.toDate() >= startDate
          );
        }

        if (filters.endDate) {
          const endDate = new Date(filters.endDate);
          filteredEvents = filteredEvents.filter(event =>
            event.endDate && event.endDate.toDate() <= endDate
          );
        }

        console.log('Final filtered events:', filteredEvents.map(e => ({ id: e.id, status: e.status })));
        setEvents(filteredEvents);
      } catch (error) {
        console.error('Error fetching events:', error);
        setError('Failed to fetch events');
        toast.error('Failed to fetch events');
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, [propEvents, filters]);

  const handleDelete = async (eventId: string) => {
    if (!window.confirm('Are you sure you want to delete this event?')) return;

    try {
      await deleteDoc(doc(db, 'events', eventId));
      setEvents(prev => prev.filter(event => event.id !== eventId));
      toast.success('Event deleted successfully');
    } catch (error) {
      console.error('Error deleting event:', error);
      toast.error('Failed to delete event');
    }
  };

  const handleFilterChange = (name: string, value: string) => {
    setFilters(prev => ({ ...prev, [name]: value }));
  };

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center min-h-[400px] p-4">
        <p className="text-red-500 text-lg mb-4">{error}</p>
        <button
          onClick={() => window.location.reload()}
          className="px-4 py-2 bg-primary text-white rounded-lg hover:bg-primary/80 transition-colors"
        >
          Retry
        </button>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="flex flex-col sm:flex-row gap-4 items-start sm:items-center justify-between">
        <div className="flex items-center gap-4">
          <button
            onClick={() => setView('grid')}
            className={`p-2 ${view === 'grid' ? 'text-primary' : 'text-gray-400 hover:text-white'} transition-colors`}
          >
            <FiGrid className="w-5 h-5" />
          </button>
          <button
            onClick={() => setView('list')}
            className={`p-2 ${view === 'list' ? 'text-primary' : 'text-gray-400 hover:text-white'} transition-colors`}
          >
            <FiList className="w-5 h-5" />
          </button>
        </div>

        <button
          onClick={() => navigate('/admin/events/add')}
          className="inline-flex items-center gap-2 px-4 py-2 bg-primary text-white rounded-lg hover:bg-primary/80 transition-colors ml-auto"
        >
          <FiPlus className="w-5 h-5" />
          <span>Add Event</span>
        </button>
      </div>

      {/* Filters */}
      {showFilters && (
        <EventFilters
          filters={filters}
          onFilterChange={handleFilterChange}
        />
      )}

      {/* Loading State */}
      {loading ? (
        <div className="flex items-center justify-center min-h-[400px]">
          <FiLoader className="w-8 h-8 text-primary animate-spin" />
        </div>
      ) : events.length === 0 ? (
        <div className="flex flex-col items-center justify-center min-h-[400px] p-4">
          <p className="text-gray-400 text-lg mb-4">{emptyMessage}</p>
          <button
            onClick={() => navigate('/admin/events/add')}
            className="inline-flex items-center gap-2 px-4 py-2 bg-primary text-white rounded-lg hover:bg-primary/80 transition-colors"
          >
            <FiPlus className="w-5 h-5" />
            <span>Add Event</span>
          </button>
        </div>
      ) : view === 'grid' ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
          {events.map(event => (
            <div
              key={event.id}
              className="group relative bg-white/5 rounded-xl overflow-hidden hover:bg-white/10 transition-colors"
            >
              {/* Event Image */}
              <div className="aspect-video bg-gray-800 relative">
                {event.featuredImage?.url ? (
                  <img
                    src={event.featuredImage.url}
                    alt={event.featuredImage.alt || event.title}
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <div className="w-full h-full flex items-center justify-center bg-gray-800">
                    <FiCalendar className="w-8 h-8 text-gray-600" />
                  </div>
                )}
                <div className="absolute top-2 right-2 flex items-center gap-2">
                  <span className={`px-2 py-1 text-xs rounded-full ${
                    event.status === 'published' ? 'bg-green-500/20 text-green-400' :
                    event.status === 'scheduled' ? 'bg-yellow-500/20 text-yellow-400' :
                    'bg-gray-500/20 text-gray-400'
                  }`}>
                    {event.status.charAt(0).toUpperCase() + event.status.slice(1)}
                  </span>
                </div>
              </div>

              {/* Event Content */}
              <div className="p-4">
                <h3 className="text-lg font-semibold text-white mb-2 line-clamp-2">{event.title}</h3>
                <p className="text-sm text-gray-400 mb-4 line-clamp-2">{event.description}</p>

                <div className="space-y-2 text-sm text-gray-400">
                  <div className="flex items-center gap-2">
                    <FiCalendar className="w-4 h-4" />
                    <span>{formatDate(event.startDate)}</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <FiMapPin className="w-4 h-4" />
                    <span className="line-clamp-1">{event.location}</span>
                  </div>
                  {event.capacity && (
                    <div className="flex items-center gap-2">
                      <FiUsers className="w-4 h-4" />
                      <span>{event.registeredCount || 0} / {event.capacity}</span>
                    </div>
                  )}
                </div>

                {/* Action Buttons */}
                <div className="mt-4 flex justify-end items-center gap-2">
                  <button
                    onClick={() => navigate(`/admin/events/${event.id}`)}
                    className="p-2 text-gray-400 hover:text-white transition-colors"
                    title="View Event"
                  >
                    <FiEye className="w-4 h-4" />
                  </button>
                  <button
                    onClick={() => navigate(`/admin/events/edit/${event.id}`)}
                    className="p-2 text-gray-400 hover:text-white transition-colors"
                    title="Edit Event"
                  >
                    <FiEdit2 className="w-4 h-4" />
                  </button>
                  <button
                    onClick={() => handleDelete(event.id!)}
                    className="p-2 text-gray-400 hover:text-red-400 transition-colors"
                    title="Delete Event"
                  >
                    <FiTrash2 className="w-4 h-4" />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="overflow-x-auto">
          <table className="w-full border-collapse">
            <thead>
              <tr className="border-b border-white/10">
                <th className="py-3 px-4 text-left font-medium text-gray-400">Title</th>
                <th className="py-3 px-4 text-left font-medium text-gray-400">Date</th>
                <th className="py-3 px-4 text-left font-medium text-gray-400">Location</th>
                <th className="py-3 px-4 text-left font-medium text-gray-400">Status</th>
                <th className="py-3 px-4 text-left font-medium text-gray-400">Capacity</th>
                <th className="py-3 px-4 text-right font-medium text-gray-400">Actions</th>
              </tr>
            </thead>
            <tbody>
              {events.map(event => (
                <tr key={event.id} className="border-b border-white/10 hover:bg-white/5">
                  <td className="py-3 px-4">
                    <div className="flex items-center gap-3">
                      <div className="w-10 h-10 rounded-lg bg-gray-800 overflow-hidden flex-shrink-0">
                        {event.featuredImage?.url ? (
                          <img
                            src={event.featuredImage.url}
                            alt={event.featuredImage.alt || event.title}
                            className="w-full h-full object-cover"
                          />
                        ) : (
                          <div className="w-full h-full flex items-center justify-center">
                            <FiCalendar className="w-5 h-5 text-gray-600" />
                          </div>
                        )}
                      </div>
                      <div>
                        <h4 className="font-medium text-white">{event.title}</h4>
                        <p className="text-sm text-gray-400 line-clamp-1">{event.description}</p>
                      </div>
                    </div>
                  </td>
                  <td className="py-3 px-4 text-gray-400">{formatDate(event.startDate)}</td>
                  <td className="py-3 px-4 text-gray-400">{event.location}</td>
                  <td className="py-3 px-4">
                    <span className={`inline-flex px-2 py-1 text-xs rounded-full ${
                      event.status === 'published' ? 'bg-green-500/20 text-green-400' :
                      event.status === 'scheduled' ? 'bg-yellow-500/20 text-yellow-400' :
                      'bg-gray-500/20 text-gray-400'
                    }`}>
                      {event.status.charAt(0).toUpperCase() + event.status.slice(1)}
                    </span>
                  </td>
                  <td className="py-3 px-4 text-gray-400">
                    {event.capacity ? `${event.registeredCount || 0} / ${event.capacity}` : 'N/A'}
                  </td>
                  <td className="py-3 px-4">
                    <div className="flex items-center justify-end gap-2">
                      <button
                        onClick={() => navigate(`/admin/events/${event.id}`)}
                        className="p-2 text-gray-400 hover:text-white transition-colors"
                        title="View Event"
                      >
                        <FiEye className="w-4 h-4" />
                      </button>
                      <button
                        onClick={() => navigate(`/admin/events/edit/${event.id}`)}
                        className="p-2 text-gray-400 hover:text-white transition-colors"
                        title="Edit Event"
                      >
                        <FiEdit2 className="w-4 h-4" />
                      </button>
                      <button
                        onClick={() => handleDelete(event.id!)}
                        className="p-2 text-gray-400 hover:text-red-400 transition-colors"
                        title="Delete Event"
                      >
                        <FiTrash2 className="w-4 h-4" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default EventsList;
