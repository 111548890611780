import React from 'react';
import { FiSearch, FiFilter, FiCalendar } from 'react-icons/fi';

interface EventFiltersProps {
  filters: {
    search: string;
    status: string;
    startDate: string;
    endDate: string;
  };
  onFilterChange: (name: string, value: string) => void;
}

const EventFilters: React.FC<EventFiltersProps> = ({ filters, onFilterChange }) => {
  const inputStyle = {
    backgroundColor: 'rgb(17, 24, 39)',
    color: 'white'
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
      {/* Search */}
      <div className="relative">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <FiSearch className="h-5 w-5 text-gray-400" />
        </div>
        <input
          type="text"
          value={filters.search}
          onChange={(e) => onFilterChange('search', e.target.value)}
          placeholder="Search events..."
          className="block w-full pl-10 pr-3 py-2 bg-gray-900 border border-white/10 rounded-lg text-white placeholder-gray-400 focus:ring-2 focus:ring-primary focus:border-primary transition-colors"
          style={inputStyle}
        />
      </div>

      {/* Status Filter */}
      <div className="relative">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <FiFilter className="h-5 w-5 text-gray-400" />
        </div>
        <select
          value={filters.status}
          onChange={(e) => onFilterChange('status', e.target.value)}
          className="block w-full pl-10 pr-3 py-2 bg-gray-900 border border-white/10 rounded-lg text-white focus:ring-2 focus:ring-primary focus:border-primary transition-colors appearance-none"
          style={inputStyle}
        >
          <option value="" className="bg-gray-900 text-white">All Status</option>
          <option value="draft" className="bg-gray-900 text-white">Draft</option>
          <option value="published" className="bg-gray-900 text-white">Published</option>
          <option value="scheduled" className="bg-gray-900 text-white">Scheduled</option>
        </select>
      </div>

      {/* Date Range */}
      <div className="relative">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <FiCalendar className="h-5 w-5 text-gray-400" />
        </div>
        <input
          type="date"
          value={filters.startDate}
          onChange={(e) => onFilterChange('startDate', e.target.value)}
          className="block w-full pl-10 pr-3 py-2 bg-gray-900 border border-white/10 rounded-lg text-white focus:ring-2 focus:ring-primary focus:border-primary transition-colors [color-scheme:dark]"
          style={inputStyle}
        />
      </div>

      <div className="relative">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <FiCalendar className="h-5 w-5 text-gray-400" />
        </div>
        <input
          type="date"
          value={filters.endDate}
          onChange={(e) => onFilterChange('endDate', e.target.value)}
          className="block w-full pl-10 pr-3 py-2 bg-gray-900 border border-white/10 rounded-lg text-white focus:ring-2 focus:ring-primary focus:border-primary transition-colors [color-scheme:dark]"
          style={inputStyle}
        />
      </div>
    </div>
  );
};

export default EventFilters;
