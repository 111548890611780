import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, Timestamp } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { Article } from '../../types/article';
import { FiArrowLeft, FiEdit2, FiClock, FiCalendar, FiUser, FiTag } from 'react-icons/fi';
import { format } from 'date-fns';
import toast from 'react-hot-toast';

const ArticlePreview: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [article, setArticle] = useState<Article | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  // Helper function to format dates safely
  const formatDate = (timestamp: Timestamp | null | undefined) => {
    if (!timestamp || !(timestamp instanceof Timestamp)) {
      return 'No date';
    }
    return format(timestamp.toDate(), 'MMM d, yyyy');
  };

  useEffect(() => {
    const fetchArticle = async () => {
      if (!id) {
        setError('Article ID is missing');
        setLoading(false);
        return;
      }
      
      try {
        console.log('Fetching article with ID:', id);
        const docRef = doc(db, 'articles', id);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
          const data = docSnap.data();
          console.log('Article data:', data);

          const processedArticle = {
            id: docSnap.id,
            ...data,
            title: data.title || 'Untitled',
            content: data.content || '',
            status: data.status || 'draft',
            author: data.author || 'Anonymous',
            categories: data.categories || [],
            readTime: data.readTime || Math.ceil((data.content?.split(' ').length || 0) / 200),
            createdAt: data.createdAt,
            updatedAt: data.updatedAt,
            publishedAt: data.publishedAt,
            scheduledAt: data.scheduledAt,
          } as Article;

          console.log('Processed article:', processedArticle);
          setArticle(processedArticle);
        } else {
          console.error('Article not found:', id);
          setError('Article not found');
          toast.error('Article not found');
          navigate('/admin/articles');
        }
      } catch (error) {
        console.error('Error fetching article:', error);
        setError(error instanceof Error ? error.message : 'Failed to fetch article');
        toast.error('Failed to fetch article');
      } finally {
        setLoading(false);
      }
    };

    fetchArticle();
  }, [id, navigate]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-[50vh]">
        <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center h-[50vh] space-y-4">
        <p className="text-red-500">Error: {error}</p>
        <button
          onClick={() => navigate('/admin/articles')}
          className="flex items-center gap-2 bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-lg transition-colors"
        >
          <FiArrowLeft /> Back to Articles
        </button>
      </div>
    );
  }

  if (!article) {
    return null;
  }

  return (
    <div className="p-6 max-w-4xl mx-auto">
      <div className="flex items-center justify-between mb-8">
        <button
          onClick={() => navigate('/admin/articles')}
          className="flex items-center gap-2 text-gray-400 hover:text-white transition-colors"
        >
          <FiArrowLeft /> Back to Articles
        </button>
        <button
          onClick={() => navigate(`/admin/articles/edit/${id}`)}
          className="flex items-center gap-2 bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-lg transition-colors"
        >
          <FiEdit2 /> Edit Article
        </button>
      </div>

      <div className="bg-dark-secondary/30 rounded-xl p-8 space-y-6">
        {article.featuredImage?.url && (
          <img
            src={article.featuredImage.url}
            alt={article.featuredImage.alt || article.title}
            className="w-full h-[400px] object-cover rounded-lg"
          />
        )}

        <div className="flex flex-wrap gap-4 text-sm text-gray-400">
          <div className="flex items-center gap-2">
            <FiCalendar />
            {formatDate(article.createdAt)}
          </div>
          <div className="flex items-center gap-2">
            <FiClock />
            {article.readTime || 1} min read
          </div>
          <div className="flex items-center gap-2">
            <FiUser />
            {article.author || 'Anonymous'}
          </div>
          {article.categories?.length > 0 && (
            <div className="flex items-center gap-2">
              <FiTag />
              {article.categories.join(', ')}
            </div>
          )}
        </div>

        <h1 className="text-4xl font-bold text-white">{article.title}</h1>
        
        <div className="prose prose-invert max-w-none">
          <div dangerouslySetInnerHTML={{ __html: article.content }} />
        </div>
      </div>
    </div>
  );
};

export default ArticlePreview;
