import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FiImage } from 'react-icons/fi';

interface Gallery {
  id: string;
  title: string;
  description: string;
  imageCount: number;
  createdAt: string;
  thumbnail: string;
}

const DeletePhotoGallery: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [gallery, setGallery] = useState<Gallery | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchGallery = async () => {
      try {
        // TODO: Replace with actual API call
        // Mock data for demonstration
        const mockGallery = {
          id: '1',
          title: 'Annual Conference 2024',
          description: 'Photos from our annual tech conference',
          imageCount: 45,
          createdAt: '2024-01-15',
          thumbnail: 'https://example.com/thumbnail1.jpg',
        };

        setGallery(mockGallery);
        setIsLoading(false);
      } catch (error) {
        setError('Failed to fetch gallery details');
        setIsLoading(false);
      }
    };

    if (id) {
      fetchGallery();
    }
  }, [id]);

  const handleDelete = async () => {
    try {
      setIsLoading(true);
      // TODO: Implement delete API call
      console.log('Deleting gallery:', id);
      navigate('/admin/photo-gallery');
    } catch (error) {
      setError('Failed to delete gallery');
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-full">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-white"></div>
      </div>
    );
  }

  if (error || !gallery) {
    return (
      <div className="p-6 max-w-2xl mx-auto bg-gray-800 rounded-lg shadow-lg">
        <div className="text-red-400 text-center">
          {error || 'Gallery not found'}
        </div>
        <div className="mt-4 flex justify-center">
          <button
            onClick={() => navigate('/admin/photo-gallery')}
            className="px-4 py-2 text-sm font-medium text-white bg-gray-700 rounded-md hover:bg-gray-600"
          >
            Back to Galleries
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="p-6 max-w-2xl mx-auto">
      <div className="bg-gray-800 rounded-lg shadow-lg overflow-hidden">
        <div className="p-6">
          <h2 className="text-2xl font-bold text-white mb-6">Delete Photo Gallery</h2>
          
          <div className="bg-red-900/50 text-red-200 p-4 rounded-lg mb-6">
            <p className="text-lg mb-2">Are you sure you want to delete this gallery?</p>
            <p className="text-sm">This will permanently delete the gallery and all its photos. This action cannot be undone.</p>
          </div>

          <div className="bg-gray-700 rounded-lg overflow-hidden mb-6">
            <div className="aspect-w-16 aspect-h-9 bg-gray-600">
              {gallery.thumbnail ? (
                <img
                  src={gallery.thumbnail}
                  alt={gallery.title}
                  className="object-cover w-full h-full"
                />
              ) : (
                <div className="flex items-center justify-center w-full h-full">
                  <FiImage className="w-12 h-12 text-gray-500" />
                </div>
              )}
            </div>
            
            <div className="p-4">
              <h3 className="text-lg font-semibold text-white mb-2">{gallery.title}</h3>
              <p className="text-gray-300 text-sm mb-4">{gallery.description}</p>
              
              <div className="flex justify-between items-center text-sm text-gray-400">
                <span>{gallery.imageCount} photos</span>
                <span>Created on {new Date(gallery.createdAt).toLocaleDateString()}</span>
              </div>
            </div>
          </div>

          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={() => navigate('/admin/photo-gallery')}
              className="px-6 py-2 text-sm font-medium text-gray-300 bg-gray-700 rounded-md hover:bg-gray-600"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={handleDelete}
              className="px-6 py-2 text-sm font-medium text-white bg-red-600 rounded-md hover:bg-red-700"
            >
              Delete Gallery
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeletePhotoGallery;
