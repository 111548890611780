import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

interface Event {
  id: string;
  title: string;
  date: string;
  time: string;
  location: string;
}

const DeleteEvent: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [event, setEvent] = useState<Event | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        // TODO: Replace with actual API call
        // Mock data for demonstration
        const mockEvent = {
          id: '1',
          title: 'Annual Tech Conference 2024',
          date: '2024-03-15',
          time: '09:00',
          location: 'Convention Center',
        };

        setEvent(mockEvent);
        setIsLoading(false);
      } catch (error) {
        setError('Failed to fetch event details');
        setIsLoading(false);
      }
    };

    if (id) {
      fetchEvent();
    }
  }, [id]);

  const handleDelete = async () => {
    try {
      setIsLoading(true);
      // TODO: Implement delete API call
      console.log('Deleting event:', id);
      navigate('/admin/events');
    } catch (error) {
      setError('Failed to delete event');
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-full">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-white"></div>
      </div>
    );
  }

  if (error || !event) {
    return (
      <div className="p-6 max-w-2xl mx-auto bg-gray-800 rounded-lg shadow-lg">
        <div className="text-red-400 text-center">
          {error || 'Event not found'}
        </div>
        <div className="mt-4 flex justify-center">
          <button
            onClick={() => navigate('/admin/events')}
            className="px-4 py-2 text-sm font-medium text-white bg-gray-700 rounded-md hover:bg-gray-600"
          >
            Back to Events
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="p-6 max-w-2xl mx-auto bg-gray-800 rounded-lg shadow-lg">
      <div className="text-center">
        <h2 className="text-2xl font-bold text-white mb-6">Delete Event</h2>
        <div className="bg-red-900/50 text-red-200 p-4 rounded-lg mb-6">
          <p className="text-lg mb-2">Are you sure you want to delete this event?</p>
          <p className="text-sm">This action cannot be undone.</p>
        </div>

        <div className="bg-gray-700 p-4 rounded-lg mb-6">
          <h3 className="text-lg font-semibold text-white mb-4">{event.title}</h3>
          <div className="text-gray-300 space-y-2">
            <p>Date: {new Date(event.date).toLocaleDateString()}</p>
            <p>Time: {event.time}</p>
            <p>Location: {event.location}</p>
          </div>
        </div>

        <div className="flex justify-center space-x-4">
          <button
            onClick={() => navigate('/admin/events')}
            className="px-6 py-2 text-sm font-medium text-gray-300 bg-gray-700 rounded-md hover:bg-gray-600"
          >
            Cancel
          </button>
          <button
            onClick={handleDelete}
            className="px-6 py-2 text-sm font-medium text-white bg-red-600 rounded-md hover:bg-red-700"
          >
            Delete Event
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteEvent;
