import React, { useEffect } from 'react';

const ErrorPage: React.FC = () => {
  useEffect(() => {
    // Set the browser tab title to mimic a DNS error
    document.title = "Site can't be reached";
    
    // Cleanup when component unmounts
    return () => {
      document.title = "CCRO India";
    };
  }, []);

  // Silent refresh without any console logs
  const handleTryAgain = (e: React.MouseEvent) => {
    e.preventDefault();
    window.location.reload();
  };

  return (
    <div style={{
      fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#fff',
      color: '#202124',
      margin: 0,
      padding: '0 20px'
    }}>
      <div style={{ maxWidth: '600px', width: '100%' }}>
        <div style={{ 
          display: 'flex', 
          alignItems: 'center', 
          marginBottom: '16px',
          gap: '12px'
        }}>
          <img 
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARCAYAAAA7bUf6AAAAAXNSR0IArs4c6QAAAZlJREFUOE+V0z9IlVEYx/HPEkhQUNQgRRAUGAS1NNTU4uDQEM7h4FBQg0NDU0P/BsHFoaEloobAqSGIBoem/oANNTQEQUNESEME0WRxjryX9/Je38t74MDvPc/3Oec553nOqUgpNaCD1/8hqMM9PMB1fMJ0RKz8lVNKzTiPrxExl1LqxBqaIuJXSukCtnEJaxHxvayoCvMYxJ2I+FyKt+M7mnAVrxDoxkRELKSUrmEJ9yPibbmSZ3iJ8fJnpTSBKbRiDNv4URbchqe4ibcRsZVSasABGrEZET/PQh7iFY7QHxHLKaUu7EfEz5TSPRxjMCLWU0oXsYneiFhMKY3gGboi4uCsktd4jNGIWCwgo3iCOxiOiOOU0gq6cRwR2ymlcbxAd0Qc1oI8wQzuRsSnYn0Ib/AQb4rN7cMc2iJir6jqGqbRFhH7tSBzeIzBiFguINfxHvURcVQWXI9P6IiIvVqQ+aLFnRGxllJqwS6uRMT3s/9GW0RUZj2lVIePxSn9G6Qk+D+QM0f4B+QkpXQJ39D8r5s9BfkNfNJpEfWk8uUAAAAASUVORK5CYII=" 
            alt=""
            style={{ width: '20px', height: '20px' }}
          />
          <span style={{ 
            fontSize: '20px', 
            fontWeight: 500,
            color: '#202124'
          }}>
            This site can't be reached
          </span>
        </div>
        
        <div style={{ 
          marginLeft: '32px',
          color: '#5f6368',
          fontSize: '14px',
          lineHeight: '20px'
        }}>
          <p>Check if there is a typo in <b>in.ccroindia.in</b>.</p>
          <p>If spelling is correct, try running Windows Network Diagnostics.</p>
          <p style={{ 
            marginTop: '16px',
            color: '#8a8a8a',
            fontSize: '12px'
          }}>
            DNS_PROBE_FINISHED_NXDOMAIN
          </p>
        </div>
        
        <div style={{ 
          marginLeft: '32px',
          marginTop: '32px'
        }}>
          <button 
            onClick={handleTryAgain}
            style={{
              backgroundColor: '#1a73e8',
              color: 'white',
              border: 'none',
              borderRadius: '4px',
              padding: '8px 16px',
              fontSize: '14px',
              fontWeight: 500,
              cursor: 'pointer'
            }}
          >
            Try Again
          </button>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
