import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FiAlertTriangle, FiTrash2 } from 'react-icons/fi';

interface UserNotification {
  id: string;
  title: string;
  message: string;
  userId: string;
  userName: string;
  scheduledDate: string;
  priority: 'low' | 'medium' | 'high';
  status: 'scheduled' | 'sent' | 'failed';
  type: 'private';
}

const DeleteUserNotification: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [notification, setNotification] = useState<UserNotification | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    const fetchNotification = async () => {
      try {
        // TODO: Replace with actual API call
        const mockNotification: UserNotification = {
          id: id || '1',
          title: 'Account Update Required',
          message: 'Please update your profile information...',
          userId: 'user1',
          userName: 'John Doe',
          scheduledDate: '2024-02-01T10:00',
          priority: 'high',
          status: 'scheduled',
          type: 'private',
        };

        setNotification(mockNotification);
        setIsFetching(false);
      } catch (error) {
        console.error('Error fetching notification:', error);
        setError('Failed to fetch notification details.');
        setIsFetching(false);
      }
    };

    fetchNotification();
  }, [id]);

  const handleDelete = async () => {
    setIsLoading(true);
    setError('');

    try {
      // TODO: Implement actual API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      navigate('/admin/user-notifications');
    } catch (error) {
      setError('Failed to delete notification. Please try again.');
      setIsLoading(false);
    }
  };

  if (isFetching) {
    return (
      <div className="flex justify-center items-center h-full">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-white"></div>
      </div>
    );
  }

  if (!notification) {
    return (
      <div className="p-6">
        <div className="text-red-400">Notification not found.</div>
        <button
          onClick={() => navigate('/admin/user-notifications')}
          className="mt-4 text-blue-400 hover:text-blue-300"
        >
          Back to Notifications
        </button>
      </div>
    );
  }

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold text-white mb-6">Delete Private User Notification</h1>

      <div className="bg-gray-800 rounded-lg shadow-lg p-6 mb-6">
        <div className="flex items-center justify-center text-yellow-400 mb-6">
          <FiAlertTriangle className="h-12 w-12" />
        </div>

        <div className="text-center mb-6">
          <h2 className="text-xl font-semibold text-white mb-2">
            Are you sure you want to delete this notification?
          </h2>
          <p className="text-gray-400">
            This action cannot be undone.
          </p>
        </div>

        <div className="border-t border-gray-700 pt-6">
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-400">Title</label>
              <div className="text-white">{notification.title}</div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-400">Message</label>
              <div className="text-white">{notification.message}</div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-400">User</label>
              <div className="text-white">{notification.userName}</div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-400">Scheduled Date</label>
              <div className="text-white">
                {new Date(notification.scheduledDate).toLocaleString()}
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-400">Priority</label>
              <div className="text-white capitalize">{notification.priority}</div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-400">Status</label>
              <div className="text-white capitalize">{notification.status}</div>
            </div>
          </div>
        </div>
      </div>

      {error && (
        <div className="text-red-400 text-sm mb-6">
          {error}
        </div>
      )}

      <div className="flex justify-end space-x-4">
        <button
          type="button"
          onClick={() => navigate('/admin/user-notifications')}
          className="px-4 py-2 text-gray-400 hover:text-gray-300"
        >
          Cancel
        </button>
        <button
          onClick={handleDelete}
          disabled={isLoading}
          className="px-6 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 disabled:bg-red-800 disabled:cursor-not-allowed flex items-center"
        >
          {isLoading ? (
            <>
              <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white mr-2"></div>
              Deleting...
            </>
          ) : (
            <>
              <FiTrash2 className="mr-2" />
              Delete Private Notification
            </>
          )}
        </button>
      </div>
    </div>
  );
};

export default DeleteUserNotification;
