import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Event } from '../../types/event';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../../styles/quill-dark.css';
import { FiImage, FiCalendar, FiClock, FiMapPin, FiDollarSign } from 'react-icons/fi';
import { Timestamp } from 'firebase/firestore';
import toast from 'react-hot-toast';

interface EventFormProps {
  event?: Partial<Event>;
  onSubmit: (data: Partial<Event>) => void;
  isLoading?: boolean;
  isEditing?: boolean;
}

interface EventFormRef {
  getFormData: () => Partial<Event> | null;
  updateFormData: (data: Partial<Event>) => void;
}

const EventForm = forwardRef<EventFormRef, EventFormProps>(({ event, onSubmit, isLoading = false, isEditing = false }, ref) => {
  const [formData, setFormData] = useState<Partial<Event>>({
    title: '',
    description: '',
    content: '',
    featuredImage: {
      url: '',
      alt: ''
    },
    location: '',
    price: 0,
    capacity: 0,
    status: 'draft',
    startDate: undefined,
    endDate: undefined,
    ...event,
  });

  useEffect(() => {
    if (event) {
      setFormData(event);
    }
  }, [event]);

  const optimizeImage = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          // Target size: around 200KB for featured images
          const maxWidth = 1200;
          const maxHeight = 800;
          let quality = 0.7; // Start with 70% quality

          let width = img.width;
          let height = img.height;

          // Calculate new dimensions
          if (width > maxWidth) {
            height = (height * maxWidth) / width;
            width = maxWidth;
          }
          if (height > maxHeight) {
            width = (width * maxHeight) / height;
            height = maxHeight;
          }

          canvas.width = width;
          canvas.height = height;
          ctx?.drawImage(img, 0, 0, width, height);

          // Convert to WebP format for better compression
          const optimizedImage = canvas.toDataURL('image/webp', quality);
          resolve(optimizedImage);
        };
        img.onerror = reject;
        img.src = reader.result as string;
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      
      try {
        const optimizedImageUrl = await optimizeImage(file);
        setFormData(prev => ({
          ...prev,
          featuredImage: {
            url: optimizedImageUrl,
            alt: file.name
          }
        }));
      } catch (error) {
        console.error('Error optimizing image:', error);
        toast.error('Failed to process image. Please try another image.');
      }
    }
  };

  const validateFormData = () => {
    if (!formData.title?.trim()) {
      toast.error('Title is required');
      return null;
    }
    if (!formData.description?.trim()) {
      toast.error('Description is required');
      return null;
    }
    if (!formData.content?.trim()) {
      toast.error('Content is required');
      return null;
    }
    if (!formData.startDate) {
      toast.error('Start date is required');
      return null;
    }
    if (!formData.location?.trim()) {
      toast.error('Location is required');
      return null;
    }
    return formData;
  };

  useImperativeHandle(ref, () => ({
    getFormData: validateFormData,
    updateFormData: (newData: Partial<Event>) => {
      setFormData(prev => ({ ...prev, ...newData }));
    }
  }), [formData]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const data = validateFormData();
    if (data) {
      onSubmit(data);
    }
  };

  const inputStyle = {
    backgroundColor: 'rgb(17, 24, 39)',
    color: 'white'
  };

  return (
    <form className="p-4 sm:p-6" onSubmit={handleSubmit}>
      {/* Main Content Area */}
      <div className="grid grid-cols-1 lg:grid-cols-4 gap-6">
        {/* Left Column - Main Content */}
        <div className="lg:col-span-3 space-y-6">
          {/* Title and Description */}
          <div className="space-y-4 bg-white/10 p-4 sm:p-6 rounded-xl">
            <div>
              <label htmlFor="title" className="block text-base font-medium text-white mb-2">
                Title *
              </label>
              <input
                type="text"
                id="title"
                name="title"
                value={formData.title}
                onChange={(e) => setFormData(prev => ({ ...prev, title: e.target.value }))}
                className="block w-full px-3 py-2 bg-gray-900 border border-white/10 rounded-lg text-white placeholder-gray-400 focus:ring-2 focus:ring-primary focus:border-primary transition-colors"
                style={inputStyle}
                placeholder="Enter event title"
                required
              />
            </div>

            <div>
              <label htmlFor="description" className="block text-base font-medium text-white mb-2">
                Description *
              </label>
              <textarea
                id="description"
                name="description"
                value={formData.description}
                onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
                className="block w-full px-3 py-2 bg-gray-900 border border-white/10 rounded-lg text-white placeholder-gray-400 focus:ring-2 focus:ring-primary focus:border-primary transition-colors"
                style={inputStyle}
                placeholder="Enter event description"
                rows={3}
                required
              />
            </div>
          </div>

          {/* Rich Text Editor */}
          <div className="bg-white/10 rounded-xl overflow-hidden mb-16">
            <div className="p-4 sm:p-6">
              <label className="block text-base font-medium text-white mb-2">
                Content *
              </label>
              <div className="dark-quill">
                <ReactQuill
                  theme="snow"
                  value={formData.content}
                  onChange={(content) => setFormData(prev => ({ ...prev, content }))}
                  className="bg-gray-900 text-white rounded-lg"
                  modules={{
                    toolbar: [
                      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                      ['bold', 'italic', 'underline', 'strike'],
                      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                      [{ 'indent': '-1'}, { 'indent': '+1' }],
                      [{ 'color': [] }, { 'background': [] }],
                      ['link', 'image'],
                      ['clean']
                    ],
                  }}
                  style={{
                    height: '500px', // Further reduced height to account for increased margin
                    backgroundColor: 'rgb(17, 24, 39)',
                    color: 'white',
                    marginBottom: '4rem' // Increased bottom margin
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Right Column - Meta Fields */}
        <div className="lg:col-span-1 space-y-6">
          {/* Featured Image */}
          <div className="bg-white/10 p-4 sm:p-6 rounded-xl space-y-4">
            <h3 className="flex items-center gap-2 text-lg font-medium text-white">
              <FiImage className="w-5 h-5" />
              Featured Image
            </h3>
            <div className="space-y-4">
              {formData.featuredImage?.url && (
                <div className="relative aspect-video rounded-lg overflow-hidden bg-gray-100">
                  <img
                    src={formData.featuredImage.url}
                    alt={formData.featuredImage.alt}
                    className="w-full h-full object-cover"
                  />
                </div>
              )}
              <div>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  className="block w-full text-sm text-white
                    file:mr-4 file:py-2 file:px-4
                    file:rounded-lg file:border-0
                    file:text-sm file:font-medium
                    file:bg-primary file:text-white
                    hover:file:cursor-pointer hover:file:bg-primary/80
                    hover:file:text-white"
                />
              </div>
            </div>
          </div>

          {/* Event Details */}
          <div className="bg-white/10 p-4 sm:p-6 rounded-xl space-y-4">
            <h3 className="flex items-center gap-2 text-lg font-medium text-white">
              <FiCalendar className="w-5 h-5" />
              Event Details
            </h3>
            <div className="space-y-4">
              <div>
                <label htmlFor="startDate" className="block text-sm font-medium text-gray-300 mb-2">
                  Start Date
                </label>
                <input
                  type="datetime-local"
                  id="startDate"
                  name="startDate"
                  value={formData.startDate ? formData.startDate.toDate().toISOString().slice(0, 16) : ''}
                  onChange={(e) => {
                    const date = e.target.value ? new Date(e.target.value) : undefined;
                    setFormData(prev => ({
                      ...prev,
                      startDate: date ? Timestamp.fromDate(date) : undefined
                    }));
                  }}
                  className="block w-full px-3 py-2 bg-gray-900 border border-white/10 rounded-lg text-white focus:ring-2 focus:ring-primary focus:border-primary transition-colors [color-scheme:dark]"
                  style={inputStyle}
                  required
                />
              </div>

              <div>
                <label htmlFor="endDate" className="block text-sm font-medium text-gray-300 mb-2">
                  End Date
                </label>
                <input
                  type="datetime-local"
                  id="endDate"
                  name="endDate"
                  value={formData.endDate ? formData.endDate.toDate().toISOString().slice(0, 16) : ''}
                  onChange={(e) => {
                    const date = e.target.value ? new Date(e.target.value) : undefined;
                    setFormData(prev => ({
                      ...prev,
                      endDate: date ? Timestamp.fromDate(date) : undefined
                    }));
                  }}
                  className="block w-full px-3 py-2 bg-gray-900 border border-white/10 rounded-lg text-white focus:ring-2 focus:ring-primary focus:border-primary transition-colors [color-scheme:dark]"
                  style={inputStyle}
                />
              </div>

              <div>
                <label htmlFor="location" className="block text-sm font-medium text-white mb-1">
                  Location *
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <FiMapPin className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="text"
                    id="location"
                    value={formData.location}
                    onChange={(e) => setFormData(prev => ({ ...prev, location: e.target.value }))}
                    className="block w-full pl-10 pr-4 py-2 bg-gray-900 border border-white/10 rounded-lg text-white placeholder-gray-400 focus:ring-2 focus:ring-primary focus:border-primary transition-colors"
                    style={inputStyle}
                    placeholder="Enter event location"
                    required
                  />
                </div>
              </div>

              <div>
                <label htmlFor="price" className="block text-sm font-medium text-white mb-1">
                  Price
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <FiDollarSign className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="number"
                    id="price"
                    min="0"
                    step="0.01"
                    value={formData.price}
                    onChange={(e) => setFormData(prev => ({ ...prev, price: parseFloat(e.target.value) }))}
                    className="block w-full pl-10 pr-4 py-2 bg-gray-900 border border-white/10 rounded-lg text-white focus:ring-2 focus:ring-primary focus:border-primary transition-colors"
                    style={inputStyle}
                  />
                </div>
              </div>

              <div>
                <label htmlFor="capacity" className="block text-sm font-medium text-white mb-1">
                  Capacity
                </label>
                <input
                  type="number"
                  id="capacity"
                  min="0"
                  value={formData.capacity}
                  onChange={(e) => setFormData(prev => ({ ...prev, capacity: parseInt(e.target.value) }))}
                  className="block w-full px-3 py-2 bg-gray-900 border border-white/10 rounded-lg text-white focus:ring-2 focus:ring-primary focus:border-primary transition-colors"
                  style={inputStyle}
                />
              </div>

              <div>
                <label htmlFor="status" className="block text-sm font-medium text-white mb-1">
                  Status
                </label>
                <select
                  id="status"
                  value={formData.status}
                  onChange={(e) => setFormData(prev => ({ ...prev, status: e.target.value as 'draft' | 'published' | 'scheduled' }))}
                  className="block w-full px-3 py-2 bg-gray-900 border border-white/10 rounded-lg text-white focus:ring-2 focus:ring-primary focus:border-primary transition-colors appearance-none"
                  style={inputStyle}
                >
                  <option value="draft" className="bg-gray-900 text-white">Draft</option>
                  <option value="published" className="bg-gray-900 text-white">Published</option>
                  <option value="scheduled" className="bg-gray-900 text-white">Scheduled</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
});

export default EventForm;
