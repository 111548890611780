import React, { useState, useEffect } from 'react';
import { collection, query, getDocs, deleteDoc, doc, where, Timestamp } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { Article } from '../../types/article';
import { useNavigate } from 'react-router-dom';
import { FiGrid, FiList, FiPlus, FiEdit2, FiEye, FiTrash2, FiLoader } from 'react-icons/fi';
import { format } from 'date-fns';
import toast from 'react-hot-toast';

interface ArticlesListProps {
  articles?: Article[];
  loading?: boolean;
  showFilters?: boolean;
  emptyMessage?: string;
}

const ArticlesList = ({ articles: propArticles, loading: propLoading, showFilters = true, emptyMessage = "No articles found" }: ArticlesListProps) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(propLoading ?? true);
  const [articles, setArticles] = useState<Article[]>(propArticles ?? []);
  const [error, setError] = useState<string | null>(null);
  const [view, setView] = useState<'grid' | 'list'>('grid');
  const [filters, setFilters] = useState({
    status: '',
    category: '',
    search: ''
  });

  // Helper function to safely format dates
  const formatDate = (timestamp: Timestamp | null | undefined) => {
    if (!timestamp) return 'N/A';
    try {
      return format(timestamp.toDate(), 'MMM d, yyyy');
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Invalid Date';
    }
  };

  // Helper function to safely strip HTML and limit text
  const stripHtmlAndLimit = (html: string, limit: number = 150) => {
    // First remove HTML tags
    const text = html.replace(/<[^>]*>/g, '');
    // Then limit the text
    return text.length > limit ? text.slice(0, limit) + '...' : text;
  };

  useEffect(() => {
    if (propArticles) {
      setArticles(propArticles);
      setLoading(false);
      return;
    }

    const fetchArticles = async () => {
      try {
        console.log('Fetching articles...');
        setError(null);
        let q = query(collection(db, 'articles'));
        
        if (filters.status) {
          q = query(q, where('status', '==', filters.status));
        }
        
        const querySnapshot = await getDocs(q);
        console.log('Query snapshot:', querySnapshot.size, 'documents found');
        
        const fetchedArticles = querySnapshot.docs.map(doc => {
          const data = doc.data();
          console.log('Article data:', { id: doc.id, ...data });

          return {
            id: doc.id,
            ...data,
            title: data.title || 'Untitled',
            content: data.content || '',
            status: data.status || 'draft',
            createdAt: data.createdAt as Timestamp,
            updatedAt: data.updatedAt as Timestamp,
            publishedAt: data.publishedAt as (Timestamp | null | undefined),
            scheduledAt: data.scheduledAt as (Timestamp | null | undefined),
          } as Article;
        });
        
        console.log('Processed articles:', fetchedArticles);
        setArticles(fetchedArticles);
      } catch (error) {
        console.error('Error fetching articles:', error);
        setError(error instanceof Error ? error.message : 'Failed to fetch articles');
        toast.error('Failed to fetch articles');
      } finally {
        setLoading(false);
      }
    };

    fetchArticles();
  }, [propArticles, filters.status]);

  const handleDelete = async (articleId: string) => {
    if (!window.confirm('Are you sure you want to delete this article?')) {
      return;
    }

    try {
      await deleteDoc(doc(db, 'articles', articleId));
      setArticles(prev => prev.filter(article => article.id !== articleId));
      toast.success('Article deleted successfully');
    } catch (error) {
      console.error('Error deleting article:', error);
      toast.error('Failed to delete article');
    }
  };

  const filteredArticles = articles?.filter(article => {
    if (!article) return false;
    
    const matchesSearch = filters.search
      ? (article.title?.toLowerCase().includes(filters.search.toLowerCase()) ||
         article.content?.toLowerCase().includes(filters.search.toLowerCase()))
      : true;

    const matchesCategory = filters.category
      ? article.categories?.includes(filters.category)
      : true;

    return matchesSearch && matchesCategory;
  }) || [];

  if (loading) {
    return (
      <div className="flex items-center justify-center h-[50vh]">
        <FiLoader className="w-8 h-8 animate-spin text-blue-500" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center h-[50vh] space-y-4">
        <p className="text-red-500">Error: {error}</p>
        <button
          onClick={() => window.location.reload()}
          className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
        >
          Retry
        </button>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {showFilters && (
        <div className="flex flex-col sm:flex-row gap-4 justify-between items-start sm:items-center bg-dark-secondary/30 p-4 rounded-xl">
          <div className="flex flex-1 gap-4">
            <input
              type="text"
              placeholder="Search articles..."
              value={filters.search}
              onChange={(e) => setFilters(prev => ({ ...prev, search: e.target.value }))}
              className="flex-1 px-4 py-2 bg-dark-secondary/50 text-white rounded-lg border border-white/10 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition-all"
            />
            <select
              value={filters.status}
              onChange={(e) => setFilters(prev => ({ ...prev, status: e.target.value }))}
              className="px-4 py-2 bg-dark-secondary/50 text-white rounded-lg border border-white/10 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition-all"
            >
              <option value="">All Status</option>
              <option value="draft">Draft</option>
              <option value="published">Published</option>
              <option value="scheduled">Scheduled</option>
            </select>
          </div>

          <div className="flex items-center gap-4 w-full sm:w-auto">
            <div className="flex items-center gap-2 bg-dark-secondary/50 rounded-lg p-1">
              <button
                onClick={() => setView('grid')}
                className={`p-2 rounded-lg transition-colors ${
                  view === 'grid'
                    ? 'bg-blue-500 text-white'
                    : 'text-gray-400 hover:text-white'
                }`}
              >
                <FiGrid />
              </button>
              <button
                onClick={() => setView('list')}
                className={`p-2 rounded-lg transition-colors ${
                  view === 'list'
                    ? 'bg-blue-500 text-white'
                    : 'text-gray-400 hover:text-white'
                }`}
              >
                <FiList />
              </button>
            </div>

            <button
              onClick={() => navigate('/admin/articles/add')}
              className="flex items-center gap-2 bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-lg transition-colors"
            >
              <FiPlus /> New Article
            </button>
          </div>
        </div>
      )}

      {filteredArticles.length === 0 ? (
        <div className="flex flex-col items-center justify-center py-12 bg-dark-secondary/30 rounded-xl">
          <p className="text-gray-400 mb-4">{emptyMessage}</p>
          <button
            onClick={() => navigate('/admin/articles/add')}
            className="flex items-center gap-2 bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-lg transition-colors"
          >
            <FiPlus /> Create New Article
          </button>
        </div>
      ) : view === 'grid' ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-6">
          {filteredArticles.map((article) => (
            <div
              key={article.id}
              className="bg-dark-secondary/30 rounded-xl overflow-hidden hover:transform hover:scale-[1.02] transition-all duration-200"
            >
              {article.featuredImage && (
                <img
                  src={article.featuredImage.url}
                  alt={article.featuredImage.alt}
                  className="w-full h-48 object-cover"
                />
              )}
              <div className="p-6">
                <h3 className="text-lg font-medium mb-2">{article.title}</h3>
                <p className="text-gray-400 mb-4 line-clamp-2">
                  {stripHtmlAndLimit(article.content)}
                </p>
                <div className="flex justify-between items-center text-sm text-gray-500">
                  <span>Published: {formatDate(article.publishedAt)}</span>
                  <div className="flex items-center gap-2">
                    <button
                      onClick={() => navigate(`/admin/articles/edit/${article.id}`)}
                      className="p-2 text-blue-400 hover:text-blue-300 transition-colors"
                      title="Edit"
                    >
                      <FiEdit2 />
                    </button>
                    <button
                      onClick={() => navigate(`/admin/articles/preview/${article.id}`)}
                      className="p-2 text-green-400 hover:text-green-300 transition-colors"
                      title="Preview"
                    >
                      <FiEye />
                    </button>
                    <button
                      onClick={() => handleDelete(article.id)}
                      className="p-2 text-red-400 hover:text-red-300 transition-colors"
                      title="Delete"
                    >
                      <FiTrash2 />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="space-y-4">
          {filteredArticles.map((article) => (
            <div
              key={article.id}
              className="flex items-center justify-between bg-dark-secondary/30 p-4 rounded-xl hover:transform hover:scale-[1.01] transition-all duration-200"
            >
              <div className="flex-1">
                <h3 className="text-lg font-semibold">{article.title}</h3>
                <p className="text-gray-400 text-sm mb-2">Published: {formatDate(article.publishedAt)}</p>
                <p className="text-gray-400 text-sm line-clamp-1">
                  {stripHtmlAndLimit(article.content, 100)}
                </p>
              </div>
              <div className="flex items-center gap-2">
                <button
                  onClick={() => navigate(`/admin/articles/edit/${article.id}`)}
                  className="p-2 text-blue-400 hover:text-blue-300 transition-colors"
                  title="Edit"
                >
                  <FiEdit2 />
                </button>
                <button
                  onClick={() => navigate(`/admin/articles/preview/${article.id}`)}
                  className="p-2 text-green-400 hover:text-green-300 transition-colors"
                  title="Preview"
                >
                  <FiEye />
                </button>
                <button
                  onClick={() => handleDelete(article.id)}
                  className="p-2 text-red-400 hover:text-red-300 transition-colors"
                  title="Delete"
                >
                  <FiTrash2 />
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ArticlesList;
