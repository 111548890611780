import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FiEdit2, FiTrash2, FiPlus, FiSearch, FiVideo } from 'react-icons/fi';
import { collection, query, onSnapshot, deleteDoc, doc, Timestamp, orderBy } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { toast } from 'react-hot-toast';

interface VideoData {
  title: string;
  description: string;
  youtubeUrl: string;
  thumbnailUrl?: string;
}

interface VideoGallery {
  id: string;
  title: string;
  description: string;
  videos: VideoData[];
  createdAt: any;
  updatedAt: any;
}

const VideoGalleryList: React.FC = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [galleries, setGalleries] = useState<VideoGallery[]>([]);
  const [loading, setLoading] = useState(true);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [galleryToDelete, setGalleryToDelete] = useState<VideoGallery | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    const galleriesRef = collection(db, 'video-gallery');
    const q = query(
      galleriesRef,
      orderBy('createdAt', 'desc')
    );

    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        const galleriesData: VideoGallery[] = [];
        snapshot.forEach((doc) => {
          const data = doc.data();
          if (data.title && data.createdAt) {
            galleriesData.push({
              id: doc.id,
              ...data
            } as VideoGallery);
          }
        });
        setGalleries(galleriesData);
        setLoading(false);
      },
      (error) => {
        console.error('Error fetching galleries:', error);
        toast.error('Failed to load galleries. Please try again later.');
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, []);

  const handleDeleteClick = (gallery: VideoGallery) => {
    setGalleryToDelete(gallery);
    setDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (!galleryToDelete) return;

    setIsDeleting(true);
    try {
      const galleryRef = doc(db, 'video-gallery', galleryToDelete.id);
      await deleteDoc(galleryRef);
      
      toast.success('Gallery deleted successfully');
      setDeleteModalOpen(false);
      setGalleryToDelete(null);
    } catch (error) {
      console.error('Error deleting gallery:', error);
      toast.error('Failed to delete gallery. Please try again.');
    } finally {
      setIsDeleting(false);
    }
  };

  const handleDeleteCancel = () => {
    setDeleteModalOpen(false);
    setGalleryToDelete(null);
  };

  const filteredGalleries = galleries.filter(gallery =>
    gallery.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    gallery.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
    gallery.videos.some(video => 
      video.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      video.description.toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-900 p-6">
      {/* Header Section */}
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4 mb-8">
        <div>
          <h1 className="text-3xl font-bold text-white mb-2">Video Galleries</h1>
          <p className="text-gray-400">Manage and organize your video collections</p>
        </div>
        <button
          onClick={() => navigate('/admin/video-gallery/add')}
          className="flex items-center px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-all duration-200 shadow-lg hover:shadow-blue-500/20"
        >
          <FiPlus className="mr-2 h-5 w-5" />
          Create Gallery
        </button>
      </div>

      {/* Search Section */}
      <div className="mb-8">
        <div className="relative max-w-md">
          <FiSearch className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
          <input
            type="text"
            placeholder="Search galleries..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full pl-12 pr-4 py-3 bg-gray-800 border border-gray-700 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
          />
        </div>
      </div>

      {/* Galleries Grid */}
      {filteredGalleries.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredGalleries.map((gallery) => (
            <div 
              key={gallery.id} 
              className="bg-gray-800 rounded-xl overflow-hidden border border-gray-700 hover:border-gray-600 transition-all duration-200 shadow-lg hover:shadow-xl"
            >
              {/* Gallery Image */}
              <div className="aspect-w-16 aspect-h-9 bg-gray-700 relative group">
                {gallery.videos[0]?.thumbnailUrl ? (
                  <img
                    src={gallery.videos[0].thumbnailUrl}
                    alt={gallery.title}
                    className="object-cover w-full h-full"
                  />
                ) : (
                  <div className="flex items-center justify-center w-full h-full">
                    <FiVideo className="w-12 h-12 text-gray-500" />
                  </div>
                )}
                {/* Hover Overlay */}
                <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-40 transition-all duration-200 flex items-center justify-center opacity-0 group-hover:opacity-100">
                  <button
                    onClick={() => navigate(`/admin/video-gallery/view/${gallery.id}`)}
                    className="px-4 py-2 bg-white text-gray-900 rounded-lg font-medium transform scale-95 hover:scale-100 transition-all duration-200"
                  >
                    View Gallery
                  </button>
                </div>
              </div>

              {/* Gallery Info */}
              <div className="p-6">
                <h3 className="text-xl font-semibold text-white mb-2 line-clamp-1">
                  {gallery.title}
                </h3>
                <p className="text-gray-400 text-sm mb-4 line-clamp-2">
                  {gallery.description}
                </p>

                {/* Gallery Stats */}
                <div className="flex items-center justify-between text-sm text-gray-400 mb-4">
                  <div className="flex items-center">
                    <FiVideo className="mr-2 h-4 w-4" />
                    <span>{gallery.videos.length} videos</span>
                  </div>
                  <span>{new Date(gallery.createdAt.toDate()).toLocaleDateString()}</span>
                </div>

                {/* Action Buttons */}
                <div className="flex justify-end space-x-2">
                  <button
                    onClick={() => navigate(`/admin/video-gallery/edit/${gallery.id}`)}
                    className="p-2 text-gray-400 hover:text-blue-400 rounded-lg hover:bg-blue-500/10 transition-colors"
                    title="Edit Gallery"
                  >
                    <FiEdit2 className="w-5 h-5" />
                  </button>
                  <button
                    onClick={() => handleDeleteClick(gallery)}
                    className="p-2 text-gray-400 hover:text-red-400 rounded-lg hover:bg-red-500/10 transition-colors"
                    title="Delete Gallery"
                  >
                    <FiTrash2 className="w-5 h-5" />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        // Empty State
        <div className="text-center py-16 bg-gray-800 rounded-xl border border-gray-700">
          <FiVideo className="mx-auto h-16 w-16 text-gray-500 mb-4" />
          <h3 className="text-xl font-semibold text-white mb-2">
            {searchTerm ? 'No matching galleries found' : 'No galleries yet'}
          </h3>
          <p className="text-gray-400 mb-8 max-w-md mx-auto">
            {searchTerm 
              ? 'Try adjusting your search terms or clear the search to see all galleries.'
              : 'Create your first video gallery to start organizing your videos.'}
          </p>
          <button
            onClick={() => navigate('/admin/video-gallery/add')}
            className="inline-flex items-center px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-all duration-200 shadow-lg hover:shadow-blue-500/20"
          >
            <FiPlus className="mr-2 h-5 w-5" />
            Create New Gallery
          </button>
        </div>
      )}

      {/* Delete Modal */}
      {deleteModalOpen && galleryToDelete && (
        <div className="fixed inset-0 bg-black/70 flex items-center justify-center p-4 z-50">
          <div className="bg-gray-800 rounded-xl shadow-xl w-full max-w-2xl p-8 border border-gray-700">
            <div className="flex flex-col items-center text-center mb-8">
              <div className="w-16 h-16 bg-red-500/10 rounded-full flex items-center justify-center mb-4">
                <FiTrash2 className="w-8 h-8 text-red-500" />
              </div>
              <h3 className="text-2xl font-bold text-white mb-2">Delete Gallery?</h3>
              <p className="text-xl text-gray-300 mb-2">
                "{galleryToDelete.title}"
              </p>
              <p className="text-gray-400">
                Contains {galleryToDelete.videos.length} videos
              </p>
            </div>
            
            <p className="text-gray-400 text-center mb-8 bg-red-500/10 p-4 rounded-lg">
              This action cannot be undone. The gallery will be permanently deleted.
            </p>

            <div className="flex justify-center space-x-4">
              <button
                onClick={handleDeleteCancel}
                className="px-6 py-3 bg-gray-700 text-gray-300 rounded-lg hover:bg-gray-600 transition-all duration-200 disabled:opacity-50 disabled:cursor-not-allowed min-w-[120px]"
                disabled={isDeleting}
              >
                Cancel
              </button>
              <button
                onClick={handleDeleteConfirm}
                className="px-6 py-3 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-all duration-200 disabled:opacity-50 disabled:cursor-not-allowed min-w-[120px] flex items-center justify-center"
                disabled={isDeleting}
              >
                {isDeleting ? (
                  <>
                    <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white mr-2" />
                    Deleting...
                  </>
                ) : (
                  'Delete'
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VideoGalleryList;
