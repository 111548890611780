import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Event } from '../../types/event';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { toast } from 'react-hot-toast';
import EventForm from '../../components/events/EventForm';
import { useAuth } from '../../contexts/AuthContext';
import { FiArrowLeft, FiSave } from 'react-icons/fi';

interface EventFormRef {
  getFormData: () => Partial<Event> | null;
  updateFormData: (data: Partial<Event>) => void;
}

const AddEvent: React.FC = () => {
  const navigate = useNavigate();
  const formRef = useRef<EventFormRef>(null);
  const { currentUser } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (formData: Partial<Event>) => {
    if (!currentUser) {
      toast.error('You must be logged in to create an event');
      return;
    }

    setIsLoading(true);
    try {
      const now = serverTimestamp();

      // Check content size
      const contentSize = new Blob([JSON.stringify(formData)]).size;
      if (contentSize > 1000000) { // Set threshold to 1MB
        toast.error('Event content is too large. Try reducing the number or size of images in the content.');
        setIsLoading(false);
        return;
      }

      const eventData = {
        ...formData,
        createdAt: now,
        updatedAt: now,
        publishedAt: formData.status === 'published' ? now : null,
        scheduledAt: formData.status === 'scheduled' ? formData.scheduledAt : null,
        createdBy: currentUser.uid,
      };

      await addDoc(collection(db, 'events'), eventData);
      toast.success('Event created successfully!');
      navigate('/admin/events');
    } catch (error: any) {
      console.error('Error creating event:', error);
      if (error.message.includes('exceeds the maximum allowed size')) {
        toast.error('Event content is too large. Try reducing the number or size of images in the content.');
      } else {
        toast.error('Failed to create event');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800">
      {/* Header */}
      <div className="sticky top-0 z-10 bg-gray-900/80 backdrop-blur-lg border-b border-white/10">
        <div className="max-w-[2000px] mx-auto px-4 sm:px-6 lg:px-8">
          <div className="py-4 flex items-center justify-between gap-4">
            {/* Left side */}
            <div className="flex items-center gap-4">
              <button
                onClick={() => navigate('/admin/events')}
                className="inline-flex items-center gap-2 text-gray-400 hover:text-white transition-colors"
              >
                <FiArrowLeft className="w-5 h-5" />
                <span className="hidden sm:inline">Back to Events</span>
              </button>
              <h1 className="text-xl sm:text-2xl font-semibold text-white">Add New Event</h1>
            </div>

            {/* Right side */}
            <div className="flex items-center gap-3">
              <button
                onClick={() => {
                  const data = formRef.current?.getFormData();
                  if (data) {
                    handleSubmit(data);
                  }
                }}
                disabled={isLoading}
                className="inline-flex items-center gap-2 px-4 py-2 text-sm font-medium text-white bg-primary hover:bg-primary/80 rounded-lg transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              >
                <FiSave className="w-4 h-4" />
                <span className="hidden sm:inline">
                  {isLoading ? 'Creating...' : 'Create Event'}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-[2000px] mx-auto px-4 sm:px-6 lg:px-8 py-6">
        <div className="bg-white/5 backdrop-blur-xl rounded-xl shadow-lg">
          <EventForm
            ref={formRef}
            onSubmit={handleSubmit}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default AddEvent;
